import {React, useEffect,useRef} from 'react';
import {Card } from 'react-bootstrap';
import VanillaTilt from 'vanilla-tilt';

function PlayerSlim({ cardData,cardSelection}) {
  const tiltRef = useRef();

  useEffect(() => {
    // Initialize VanillaTilt on the DOM element using the ref
    VanillaTilt.init(tiltRef.current, {
      reverse: true, 
      max: 25, // Maximum tilt angle
      speed: 400, // Tilt speed
      glare: true, // Enable glare effect
      startY: -50,
      'max-glare': 0.5 // Max glare opacity
    });

  }, []);

  // console.log(cardData.title, cardData.body, cardData.imgSrc,cardSelection)
  return (
    <Card ref={tiltRef} className="tilt" style={{ padding: '3%', border: "transparent", backgroundColor: "transparent" }} >
      <Card.Img variant="top" src={cardData.imgSrc} />
    </Card>
  );
}

export default PlayerSlim;