import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { verifyPayment } from '../../helpers/api';
import * as IoIcons from 'react-icons/io';

const PaymentForm = ({ onSuccess, teamCostUsd , account}) => {
  const stripe = useStripe(); // Initialize Stripe
  const elements = useElements(); // Initialize Elements
  const [loading, setLoading] = useState(false); // State to handle loading status
  const [errorMessage, setErrorMessage] = useState(null); // State to handle errors

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'https://chainballers.xyz/payment-success',
      },
      redirect: 'if_required',
    });

    if (error) {
      setErrorMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      try {
        await verifyPayment(paymentIntent.id, account);
        console.log('Payment verified by server');
        onSuccess();
      } catch (serverError) {
        setErrorMessage('Payment completed but verification failed. Try again.');
        console.error('Server verification error:', serverError);
      }
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
      <div className="justify-content-center" style={{ marginTop: '20px' }}>
          <div className="sub-title" style={{ textAlign: 'center' }}>
           ${(teamCostUsd/100).toFixed(2)} USD
          </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button className='gradient-button' type="submit" disabled={!stripe || loading}>
          <div className='sub-title'>
            {loading ? 'Processing...' : (
              <>
                Buy now <IoIcons.IoIosFlash style={{ color: 'yellow', filter: 'drop-shadow(0px 0px 1px black)' }} />
              </>
            )}
          </div>
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
