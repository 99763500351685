import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Figure, Row } from 'react-bootstrap';
import TeamListAndAttributes from '../components/nft/TeamListAndAttributes';
import INFTContract from '../contracts/INFTContract.json'
import SignInButton from '../components/SignInButton';
import Deck from '../components/Deck/Deck';
// helpers
import * as players from '../helpers/players.js'; 
import * as api from '../helpers/api.js';
import * as coreWeb3 from '../helpers/coreWeb3.js';
// static
import backgroundImage from "../static/training-ground-2.png";
import styles from '../components/Deck/Deck.module.css';
import handIcon from '../static/icons/hand.png';
import TrainingMenu from '../components/trainingMenu/TrainingMenu';
import { Notification } from '../components/notification/Notification';
import confetti from 'canvas-confetti';
import TrainingRoom from '../components/trainingRoom/TrainingRoom';

class Training extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: 0,
      selectedItem: 0,
      selectedAttribute: 0,
      emptyCard: {
        id: 0
      },
      swipeCount: 0,
      swipedCardIds: [],
      isTrainingCampStarting: false,
      trainingResults: [],
      notifications: [],
      notificationId: 0,
      showSuccessfulTrainees: false
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleSelectAttribute = this.handleSelectAttribute.bind(this)
    this.setCanTrainMappingFromServer = this.setCanTrainMappingFromServer.bind(this)
    this.changeSelection = this.changeSelection.bind(this)
  }
  
  async componentDidMount(){
    await this.checkSize();
    await this.init();
    if (this.props.ownedTeamId) {
      await this.setCanTrainMappingFromServer(this.props.ownedTeamId);
    }
    if (this.props.ownedPlayers) {
      await this.setTrainingCapacityFromServer(this.props.ownedTeamId);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  async init(){
    await this.props.trainingCallback();
  }

  setInitialSkillLevel(skillValue){
    // console.log(skillValue)
    this.setState({
      initialSkillLevel: skillValue
    })
  }

  setShowTrainingRoomModal(status){
    this.setState({
      showTrainingRoomModal: status
    })
  }

  setSubmittedAttempt(status){
    this.setState({
      submittedAttempt: status
    })
  }

  setLevelUpAttempt(status){
    this.setState({
      levelUpAttempt: status
    })
  }

  setLevelUpResult(result){
    this.setState({
      levelUpResult: result
    })
  }

  setAttemptResult(result){
    this.setState({
      trainingAttempt: result
    })
  }

  handleCloseTrainingRoomModal(){
    this.setState({
      showTrainingRoomModal: false,
      trainingAttempt: false,
      levelUpAttempt: false,
      levelUpResult: false
    })
  }
  
  handleSelect(item){
    this.setState({
      selectedItem: item
    })
    // console.log(item)
  }

  handleSelectAttribute(attIndex){
    this.setState({
      selectedAttribute: attIndex
    })
  }

  async hasLevelledUp(tokenId, attIndex){
    let initialSkillLevel = this.state.initialSkillLevel;
    let currentSkillLevel = 0;
    const CBContract = new this.props.web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
      await CBContract.methods.getSkillByIndex(tokenId, attIndex).call().then(
        result => {
          // console.log(initialSkillLevel, result)
          currentSkillLevel = result
          if (currentSkillLevel > initialSkillLevel){
            return this.setLevelUpResult("success")
          } else {
            return this.setLevelUpResult("failed")
          }
        })
  }

  addNotification = (message) => {
    this.setState(prevState => ({
      notifications: [...prevState.notifications, {
        id: prevState.notificationId,
        message: message
      }],
      notificationId: prevState.notificationId + 1
    }));
  }

  removeNotification = (id) => {
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(notification => notification.id !== id)
    }));
  }

  async handleTraining(player, attIndex){
    let network = await this.props.web3.eth.net.getId();
    if (network !== this.props.ownedTeamChainId){
      await coreWeb3.switchToChain(this.props.web3, this.props.ownedTeamChainId);
    }
    if (network !== 8453){
      this.addNotification("Please switch to Base network");
      return;
    }
    console.log("training",player, attIndex, this.props.account)
    let data = "0x"
    // trainUp
    this.setInitialSkillLevel(player.skillsValue[attIndex])
    this.setShowTrainingRoomModal(true)
    let web3 = this.props.web3;
    if (web3 !== undefined){

      const CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
      await CBContract.methods.trainUp(player.tokenId, attIndex, data).send({ 
        from: this.props.account,
        maxPriorityFeePerGas: null, 
        maxFeePerGas: null
      }).on('receipt', receipt =>
        {
          console.log(receipt)
          this.setSubmittedAttempt(true)
          CBContract.methods.getLastTrainAttemptResult(player.tokenId).call().then(
            result => {
              console.log(result)
              this.setSubmittedAttempt(false)
              this.setAttemptResult(result)
              if (result){
                this.setLevelUpAttempt(true)
                // tryLevelUp (within 3 min)
                CBContract.methods.tryLevelUp(player.tokenId).send({ 
                  from: this.props.account,
                  maxPriorityFeePerGas: null, 
                  maxFeePerGas: null
                }).on('receipt', receipt =>
                  {
                    console.log(receipt)
                    this.setLevelUpAttempt(false)
                    this.hasLevelledUp(player.tokenId, attIndex)                  
                  }
                )
              } else {
                this.setLevelUpResult("failed")
              }
            }
          )
        }
      )
      await this.setCanTrainMappingFromServer(this.props.ownedTeamId)
      this.addNotification("Training attempt submitted successfully");
    }
  }

  async setCanTrainMapping(ownedPlayers){
    let canTrainMapping = []
    const output = {};
    let web3 = this.props.web3;
    if (web3 !== undefined) {
      const CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
      // Create an array of promises
      // console.log(ownedPlayers)
      const promises = ownedPlayers.map(item => {
        return CBContract.methods.canTrain(item.baller_id).call()
          .then(result => {
            // console.log(result)
            output[item.baller_id] = result;
          });
      });
      // Use Promise.all to wait for all promises to resolve
      await Promise.all(promises)
        .then(() => {
          // results is an array of values from each resolved promise
          canTrainMapping = output;
        })
        .catch(error => {
          // Handle any error that occurred in any of the promise
          console.error(error);
        });
    }
    this.setState({
      canTrainMapping: canTrainMapping
    })
    // console.log(canTrainMapping)
    return canTrainMapping
  }

  async setCanTrainMappingFromServer(teamId){
    let canTrainMapping = []
    canTrainMapping = await api.getTrainingStatus(this.props.ownedTeamChainId,teamId);
    this.setState({
      canTrainMapping: canTrainMapping
    })
    return canTrainMapping
  }

  async setTrainingCapacityFromServer(teamId) {
    if (!this.props.ownedPlayers || this.props.ownedPlayers.length === 0) return [];
    
    try {
      // Get all tokenIds in a single array
      const tokenIds = this.props.ownedPlayers.map(player => player.baller_id);
      
      // Make a single API call with all tokenIds
      const capacities = await api.getTrainingCapacity(this.props.ownedTeamChainId, tokenIds);
 
      // Store the result in state
      this.setState({
        trainingCapacity: capacities
      });
      
      return capacities;
    } catch (error) {
      console.error('Error fetching training capacities:', error);
      return {};
    }
  }

  changeSelection(role, toChangeId, side ){
    let index = this.props.ownedPlayers.findIndex((item) => item.baller_id === toChangeId);
    let newPlayerData = {}
    if (side === "Back"){ 
      newPlayerData = index > 0 ? this.props.ownedPlayers[index - 1] : this.props.ownedPlayers[0];
    } else {
      newPlayerData = index < this.props.ownedPlayers.length - 1 ? this.props.ownedPlayers[index + 1] : this.props.ownedPlayers[0];
    }
    let title = "Baller #"+ newPlayerData.baller_id;
    let tokenId = newPlayerData.baller_id;
    let imgSrc = players.uriToImage(newPlayerData.baller_uri);
    const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
    newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
    this.setState({
      selectedItem: newPlayerData
    })
  }

  getAvailableCount() {
    const canTrainMapping = this.state.canTrainMapping;
    if (!canTrainMapping) return this.props.ownedPlayers ? this.props.ownedPlayers.length : 0;
    
    return Object.values(canTrainMapping).filter(status => status.canTrain === true).length;
  }

  async handleTrainingCampStart(swipedCardIds, filteredCards) {
    this.setState({ isTrainingCampStarting: true });
    try {
      const receipt = await this.props.startTrainingCamp(swipedCardIds);
      console.log("receipt", receipt);
      
      // Decode the training results from events
      const trainingResults = [];
      
      // Handle successful level ups from events
      if (receipt.events) {
        Object.values(receipt.events).forEach(event => {
          const tokenId = parseInt(event.raw.topics[1], 16);
          const attIndex = parseInt(event.raw.data, 16);
          const trainedCard = filteredCards.find(card => card.baller_id == tokenId);
          const [skillsName, skillsValue] = players.getSkillsFromPlayerData(trainedCard);
          trainingResults.push({
            title: `Baller #${tokenId}`,
            trainedSkill: skillsName[attIndex],
            success: true
          });
        });
      }

      // Add unsuccessful results for remaining cards
      const successfulTokenIds = new Set(trainingResults.map(result => 
        parseInt(result.title.replace('Baller #', ''))
      ));
      swipedCardIds.forEach(tokenId => {
        if (!successfulTokenIds.has(tokenId)) {
          const trainedCard = filteredCards.find(card => card.baller_id === tokenId);
          if (trainedCard) {
            const [skillsName, skillsValue] = players.getSkillsFromPlayerData(trainedCard);
            const randomSkillIndex = Math.floor(Math.random() * skillsName.length);
            
            trainingResults.push({
              title: `Baller #${tokenId}`,
              trainedSkill: skillsName[randomSkillIndex],
              success: false
            });
          }
        }
      });

      // Show confetti animation for successful training
      const successfulResults = trainingResults.filter(result => result.success);
      if (successfulResults.length > 0) {
        this.showConfettiAnimation();
      }

      this.setState(prevState => ({
        isTrainingCampStarting: false,
        trainingResults: [...prevState.trainingResults, ...trainingResults],
        showSuccessfulTrainees: true
      }));
      
    } catch (error) {
      console.error('Error starting training camp:', error);
      this.setState({ 
        isTrainingCampStarting: false,
        swipeCount: 0,
        swipedCardIds: []
      });
      this.addNotification("Failed to start training camp. Please try again.");
    }
  }

  showConfettiAnimation() {
    const duration = 3000;
    const end = Date.now() + duration;
    const colors = ['#00ff00', '#ff0000', '#0000ff', '#ffff00'];

    (function frame() {
      confetti({
        particleCount: 4,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors
      });
      confetti({
        particleCount: 4,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors
      });

      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    }());
  }

  
  render(){
    console.log(this.props.ownedPlayers)
    // Get filtered cards array once
    const filteredCards = this.props.ownedPlayers ? this.props.ownedPlayers
      .filter(player => 
        !this.state.canTrainMapping || 
        (this.state.canTrainMapping[player.baller_id] && 
         this.state.canTrainMapping[player.baller_id].canTrain === true)
      ) : [];
    return (
      <>
      <div style={ !this.props.isMobile ? {} : {paddingTop: "0px"}}>
        <div style={this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            minHeight: window.innerHeight,
            minWidth: '100%'
        }: null}>
          <Row style={{display: 'flex', justifyContent: 'center', paddingTop:"1%", paddingBottom: "1%"}}>
                <div className='sub-title-mobile'>
                Training Grounds
              </div>
          </Row>
        {this.props.account !== undefined && this.props.ownedTeamId > 0 && (
            <>
            <TrainingMenu 
              totalCount={this.props.ownedPlayers ? this.props.ownedPlayers.length : 0}
              availableCount={this.getAvailableCount()}
              trainedCount={this.state.swipedCardIds.length || 0}
              trainingResults={this.state.trainingResults}
            />
            <Row style={{display: 'flex', justifyContent: 'center', paddingTop: this.props.isMobile ? '20%' : '7%', paddingBottom: "1%"}}>
              <div style={{ 
                position: 'relative', 
                height: '600px', 
                width: '100%', 
                paddingLeft: this.props.isMobile ? '10%' : '0%',
                paddingTop: '2%',
                overflow: 'hidden'
              }}>
                <Deck 
                  cards={(() => {
                    if (this.state.showSuccessfulTrainees) {
                      // Show only successfully trained players
                      const successfulTokenIds = new Set(
                        this.state.trainingResults
                          .filter(result => result.success)
                          .map(result => parseInt(result.title.replace('Baller #', '')))
                      );
                      return this.props.ownedPlayers
                        .filter(player => {
                          const ballerId = Number(player.baller_id);
                          return successfulTokenIds.has(ballerId);
                        })
                        .map(player => {
                          const [skillsName, skillsValue] = players.getSkillsFromPlayerData(player);
                          const trainingResult = this.state.trainingResults.find(
                            result => result.title === `Baller #${player.baller_id}`
                          );
                          const capacity = this.state.trainingCapacity?.[player.baller_id] || {
                            successfulAttempts: '0',
                            totalCapacity: '0'
                          };
                          
                          return {
                            image: players.uriToImage(player.baller_uri),
                            title: `Baller #${player.baller_id}`,
                            skillsName,
                            skillsValue,
                            tokenId: player.baller_id,
                            baller_uri: player.baller_uri,
                            trainingCapacity: capacity,
                            improvedSkill: trainingResult?.trainedSkill
                          };
                        });
                    }
                    
                    // Original cards logic for initial training
                    return (filteredCards.length > 0 ? filteredCards : this.props.ownedPlayers || []).map(player => {
                      const [skillsName, skillsValue] = players.getSkillsFromPlayerData(player);
                      const capacity = this.state.trainingCapacity?.[player.baller_id] || {
                        successfulAttempts: '0',
                        totalCapacity: '0'
                      };
                      
                      return {
                        image: players.uriToImage(player.baller_uri),
                        title: `Baller #${player.baller_id}`,
                        skillsName,
                        skillsValue,
                        tokenId: player.baller_id,
                        baller_uri: player.baller_uri,
                        trainingCapacity: capacity
                      };
                    });
                  })()}
                  visible={!this.state.isTrainingCampStarting}
                  showSuccessfulTrainees={this.state.showSuccessfulTrainees}
                  onSwipeLeft={(card) => {
                    if (this.state.swipeCount < filteredCards.length) {
                      const newSwipedCardIds = [...this.state.swipedCardIds];
                      
                      this.setState(prevState => ({
                        swipeCount: prevState.swipeCount + 1,
                        swipedCardIds: newSwipedCardIds
                      }), async () => {
                        // Check if this was the last card
                        if (this.state.swipeCount === filteredCards.length) {
                          await this.handleTrainingCampStart(newSwipedCardIds, filteredCards);
                        }
                      });

                      // Create skipped text element
                      const skippedText = document.createElement('div');
                      skippedText.className = styles.trainedText;
                      skippedText.textContent = 'SKIPPED ❌';
                      document.body.appendChild(skippedText);

                      // Add grey sparkles
                      const sparkles = 100;
                      for (let i = 0; i < sparkles; i++) {
                        const sparkle = document.createElement('div');
                        sparkle.className = styles.sparkle;
                        
                        // Add grey color
                        sparkle.style.background = '#808080';
                        
                        // Random position around the card - using same range as swipe right
                        const x = Math.random() * 400 - 100; // -100 to 300
                        const y = Math.random() * 100 - 100; // -100 to 0
                        
                        sparkle.style.left = `calc(50% + ${x}px)`;
                        sparkle.style.top = `calc(50% + ${y}px)`;
                        
                        document.body.appendChild(sparkle);
                        
                        // Remove sparkle after animation
                        setTimeout(() => sparkle.remove(), 1000);
                      }

                      // Remove text after animation
                      setTimeout(() => skippedText.remove(), 1000);
                    }
                  }}
                  onSwipeRight={async (card) => {
                    if (this.state.swipeCount < filteredCards.length) {
                      const newSwipedCardIds = [...this.state.swipedCardIds, card.tokenId];
                      
                      this.setState(prevState => ({
                        swipeCount: prevState.swipeCount + 1,
                        swipedCardIds: newSwipedCardIds
                      }), async () => {
                        // Check if this was the last card
                        if (this.state.swipeCount === filteredCards.length) {
                          await this.handleTrainingCampStart(newSwipedCardIds, filteredCards);
                        }
                      });

                      // Create sparkle elements
                      const sparkles = 100;
                      
                      const trainedText = document.createElement('div');
                      trainedText.className = styles.trainedText;
                      trainedText.textContent = 'TRAIN 💪';
                      document.body.appendChild(trainedText);

                      for (let i = 0; i < sparkles; i++) {
                        const sparkle = document.createElement('div');
                        sparkle.className = styles.sparkle;
                        
                        // Random position around the card
                        const x = Math.random() * 400 - 100; // -100 to 100
                        const y = Math.random() * 100 - 100; // -100 to 100
                        
                        sparkle.style.left = `calc(50% + ${x}px)`;
                        sparkle.style.top = `calc(50% + ${y}px)`;
                        
                        document.body.appendChild(sparkle);
                        
                        // Remove sparkle after animation
                        setTimeout(() => sparkle.remove(), 1000);
                      }

                      // Remove text after animation
                      setTimeout(() => trainedText.remove(), 1000);
                    }
                  }}
                />
                {this.state.isTrainingCampStarting && (
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 5,
                    width: '80%',
                    maxWidth: '400px'
                  }}>
                    <div className='box-shadow-simple-2' style={{
                      backgroundColor: '#fff',
                      borderRadius: '15px',
                      padding: '20px',
                      textAlign: 'center'
                    }}>
                      <h4 style={{ marginBottom: '15px' }}>Training in Progress</h4>
                      <div className="spinner-border text-dark mb-3" role="status"/>
                      <p style={{ marginBottom: '10px' }}>
                        Training {this.state.swipedCardIds.length} players...
                      </p>
                      <p style={{ fontSize: '0.9em', color: '#666' }}>
                        Please wait while we process your training session. This may take a few moments.
                      </p>
                    </div>
                  </div>
                )}
                {this.props.ownedPlayers && this.props.ownedPlayers.length > 0 && this.state.swipeCount === 0 && filteredCards.length !== 0 && (
                  <div className={styles.swipeHint} style={{ 
                    position: 'absolute',
                    bottom: '-10px',
                    left: '50%',
                    transform: 'translateX(-50%)'
                  }}>
                    <div className={styles.path}></div>
                    <div className={styles.handIcon} style={{ backgroundImage: `url(${handIcon})` }}></div>
                  </div>
                )}
              </div>
            </Row>
            <Row style={{display: 'flex', justifyContent: 'center', paddingBottom: "1%"}}>
                  <div className='table-title'>
                  Select Player
                </div>
            </Row>
            </>
            )
        }
        <Container style={!this.props.isMobile ? {minWidth: '1000px', minHeight: window.innerHeight}:null}>
          {this.props.account !== undefined ? (
            <>
            {this.props.ownedPlayers === undefined || this.props.ownedPlayers.length === 0 ? (
              <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
                <Col md="auto" xs="auto">
                  <Link to="/app" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <button className="button-1">
                      No players available to train. Create a team!
                    </button>
                  </Link>
                </Col>
              </Row>
            ):(
              <>
              <Row className="justify-content-center"  style={{paddingLeft: '2%', paddingBottom: '2%'}}>
                <>
                  <Container style={{paddingTop:"2%"}}>
                    <TeamListAndAttributes
                      account = {this.props.account}
                      web3 = {this.props.web3}
                      CBAddress = {this.props.CBAddress}
                      ownedPlayers={this.props.ownedPlayers}
                      ownedTeamId={this.props.ownedTeamId}
                      handleselect = {this.handleSelect}
                      canTrainMapping = {this.state.canTrainMapping}
                      setCanTrainMapping = {this.setCanTrainMappingFromServer}
                      isMobile={this.props.isMobile}
                      key={this.props.ownedPlayers}
                    />
                  </Container>
                </>
              </Row>
              </>
            )}
            </>
          ):(
          <Row className="justify-content-center" style={{ paddingTop: '0%'}}>
            <Col md="auto" xs="auto">
              <SignInButton
                loginButtonFallback={this.props.loginButtonFallback}
                customText={'Sign in to display your players'}
                accountListenerFallback={this.props.accountListenerFallback}
                isMobile={this.props.isMobile}
              />
            </Col>
          </Row>
          )}
            <TrainingRoom
              selectedItem={this.state.selectedItem}
              selectedAttribute={this.state.selectedAttribute}
              canTrainMapping={this.state.canTrainMapping}
              handleTraining={(player, attIndex) => this.handleTraining(player, attIndex)}
              handleSelectAttribute={this.handleSelectAttribute}
              changeSelection={this.changeSelection}
              showTrainingRoomModal={this.state.showTrainingRoomModal}
              submittedAttempt={this.state.submittedAttempt}
              trainingAttempt={this.state.trainingAttempt}
              levelUpAttempt={this.state.levelUpAttempt}
              levelUpResult={this.state.levelUpResult}
              handleCloseTrainingRoomModal={() => this.handleCloseTrainingRoomModal()}
              isMobile={this.props.isMobile}
            />
          </Container>
        </div>
      </div>
      </>
    );
  }
}

export default Training;
