import React, { useState, useEffect } from 'react';
import { Col, Spinner, Row, Container } from 'react-bootstrap';
import PlayerSlimCardSpring from './PlayerSlimCardSpring';
import * as players from '../../helpers/players.js';

const TeamList = ({ account, CBAddress, web3, ownedPlayers: initialOwnedPlayers, handleselect, isMobile }) => {
  const [loaded, setLoaded] = useState(false);
  const [cardsData, setCardsData] = useState([]);
  const [cardSelected, setCardSelected] = useState(0);
  const [ownedPlayers, setOwnedPlayers] = useState(initialOwnedPlayers);

  // Initial loading of the players
  useEffect(() => {
    const init = async () => {
      try {
        let fetchedPlayers = initialOwnedPlayers;
        setOwnedPlayers(fetchedPlayers);
        if (fetchedPlayers) {
          const fetchedCardsData = await Promise.all(
            fetchedPlayers.map(async (item) => {
              const cardData = await players.loadCardData(item);
              return cardData;
            })
          );
          setCardsData(fetchedCardsData);
        }
        setLoaded(true);
      } catch (error) {
        console.error('Error loading players:', error);
      }
    };

    init();
  }, [account, CBAddress, web3, initialOwnedPlayers]);

  // Updating players when ownedPlayers changes
  useEffect(() => {
    const updatePlayers = async () => {
      if (!initialOwnedPlayers || initialOwnedPlayers.length === 0) return;

      const fetchedCardsData = await Promise.all(
        initialOwnedPlayers.map(async (item) => {
          const cardData = await players.loadCardData(item);
          return cardData;
        })
      );
      setOwnedPlayers(initialOwnedPlayers);
      setCardsData(fetchedCardsData);
    };

    updatePlayers();
  }, [initialOwnedPlayers]);

  const cardFunc = (tokenId) => {
    if (cardSelected === tokenId) {
      setCardSelected(0);
    } else if (cardSelected > 0) {
      const updatedCardsData = [...cardsData];
      const idxSelectedAlready = ownedPlayers.indexOf(cardSelected);
      const idxSelected = ownedPlayers.indexOf(tokenId);
      const cardDataAtIdx = updatedCardsData[idxSelected];
      const cardDataAtIdxAlready = updatedCardsData[idxSelectedAlready];
      updatedCardsData[idxSelected] = cardDataAtIdxAlready;
      updatedCardsData[idxSelectedAlready] = cardDataAtIdx;

      setCardsData(updatedCardsData);
      setCardSelected(0);
    } else {
      setCardSelected(tokenId);
    }
  };

  const setSelectedItem = (item) => {
    const index = getIndex(item, cardsData);
    const cardData = cardsData[index];
    handleselect(item);
  };

  const getIndex = (item, items) => {
    return items.findIndex((card) => card.tokenId === item);
  };

  return (
        <>
          {cardsData.length > 0 ? (
            <Container style={{ width: '90%' }}>
              <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
                {ownedPlayers.map((item, cardIndex) => (
                  <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                    <div onClick={() => setSelectedItem(item)}>
                      <PlayerSlimCardSpring
                        key={item.value}
                        cardData={cardsData[cardIndex]}
                        cardSelection={cardSelected}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          ):(
            <Container style={{ width: '90%' }}>
            <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
              {[...Array(11)].map((_, index) => (
                <Col key={index} md={2} xs={4} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
                  <div className="loading-card-placeholder" style={{
                    height: isMobile ? '80px' : '150px',
                    background: '#f0f0f0',
                    borderRadius: '8px',
                    animation: 'pulse 1.5s infinite'
                  }}/>
                </Col>
              ))}
            </Row>
          </Container>
          )}
        </>
  );
};

export default TeamList;
