import React, { Component } from 'react'
import { Col, Row, Container} from 'react-bootstrap'

import PlayerSlimCard from '../PlayerSlimCard';
import PlayerSlim from '../PlayerSlim';
// import sampleTeam from '../../static/sampleTeam';
import sampleTeam from '../../../static/sampleTeam-white';
import * as players from '../../../helpers/players';

class TeamListLoaded extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      formIndexes: [[1,4],[4,9],[9,11]],
      cardSelected: 0,
    }
  }

  async componentDidMount() {
    this.init()
  }

  async init(){
    const updatedTeam = sampleTeam.map((item, cardIndex) => {
      let svgText = players.replaceSvgBackgroundColorWithTransparency(item.svgText, "#ffffff");
      let blob = new Blob([svgText], {type: 'image/svg+xml'});
      let url = URL.createObjectURL(blob);
      return {
        ...item,
        imgSrc: url
      };
    });

    this.setState({ cardsData: updatedTeam });
  }

  render() {
    const teamData = this.state.cardsData.length > 0 ? this.state.cardsData : sampleTeam;
    
    return(
      <>
        {teamData.length > 0 && (
          <>
          {!this.props.isMobile ? (
            <Container style={{width: '80%'}}>
              {/* Defenders - 4 players */}
              <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
              {teamData.slice(6, 10).map((item, cardIndex) => (
                <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                  <PlayerSlim key={item.value} cardData={item} />
                </Col>
              ))}
            </Row>
            
            {/* Midfielders - 4 players */}
            <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
              {teamData.slice(2, 6).map((item, cardIndex) => (
                <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                  <PlayerSlim key={item.value} cardData={item} />
                </Col>
              ))}
            </Row>
            
            {/* Forwards - 2 players */}
            <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
              {teamData.slice(0, 2).map((item, cardIndex) => (
                <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                  <PlayerSlim key={item.value} cardData={item} />
                </Col>
              ))}
              </Row>
            </Container>
          ) : (
            <Container style={{width: '80%'}}>
              <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
                {teamData
                .map((item,cardIndex) => (
                  <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                    <PlayerSlim key={item.value}
                        cardData={item} 
                    />
                  </Col>
                ))} 
              </Row>
            </Container>
          )}
          </>
        )}
      </>
    );
  }
}

export default TeamListLoaded;