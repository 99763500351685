import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Form, Spinner } from 'react-bootstrap';
import './NextDivision.module.scss';
import { transformToBNotation } from '../../helpers/utils';


const NextDivision = (props) => {
    let { ids, isMobile, handleInvite, handleCreateDivision, onSelectedTeamsChange, shareTeam } = props;
    ids = ids.filter(id => id !== 0 && id !== "0");

    const [selectedExtraTeam, setSelectedExtraTeam] = useState(null); // Track the selected team from extra teams
    const [extraTeams, setExtraTeams] = useState(ids.slice(8)); // Get the extra teams
    const [searchTerm, setSearchTerm] = useState(""); // State to store search input
    const [showMore, setShowMore] = useState(false); // State to control showing more teams
    const [filteredTeams, setFilteredTeams] = useState(extraTeams); // Filtered teams based on search
    const [mainTeams, setMainTeams] = useState(ids.slice(0, 8)); // Limit the main table to 8 rows
    const [teamNames, setTeamNames] = useState([]);
    const [createDivisionClick, setCreateDivisionClick] = useState(false);

    useEffect(() => {
        const fetchTeamNames = async () => {
            const names = await Promise.all(
                mainTeams.map(async (id) => {
                    const name = await props.getTeamNameById(props.chainId, id);
                    return { id, name };
                })
            );
            setTeamNames(names);
        };

        fetchTeamNames();
    }, [mainTeams, props]);


    const getTeamName = (id) => {
        const team = teamNames.find(team => team.id === id);
        return team ? team.name : "No Name";
    };

    // Calculate how many empty rows we need to add to make it 8 rows if there are fewer than 8 teams
    const emptyRowsCount = 8 - mainTeams.length;

    // Handle selecting an extra team to potentially replace a main team
    const handleExtraTeamSelection = (id) => {
        setSelectedExtraTeam(id);
    };

    // Replace a main team with the selected extra team
    const handleReplaceTeam = (mainTeamId) => {
        if (selectedExtraTeam) {
            const updatedMainTeams = mainTeams.map(team => 
                team === mainTeamId ? selectedExtraTeam : team
            );
            setMainTeams(updatedMainTeams);
            onSelectedTeamsChange(updatedMainTeams);  // Pass the updated teams

            setExtraTeams(prevExtraTeams => [...prevExtraTeams.filter(team => team !== selectedExtraTeam), mainTeamId]);
            setSelectedExtraTeam(null);
        }
    };

    // Handle search term updates
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    // Filter the teams based on search term
    useEffect(() => {
        setFilteredTeams(
            extraTeams.filter(team => {
                const teamName  = getTeamName(team);
                return teamName.toLowerCase().includes(searchTerm) || team.toString().includes(searchTerm);
            })
        );
    }, [searchTerm, extraTeams]);

    useEffect(() => {
        onSelectedTeamsChange(mainTeams);  // Pass the updated selected teams to the parent
    }, [mainTeams]);

    async function handleCreateDivisionClick(){
        setCreateDivisionClick(true);
        await handleCreateDivision(mainTeams);  // Pass the selected teams when creating the division
        setCreateDivisionClick(false);
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div className="box-shadow-simple justify-content-center" 
            style={ props.isMobile ?
                {padding:"2%", backgroundColor: '#fff', margin: "auto", marginBottom: "2%", borderRadius: "10px",  width: "89%"}
                :{padding:"2%", backgroundColor: '#fff', margin: "auto", marginBottom: "2%", borderRadius: "10px",  width: "50%",  minWidth: "600px"}
            }
        >
            <Row className='justify-content-center' style={{paddingBottom: "2%"}}>
                <div className="sub-title-mobile"> 
                    Next Division
                </div>
            </Row>
            <Row className='justify-content-center' style={{paddingBottom: "2%"}}>
                <div style={{fontStyle: "italic"}}> 
                    <>
                    {mainTeams.length < 8 ? (
                        <>
                        Waiting for players... <Spinner animation="border" style={{ borderWidth: '4px', height: '1rem', width: '1rem' }} />
                        </>
                    ) : (
                        "All players have joined!"
                    )}
                </>
                </div>
            </Row>
            {/* Show Create Division button if there are 8 or more teams */}
            {mainTeams.length === 8 && (
                <Row className="justify-content-center" style={{ paddingBottom: '2%' }}>
                    <button onClick={handleCreateDivisionClick} className='gradient-button' style={{minWidth: "238px"}}>
                        <div className="sub-title-mobile">
                            <>
                            {createDivisionClick ? (<>
                                <Spinner animation="border" style={{ borderWidth: '4px', height: '1rem', width: '1rem', color:"black" }} />
                                </>) : 
                                ("Create Division")
                            }
                            </>
                        </div>
                    </button>
                </Row>
            )}
            <Row className='justify-content-center' style={{paddingBottom: "2%"}}>
                <div style={{fontStyle: "italic", width:"100%"}}> 
                    {mainTeams.length < 8 ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <span className="sub-title-neon-shadow" style={{ paddingRight: "1%" }}> 
                                {8 - mainTeams.length} 
                            </span> 
                            <span>
                                teams left to join
                            </span>
                        </div>
                    ) : ""}
                </div>
            </Row>
            <Row
                className="box-shadow-simple justify-content-center"
                style={{ margin: 0 }}
            >
                <Table striped bordered hover responsive style={{ width: '100%', maxWidth: '100%' }}>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '60px' }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Index
                                </div>
                            </th>
                            <th>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Team Name
                                </div>
                            </th>
                            <th>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    Team Id
                                </div>
                            </th>
                            <th style={{ minWidth: '100px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {mainTeams.map((id, index) => {
                            const teamName = getTeamName(id);
                            const displayId = id.startsWith('8453') ? transformToBNotation(id) : id;
                            return (
                                <tr key={id}>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {index + 1}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {teamName}
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            {displayId}
                                        </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                        {selectedExtraTeam ? (
                                            <Button
                                                variant="outline-danger"
                                                onClick={() => handleReplaceTeam(id)}
                                            >
                                                Replace
                                            </Button>
                                        ) : (
                                            <Button variant="primary" disabled>
                                                Selected
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        {/* Add empty rows to make up for a total of 8 rows */}
                        {Array.from({ length: emptyRowsCount }).map((_, index) => (
                            <tr key={`empty-${index}`}>
                                <td colSpan="4">
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <Button onClick={handleInvite} variant="outline-success">
                                            + Invite Player
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>

                </Table>
            </Row>
            {/* Show extra teams if there are more than 8 */}
            {extraTeams.length > 0 && (
                <>
                    <Row className="justify-content-between align-items-center" style={{ paddingTop: '6%' }}>
                        <Col>
                            <h5>Other Teams:</h5>
                        </Col>
                        <Col xs={5}>
                            <Form.Control 
                                type="text"
                                placeholder="Search teams..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    <Row className="justify-content-center" style={{ paddingTop: '2%' }}>
                        <Col>
                            <Table striped bordered hover responsive >
                                <thead>
                                    <tr>
                                        <th>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Index
                                            </div>
                                            </th>
                                        <th>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Team Name
                                            </div>
                                        </th>
                                        <th>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Team Id
                                            </div>
                                        </th>
                                        <th>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                Select
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTeams.slice(0, showMore ? filteredTeams.length : 3).map((id, index) => {
                                        const teamName  = getTeamName(id);
                                        return (
                                            <tr key={id}>
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        {index + 1}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        {teamName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        {id}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <Button 
                                                            variant={selectedExtraTeam === id ? "outline-success" : "outline-primary"}
                                                            onClick={() => handleExtraTeamSelection(id)}
                                                            disabled={selectedExtraTeam === id}  // Disable button if already selected
                                                        >
                                                            {selectedExtraTeam === id ? "Selected" : "Select"}
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            {filteredTeams.length > 3 && (
                                <Button variant="link" onClick={handleShowMore}>
                                    {showMore ? "Show Less" : "Show More"}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default NextDivision;
