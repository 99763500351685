import React, { Component } from 'react'
import { Col,Row, Spinner,Container} from 'react-bootstrap'


// import IRenderer from '../../contracts/IRenderer.json'
import INFTContract from '../../contracts/INFTContract.json'
import PlayerCard from '../nft/PlayerCard';
import PlayerCardSpring from '../nft/PlayerCardSpring';
import Rectangle from '../cardsGrid/Rectangle';
import SlideIn from '../animations/slide/SlideIn';
import * as formationHelper from '../../helpers/gameplay/formationHelper.js';
import * as players from '../../helpers/players.js';
import * as coreWeb3 from '../../helpers/coreWeb3.js';
import * as api from '../../helpers/api.js';
import PositionScore from './PositionScore';

class CardsGrid extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      loaded: false,
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      defaultFormation: [],
      formationType: [4,4,2],
      formationArrays: [[4,4,2],[4,3,3],[4,5,1],[5,4,1],[3,5,2]],
      formIndexes: [[1,4],[4,9],[9,11]],
      cardSelected: 0,
    }
    this.cardSelectFunc = this.cardSelectFunc.bind(this)
    this.cardCompareSelectFunc = this.cardCompareSelectFunc.bind(this)
    this.cardAttrFunc = this.cardAttrFunc.bind(this)
    this.handleDropCard = this.handleDropCard.bind(this);
  }

  async componentDidMount() {
    this.init()
  }

  async init(){
    let account = this.props.account
    try {
      let ownedPlayers = this.props.ownedPlayers;
      if ((ownedPlayers == undefined)){
        ownedPlayers = await this.props.getPlayersData();
      }

      this.setState({
        ownedPlayers: ownedPlayers
      })
      var defaultFormation = []
      if (account !== undefined && account !== 0) {
        defaultFormation = await this.loadFormationFromServer(ownedPlayers);
      }
      
      var cardsData = [] 
      if (defaultFormation !== undefined && defaultFormation.length > 0){
        cardsData = await Promise.all(defaultFormation.map(async (item) => {
          const cardData = players.loadCardDataFull(item);
          return cardData;
        }));
    
      } else {
        this.setState({
          defaultFormation: ownedPlayers.slice(0,11)
        })
        cardsData = await Promise.all(ownedPlayers.slice(0,11).map(async (item) => {
          const cardData = players.loadCardDataFull(item);
          // console.log(cardData)
          return cardData;
        }));
      
      }
      // console.log(cardsData)
      this.setState({
        cardsData : cardsData,
        loaded: true
      })
      
    } catch (error) {
      // console.log(error)
      setTimeout(async () => {
          this.init();
      }, 2001);
    }
  }


  handleDropCard(tokenId, dropTokenId) {
    if (tokenId === dropTokenId) return;
  
    let formation;
    if (this.state.formation.length > 0) {
      formation = [...this.state.formation];
    } else {
      formation = [...this.state.defaultFormation];
    }
  
    let cardsData = [...this.state.cardsData];
  
    let idxSelected = formation.findIndex(entry => entry.baller_id === tokenId);
    let idxDrop = formation.findIndex(entry => entry.baller_id === dropTokenId);
    
    if (idxSelected === -1 || idxDrop === -1) return; // Exit if either index is not found
  
    // Swapping formation positions
    let tempFormation = formation[idxSelected];
    formation[idxSelected] = formation[idxDrop];
    formation[idxDrop] = tempFormation;
  
    // Swapping card data
    let tempCardData = cardsData[idxSelected];
    cardsData[idxSelected] = cardsData[idxDrop];
    cardsData[idxDrop] = tempCardData;
  
    this.setState({
      formation: formation,
      cardsData: cardsData,
      cardSelected: 0
    });
  
    // Notify parent components of the update
    this.props.formationSelectionFallback(formation);
    this.props.playerSelectionFallback(this.state.cardsData[idxSelected], this.state.cardsData[idxDrop]);
  }
  

  async loadFormation(ownedPlayers){
    // console.log(ownedPlayers)
    let web3 = this.props.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);

    let ownedTeamId = await this.props.getOwnedTeam()
    var defaultFormation = []
    await CBContract.methods.getDefaultFormation(ownedTeamId).call().then(
      _indexes => {
          // console.log(_indexes)
          let indexes = _indexes;
          defaultFormation = indexes[1]
          defaultFormation = defaultFormation.map(id => parseInt(id, 10));
          defaultFormation = defaultFormation.map(id => ownedPlayers.find(baller => baller.baller_id === id)).filter(baller => baller !== undefined);
    
          // let indexes = [1]
          this.setState({
              defaultFormation: defaultFormation,
              formationType: this.state.formationArrays[indexes[0]]
          })
          this.props.formationSelectionFallback(defaultFormation, this.state.formationArrays[indexes[0]])
      }
    )
    // console.log(defaultFormation)
    return defaultFormation
  }

  async loadFormationFromServer(ownedPlayers){
    let ownedTeamId = await this.props.ownedTeamId
    var formationData = await api.getTeamFormation(this.props.ownedTeamChainId,ownedTeamId)
    var defaultFormation = formationData.defaultFormation.map(id => ownedPlayers.find(baller => baller.baller_id === id)).filter(baller => baller !== undefined);
    this.setState({
        defaultFormation: defaultFormation,
        formationType: this.state.formationArrays[formationData.formationType]
    })
    this.props.formationSelectionFallback(defaultFormation, this.state.formationArrays[defaultFormation.formationType])
    return defaultFormation
  }

  getFormationIndexes(arr) {
    let sumOfPreviousElements = 1;
    return arr.map(currentElement => {
      const prevSumOfPreviousElements = sumOfPreviousElements;
      const sumOfPreviousAndCurrentElements = sumOfPreviousElements + currentElement;
      sumOfPreviousElements += currentElement;
      return [prevSumOfPreviousElements, sumOfPreviousAndCurrentElements];
    });
  }

  cardSelectFunc(tokenId) {
    // console.log(tokenId, this.state.cardSelected)
    let cardAlreadySelected = this.state.cardSelected
    let formation;
    if (this.state.formation.length > 0){
      formation = [...this.state.formation];
    } else {
      formation = [...this.state.defaultFormation];
    }
    if (cardAlreadySelected === tokenId){
      this.setState({
        cardSelected: 0,
        cardCompared: 0
      })
    } else if (cardAlreadySelected > 0){
      // console.log(formation)
      let cardsData = [...this.state.cardsData];
      let idxSelectedAlready = formation.findIndex(entry => entry.baller_id === cardAlreadySelected);
      let idxSelected = formation.findIndex(entry => entry.baller_id === tokenId);
      let cardDataAtIdx = cardsData[idxSelected];
      let cardDataAtIdxAlready = cardsData[idxSelectedAlready];
      let temp = formation[idxSelected];
      // console.log(formation[idxSelectedAlready],idxSelectedAlready, formation[idxSelected],idxSelected )
      formation[idxSelected] = formation[idxSelectedAlready];
      cardsData[idxSelected] = cardDataAtIdxAlready;
      formation[idxSelectedAlready] = temp;
      cardsData[idxSelectedAlready] = cardDataAtIdx;
      this.setState({
        formation: formation,
        cardsData: cardsData,
        cardSelected: 0
      })
      // console.log(idxSelected)
      this.props.formationSelectionFallback(formation)
      this.props.playerSelectionFallback(this.state.cardsData[idxSelectedAlready],this.state.cardsData[idxSelected])
    } else {
      this.setState({
        cardSelected: tokenId
      })
      let idxSelected = formation.findIndex(entry => entry.baller_id === tokenId);
      this.props.playerSelectionFallback(this.state.cardsData[idxSelected], undefined)
    }
  }

  cardCompareSelectFunc(tokenId) {
    let cardAlreadySelected = this.state.cardSelected
    let formation;
    if (this.state.formation.length > 0){
      formation = [...this.state.formation];
    } else {
      formation = [...this.state.defaultFormation];
    }
    if (cardAlreadySelected > 0){
      // console.log(formation)
      let cardsData = [...this.state.cardsData];
      let idxSelectedAlready = formation.findIndex(entry => entry.baller_id === cardAlreadySelected);
      let idxSelected = formation.findIndex(entry => entry.baller_id === tokenId);
      let cardDataAtIdx = cardsData[idxSelected];
      let cardDataAtIdxAlready = cardsData[idxSelectedAlready];
      let temp = formation[idxSelected];
      // console.log(formation[idxSelectedAlready],idxSelectedAlready, formation[idxSelected],idxSelected )
      formation[idxSelected] = formation[idxSelectedAlready];
      cardsData[idxSelected] = cardDataAtIdxAlready;
      formation[idxSelectedAlready] = temp;
      cardsData[idxSelectedAlready] = cardDataAtIdx;
      // console.log(idxSelected)
      this.props.formationSelectionFallback(formation)
      this.props.playerSelectionFallback(this.state.cardsData[idxSelectedAlready],this.state.cardsData[idxSelected])
      this.setState({
        cardCompared: tokenId
      })
    } else {
      this.setState({
        cardCompared: 0
      })
      let idxSelected = formation.findIndex(entry => entry.baller_id === tokenId);
      this.props.playerSelectionFallback(this.state.cardsData[idxSelected], undefined)
    }
  }

  cardAttrFunc(tokenId,showAttr){
    if (showAttr){
      this.setState({
        showAttrOf: tokenId
      })
    } else {
      this.setState({
        showAttrOf: 0
      })
    }
  }

  render() {
    // console.log(this.state.formationType)
    let formationType = this.state.formationType;
    if(this.props.formationArray !== undefined){
      formationType = this.props.formationArray;
    }
    let formIndexes = this.getFormationIndexes(formationType)
    // console.log(formationType,this.state.formation, this.state.cardsData, this.state.defaultFormation)
    return(
      <>
      {this.state.cardsData.length > 0 ? (
      <Container style={{width: "90%"}}>
        {formationType.reduce((acc, rowCount, index) => {
          const row = (
            <Row key={index} className="justify-content-center" style={{ paddingBottom: '1%', paddingTop: this.props.isMobile ? '10%' : '0' }}>
              {this.state.defaultFormation.slice(formIndexes[index][0],formIndexes[index][1])
              .map((item,cardIndex) => (
                <Col key={cardIndex} md="auto" xs="auto" style={{ paddingLeft: '1%'}}>
                  {this.props.isMobile ? (
                    <PlayerCard key={item.value} 
                      cardData = {this.state.cardsData[formIndexes[index][0]+cardIndex]} 
                      cardSelection = {this.state.cardSelected}
                      cardCompared = {this.state.cardCompared}
                      showAttrOf = {this.state.showAttrOf}
                      formationType = {formationType}
                      formationIndex = {formIndexes[index][0]+cardIndex}
                      cardFunction = {this.cardSelectFunc}
                      cardCompareFunction = {this.cardCompareSelectFunc}
                      onDropCard={this.handleDropCard}
                      isMobile={this.props.isMobile}
                    />
                  ) : (
                    <PlayerCardSpring key={item.value} 
                      cardData = {this.state.cardsData[formIndexes[index][0]+cardIndex]} 
                      cardSelection = {this.state.cardSelected}
                      cardCompared = {this.state.cardCompared}
                      showAttrOf = {this.state.showAttrOf}
                      formationType = {formationType}
                      formationIndex = {formIndexes[index][0]+cardIndex}
                      cardFunction = {this.cardSelectFunc}
                      cardCompareFunction = {this.cardCompareSelectFunc}
                      onDropCard={this.handleDropCard}
                      isMobile={this.props.isMobile}
                    />
                  )}
                </Col>
              ))}
            </Row>
          );
          acc.unshift(row);
          return acc;
        }, [])}
        <Row className="justify-content-center" style={{paddingBottom: this.props.isMobile ? '15%' : '5%' , paddingTop: this.props.isMobile ? '10%' : '0' }}>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: '8%' }}>
            <img src='./gk-glove.png' alt="gk" style={!this.props.isMobile ? {width: '40px', height: '40px',transform: 'scaleX(-1) rotate(90deg)'}: {width: '40px', height: '40px',transform: 'scaleX(-1) rotate(90deg)'}}/>
          </div>
          <Col md="auto" xs="auto">  
            <PlayerCard key={this.state.defaultFormation[0]} 
              cardData = {this.state.cardsData[0]} 
              cardSelection = {this.state.cardSelected}
              cardCompared = {this.state.cardCompared}
              formationType = {formationType}
              formationIndex = {0}
              cardFunction = {this.cardSelectFunc}
              cardCompareFunction = {this.cardCompareSelectFunc}
              showAttrOf = {this.state.showAttrOf}
              isMobile={this.props.isMobile}
            />
          </Col>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: '8%' }}>
            <img src='./gk-glove.png' alt="gk" style={{width: '40px', height: '40px',transform: 'rotate(90deg)'}}/>
          </div>
        </Row>
        <PositionScore />
      </Container>
      ):(
        <Container style={{ width: '90%' }}>
          {/* First row - 2 players */}
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            {[...Array(2)].map((_, index) => (
              <Col key={index} md={2} xs={3} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
                <div className="loading-card-placeholder" style={{
                  height: this.props.isMobile ? '80px' : '150px',
                  background: '#f0f0f0',
                  borderRadius: '8px',
                  animation: 'pulse 1.5s infinite'
                }}/>
              </Col>
            ))}
          </Row>
          {/* Second row - 4 players */}
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            {[...Array(4)].map((_, index) => (
              <Col key={index} md={2} xs={3} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
                <div className="loading-card-placeholder" style={{
                  height: this.props.isMobile ? '80px' : '150px',
                  background: '#f0f0f0',
                  borderRadius: '8px',
                  animation: 'pulse 1.5s infinite'
                }}/>
              </Col>
            ))}
          </Row>
          {/* Third row - 4 players */}
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            {[...Array(4)].map((_, index) => (
              <Col key={index} md={2} xs={3} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
                <div className="loading-card-placeholder" style={{
                  height: this.props.isMobile ? '80px' : '150px',
                  background: '#f0f0f0',
                  borderRadius: '8px',
                  animation: 'pulse 1.5s infinite'
                }}/>
              </Col>
            ))}
          </Row>
          {/* Fourth row - 1 player (goalkeeper) */}
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            <Col md={2} xs={3} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
              <div className="loading-card-placeholder" style={{
                height: this.props.isMobile ? '80px' : '150px',
                background: '#f0f0f0',
                borderRadius: '8px',
                animation: 'pulse 1.5s infinite'
              }}/>
            </Col>
          </Row>
        </Container>
      )}
      </>
    );
}
}

export default CardsGrid;