import React, { useState } from 'react';
import GameViewEngine from '../gameViewEngine/GameViewEngine';
import GameControl from '../gameViewEngine/GameControl';
import TeamPreview from './TeamPreview';
import backgroundImage from "../../static/stadium-pitch-ng-2.png";

const GameView = ({
  selectedGame,
  gameResult,
  gameCommentary,
  homeFormation,
  awayFormation,
  homeFormationArray,
  awayFormationArray,
  teamsData,
  getLeagueData,
  handlePlayGameClick,
  isMobile,
}) => {
  const [gameStatus, setGameStatus] = useState({score: [0,0], gameTime: 0});
  const [gameStarted, setGameStarted] = useState(false);
  const [hasPreviewEnded, setHasPreviewEnded] = useState(false);

  const updateGameStatus = (status) => {
    setGameStatus(status);
  };

  const startGame = (bool) => {
    setGameStarted(bool);
  };

  return (
    <div className="box-shadow-simple" style={{
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
      paddingTop: hasPreviewEnded ? "2%" : "0",
      backgroundColor: '#fff', 
      width: "100%", 
      minWidth: isMobile ? "300px" : "600px", 
      margin: "0 auto",
      marginBottom: "2%", 
      borderRadius: "10px",
      height: "100vh"
    }}>
      {!hasPreviewEnded && (
        <TeamPreview 
          selectedGame={selectedGame}
          homeFormation={homeFormation}
          awayFormation={awayFormation}
          onScrollEnd={() => setHasPreviewEnded(true)}
        />
      )}
      {hasPreviewEnded && (
        <>
          <GameViewEngine
            selectedGame={selectedGame}
            gameResult={gameResult}
            gameSummary={gameCommentary}
            homeFormation={homeFormation}
            awayFormation={awayFormation}
            homeFormationArray={homeFormationArray}
            awayFormationArray={awayFormationArray}
            teamsData={teamsData}
            getLeagueData={getLeagueData}
            isMobile={isMobile}
            updateGameStatus={updateGameStatus}
            gameStarted={gameStarted}
          />
          <GameControl
            handlePlayGameClick={handlePlayGameClick}
            startGame={startGame}
            gameResult={gameResult}
            gameStatus={gameStatus}
            gameStarted={gameStarted}
            gameId={selectedGame.game_id}
          />
        </>
      )}
    </div>
  );
};

export default GameView;