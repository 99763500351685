import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import './Description.scss';

const Description = ({ isMobile }) => {
  const className = isMobile ? "subtitle-card-mobile" : "subtitle-card";

  return (
    <div>
    <Row className="description-container justify-content-center">
      <Col md="auto" xs="auto">
        <div className={className}>
          <div className="description-text">
            <div style={{ fontSize: '1.2rem' , paddingBottom: '1rem'}}>
                Chainballers is an online Football Manager Game.
            </div>
            <div style={{ fontSize: '1rem' , padding: '0 0.5rem', paddingBottom: '0.5rem'}}>
              Players own and manage their team to try and reach the top of the league!
            </div>
          </div>
        </div>
      </Col>
    </Row>
    {isMobile && 
      <Row className="justify-content-center" style={{paddingTop:"2%"}}>
        <Col xs="auto" style={{paddingBottom: "1%"}}>
          <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
            <button className='gradient-button-play'>
              <div className='sub-title-neon'>PLAY</div>
            </button>
          </Link>
        </Col>
      </Row>
      }
    </div>
  );
};

export default Description; 