import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TeamListLoadedCards from '../nft/teamListHome/TeamListLoadedCards';
import TeamListHome from '../nft/teamListHome/TeamListHome.js';
import Cards from '../homepageCards/Cards.js';
import Description from '../description/Description.js';
import Divider from '../divider/Divider.js';

const HomeContent = ({ isMobile, handlePlayClick, teamData }) => {
  const backgroundColor = "#352D39"

  return (
    <div style ={{ zIndex: 2 , height: '100vh'}}>
        <>
            {!isMobile ? (
            <div style={{ position: 'relative', zIndex: 2, bottom: '11vh'}}>
                <div>
                    <Divider
                        colorPrimary= "#277218"
                        colorSecondary = "#49be31"
                    />
                    <div style ={{ zIndex: 2, boxShadow: "0px 0px 140px 40px #57ef7c"}}>
                        <Row className="justify-content-md-center" style={{
                          paddingTop: "2%",
                        //background: "#f5fcfe"
                            background: 'linear-gradient(to bottom, #f5fcfe 0%, rgba(87, 239, 124, 1) 60%, white 100%)',
                        }}>
                            <TeamListHome
                                chainId={1}
                                isMobile={isMobile}
                                openseaUrl={teamData?.openseaUrl}
                            />
                        </Row>
                        <div style={{backgroundColor: 'white', zIndex: 2}}>
                            <Divider
                                colorPrimary= "#277218"
                                colorSecondary = "#49be31"
                            />
                        </div>
                        <div className="home-content-background" style={{ zIndex: 2, boxShadow: "0px 0px 140px 40px rgba(127, 255, 155, 0.7)"}}>
                            <Row className="justify-content-md-center" style={{paddingTop:"2%"}}>
                                <Cards/>
                            </Row>
                            <Row className="justify-content-center" style={{paddingTop:"2%", paddingBottom:"2%"}}>
                                <Col md="auto" xs="auto">
                                    <Row className="justify-content-center" style={{paddingBottom:"2%"}}>
                                    <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                                        <button className='gradient-button-play' onClick={handlePlayClick}>
                                            <div className='sub-title-neon'>OPEN APP</div>
                                            </button>
                                        </Link>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="home-content-background" style={{ zIndex: 2}}>
                            <Divider
                                colorPrimary= "#277218"
                                colorSecondary = "#49be31"
                            />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>  
            <Description isMobile={true} />
            <div style={{ zIndex: 2}}>
                        <Divider
                            colorPrimary= "#277218"
                            colorSecondary = "#49be31"
                        />
                </div>
            <div className="home-content-background" style={{ zIndex: 2}}>     
                <div>
                    <div className="justify-content-md-center" style={{background: 'linear-gradient(to bottom, #f5fcfe 0%, rgba(87, 239, 124, 1) 60%, white 100%)',}}>
                        <Row className="justify-content-md-center" style={{
                          paddingTop: "2%",
                          background: ""
                        // background: 'linear-gradient(to bottom, white 0%, rgba(87, 239, 124, 1) 60%, white 100%)',
                        }}>
                        <TeamListHome
                            chainId={1}
                            isMobile={isMobile}
                            openseaUrl={teamData?.openseaUrl}
                        />
                        </Row>
                    </div>
                    <div style={{backgroundColor: "white", zIndex: 2}}>
                        <Divider
                            colorPrimary= "#277218"
                            colorSecondary = "#49be31"
                        />
                    </div>
                </div>
                <Row className="justify-content-center home-content-background" style={{paddingTop:"2%"}}>
                        <Cards/>
                    </Row>
                    <Row className="justify-content-center" style={{paddingTop:"2%", paddingBottom:"2%"}}>
                        <Col md="auto" xs="auto">
                            <Row className="justify-content-center" style={{paddingBottom:"2%"}}>
                            <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                                <button className='gradient-button-play' onClick={handlePlayClick}>
                                    <div className='sub-title-neon'>OPEN APP</div>
                                    </button>
                                </Link>
                            </Row>
                        </Col>
                    </Row>
                    <div className="home-content-background" style={{ zIndex: 2}}>
                        <Divider
                            colorPrimary= "#277218"
                            colorSecondary = "#49be31"
                        />
                    </div>
            </div>
            </>
        )}
      </>
    </div>
  );
};

export default HomeContent; 