import React, { useState } from 'react';
import { FaSignOutAlt, FaCopy, FaCheck, FaExternalLinkAlt } from 'react-icons/fa'; 
import styles from './UserModal.module.scss';
import { IoMdNotifications } from 'react-icons/io';

const UserModal = ({ showModal, handleClose, userInfo, logout }) => {
  const [copied, setCopied] = useState(false);

  if (!showModal) return null;

  // Format the wallet address 
  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 6)}..${address.slice(-4)}`;
  };

  // Copy wallet address to clipboard and temporarily show check icon
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); 
  };

  return (
    <div className={styles["custom-modal"]}>
      <div className={styles["custom-modal-content"]}>
        <span className={styles["close-button"]} onClick={handleClose}>&times;</span>
        <h4 className={styles["modal-title"]}>{userInfo?.email}</h4>

        {/* Wallet Address Section */}
        {userInfo?.wallet && (
          <div className={styles["modal-wallet-row"]}>
            <div className={styles["modal-option-sub-title"]}>Wallet:</div>
            <span className={styles["wallet-address"]}>
              {formatWalletAddress(userInfo.wallet)}
              {copied ? (
                <FaCheck className={styles["copy-icon"]} />
              ) : (
                <FaCopy
                  className={styles["copy-icon"]}
                  onClick={() => copyToClipboard(userInfo.wallet)}
                />
              )}
              <a
                href={`https://etherscan.io/address/${userInfo.wallet}`}
                target="_blank"
                rel="noopener noreferrer"
               
              >
                <FaExternalLinkAlt 
                 className={styles["etherscan-icon"]}/>
              </a>
            </span>
          </div>
        )}

        {/* Notifications Section */}
        <div className={styles["modal-content-row"]}>
          <p className={styles["modal-option-title"]}>Notifications</p>
        </div>
        <div className={styles["modal-content-row"]}>
          {userInfo?.notifications?.length > 0 ? (
            userInfo.notifications.map((notification, index) => (
              <div key={index} className={styles["modal-option"]}>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}>
                  <div style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    flexShrink: 0
                  }} />
                  {notification.message}
                </div>
              </div>
            ))
          ) : (
            <p className={styles["modal-option"]}>No new notifications</p>
          )}
        </div>

        {/* Logout Button */}
        <div className={styles["modal-content-row-right"]}>
          <button onClick={logout} className={styles["custom-logout-button"]}>
            <FaSignOutAlt className={styles["logout-icon"]} /> Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
