import React from 'react';
import {Card, Col, Row  } from 'react-bootstrap';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

function PlayerCardSingle({ cardData,cardSelection, cardFunction}) {
  // console.log(cardData.title, cardData.body, cardData.imgSrc,cardSelection)
  return (
    <Card style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '3%', height: "200px", width:"210px"}}>
      <Card.Body style={{padding: '0px', paddingTop: "1%"}}>
        <div className="d-flex justify-content-center align-items-center">
          {cardData.tokenId !== undefined && (
            <div className="d-flex align-items-center" style={{cursor: 'pointer'}}>
              <AiFillCaretLeft onClick={() => {handleSelectClick(cardData.tokenId, "Back")}}/>
            </div>
          )}
          
          <div className="mx-2">
            {cardData != undefined && cardData != 0 && (
              <img src={cardData.imgSrc} style={{height: "140px", width:"140px", border: 'none', outline: 'none'}} />
            )}
          </div>

          {cardData.tokenId !== undefined && (
            <div className="d-flex align-items-center" style={{cursor: 'pointer'}}>
              <AiFillCaretRight onClick={() => {handleSelectClick(cardData.tokenId, "Next")}}/>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );

  function handleSelectClick(tokenId, side){
    let roleId = "None"
    cardFunction(roleId, tokenId, side)
  }
}

export default PlayerCardSingle;