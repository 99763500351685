import React, { useState, useEffect } from 'react';
import PlayerCardMarket from '../PlayerCardMarket';
import * as players from '../../../helpers/players';
import styles from './teamListHome.module.css';
import {homeCards} from '../../../data/homeCards.js';

export default function TeamListHome({ chainId, isMobile, openseaUrl }) {
  const [marketData, setMarketData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchMarketData() {
      if (!chainId) return;
      
      try {
        setIsLoading(true);
        const processedMarket = homeCards.map(player => 
          players.loadCardDataFull(player)
        );
        // Store processed data in localStorage for Market.js to use
        localStorage.setItem('processedHomeCards', JSON.stringify(processedMarket));
        // Duplicate the array to create a seamless scroll effect
        setMarketData([...processedMarket, ...processedMarket]);
      } catch (error) {
        console.error('Error processing market data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchMarketData();
  }, [chainId]);

  if (isLoading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.cardsContainer}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((_, index) => (
            <div key={`skeleton-${index}`} className={styles.cardWrapper}>
              <div className={styles.cardSkeleton}>
                <div className={styles.cardTitle}></div>
                <div className={styles.cardContent}>
                  <div className={styles.cardChart}></div>
                  <div className={styles.cardImage}></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.cardsContainer}>
        {marketData.map((player, index) => (
          <div key={`${player.tokenId}-${index}`} className={styles.cardWrapper}>
            <PlayerCardMarket
              baseUrl={openseaUrl}
              noHoverMarket={true}
              cardData={player}
              maxSize={"250px"}
              isMobile={isMobile}
              isOwned={false}
              roseColor={{
                backgroundColors: ['rgba(30, 144, 255, 0.2)', 'rgba(0, 71, 171, 0.2)'],
                borderColors: ['rgba(30, 144, 255, 1)', 'rgba(0, 71, 171, 1)']
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
