import React, { useState, useEffect } from 'react';
import {
  useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef,
} from '@react-spring/web';
import * as api from '../../helpers/api.js';
import styles from './market.module.scss';
import PlayerCardMarket from '../../components/nft/PlayerCardMarket';
import * as players from '../../helpers/players.js';
import { Spinner, Row } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

export default function Market(props) {
  const [sectionsOpen, setSectionsOpen] = useState({
    team: false,
    market: false
  });
  const [teamData, setTeamData] = useState([]);
  const [marketData, setMarketData] = useState([]);
  const [openseaUrl, setOpenseaUrl] = useState(`https://opensea.io/assets/ethereum/${props.CBAddressL1}`);
  const [isLoading, setIsLoading] = useState(false);
  const PLAYERS_PER_PAGE = 32;
  const [loadedPages, setLoadedPages] = useState(1);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && sectionsOpen.market) {
      setLoadedPages(prev => prev + 1);
    }
  }, [inView, sectionsOpen.market]);

  useEffect(() => {
    async function fetchData() {
      let team = [];
      let market = [];
        try {
            setIsLoading(true);
            console.log("fetching data");
            if (props.ownedTeamId && props.ownedTeamChainId) {
              team = await api.getBallersByTeam(props.ownedTeamId, props.ownedTeamChainId);
            }
            
            // Try to get cached data first
            const cachedMarket = localStorage.getItem('processedHomeCards');
            if (cachedMarket) {
              market = JSON.parse(cachedMarket);
            } else {
              // Fallback to API call if cache doesn't exist
              market = await api.getAllBallers();
              if (market.length > 0) {
                market = market.map(player => players.loadCardDataFull(player));
              }
            }
        } catch (error) {
            console.error('Error fetching market data:', error);
            setIsLoading(false);
        } 
        
        // Process team data first
        if (team.length > 0) {
          team = team.map(player => {
            return players.loadCardDataFull(player);
          });
          setTeamData(team);
        }

        // Filter out team players from market
        if (market.length > 0) {
          const teamBallerIds = team.map(player => player.tokenId);
          market = market.filter(player => !teamBallerIds.includes(player.tokenId));
          setMarketData(market);
        }
        console.log("Market is", market.length, "players");
        setIsLoading(false);
    }
    props.marketCallback();
    fetchData();
  }, [props.ownedTeamId]);

  // Spring and transition for "Your Team"
  const teamSpringApi = useSpringRef();
  const { width: teamWidth, height: teamHeight, ...teamRest } = useSpring({
    ref: teamSpringApi,
    config: config.stiff,
    from: { 
      width: props.isMobile ? '100%' : '30%', 
      height: '200px',
      background: styles.brand1
    },
    to: {
      width: sectionsOpen.team ? '100%' : props.isMobile ? '100%' : '30%',
      height: sectionsOpen.team ? '100%' : '200px',
      background: sectionsOpen.team ? 'white' : styles.brand1,
    },
  });

  const teamTransApi = useSpringRef();
  const teamTransition = useTransition(sectionsOpen.team ? teamData : [], {
    ref: teamTransApi,
    trail: 400 / teamData.length,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    leave: { opacity: 0, scale: 0 },
  });

  // Spring and transition for "Market"
  const marketSpringApi = useSpringRef();
  const { width: marketWidth, height: marketHeight, ...marketRest } = useSpring({
    ref: marketSpringApi,
    config: config.stiff,
    from: { 
      width: props.isMobile ? '100%' : '30%', 
      height: '200px',
      background: styles.brand3
    },
    to: {
      width: sectionsOpen.market ? '100%' : props.isMobile ? '100%' : '30%',
      height: sectionsOpen.market ? '100%' : '200px',
      background: sectionsOpen.market ? 'white' : styles.brand3,
    },
  });

  const marketTransApi = useSpringRef();
  const marketTransition = useTransition(
    sectionsOpen.market ? marketData.slice(0, loadedPages * PLAYERS_PER_PAGE) : [], 
    {
      ref: marketTransApi,
      trail: 400 / Math.min(marketData.length, PLAYERS_PER_PAGE),
      from: { opacity: 0, scale: 0 },
      enter: { opacity: 1, scale: 1 },
      leave: { opacity: 0, scale: 0 },
    }
  );

  // Orchestrate animations
  useChain(sectionsOpen.team ? [teamSpringApi, teamTransApi] : [teamTransApi, teamSpringApi], [
    0,
    sectionsOpen.team ? 0.1 : 0.6,
  ]);

  useChain(sectionsOpen.market ? [marketSpringApi, marketTransApi] : [marketTransApi, marketSpringApi], [
    0,
    sectionsOpen.market ? 0.1 : 0.6,
  ]);

  const handleSectionClick = (section) => {
    setSectionsOpen(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div style={ !props.isMobile ? {paddingTop: "0px", paddingLeft: "0%"} : {paddingTop: "5%"}}>
        <Row className="justify-content-center" style={{ display: 'flex', justifyContent: 'center' }}>
          <div className='sub-title-mobile' style={{paddingBottom: "1%", paddingTop: "1%"}}>
            Market
          </div>
        </Row>
      </div>
      {(props.account && props.ownedTeamId > 0) && (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: props.isMobile ? '0 20px' : '0'}}>
        <animated.div
          style={{ ...teamRest, width: teamWidth, height: teamHeight}}
          className={styles.container}
          onMouseEnter={e => {
            e.currentTarget.style.cursor = 'pointer';
          }}
        >
          {sectionsOpen.team && (
            <button 
              className="button-1-nopad"
              onClick={() => handleSectionClick('team')}
              style={{ position: 'absolute', top: '-20px', right: '10px', minWidth: '4%', zIndex: 1 }}
            >
              X
            </button>
          )}
          {teamTransition((style, item) => (
            <animated.div
              className={styles.item}
              style={{ ...style, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <PlayerCardMarket
                baseUrl={openseaUrl}
                cardData={item}
                isMobile={props.isMobile}
                isOwned={true}
              />
            </animated.div>
          ))}
          {!sectionsOpen.team && (
            <div 
              onClick={() => handleSectionClick('team')}
              style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                fontSize: '18pt'
              }}
              className="sub-title-mobile"
            >
              Your Team
            </div>
          )}
        </animated.div>
        </div>
      )}

      {isLoading && sectionsOpen.market ? (
        <animated.div
          style={{ ...marketRest, width: marketWidth, height: marketHeight, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          className={styles.container}
        >
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
          }}>
            <Spinner animation="border" variant="dark" />
          </div>
        </animated.div>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: props.isMobile ? '0 20px' : '0'}}>
        <animated.div
          style={{ ...marketRest, width: marketWidth, height: marketHeight }}
          className={styles.container}
          onMouseEnter={e => {
            e.currentTarget.style.cursor = 'pointer';
          }}
        >
          {sectionsOpen.market && (
            <button 
              className="button-1-nopad"
              onClick={() => handleSectionClick('market')}
              style={{ position: 'absolute', top: '-20px', right: '10px', minWidth: '4%', zIndex: 1 }}
            >
              X
            </button>
          )}
          {marketTransition((style, item) => (
            <animated.div
              key={item.baller_id}
              className={styles.item}
              style={{ ...style}}
            >
              <PlayerCardMarket
                baseUrl={openseaUrl}
                cardData={item}
                isMobile={props.isMobile}
                isOwned={false}
                roseColor={{
                  backgroundColors: ['rgba(30, 144, 255, 0.2)', 'rgba(0, 71, 171, 0.2)'],
                  borderColors: ['rgba(30, 144, 255, 1)', 'rgba(0, 71, 171, 1)']
                }}
              />
            </animated.div>
          ))}
          {sectionsOpen.market && loadedPages * PLAYERS_PER_PAGE < marketData.length && (
            <div ref={ref} style={{ height: '20px', width: '100%' }}></div>
          )}
          {!sectionsOpen.market && (
            <div 
              onClick={() => handleSectionClick('market')}
              style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                fontSize: '18pt'
              }}
              className="sub-title-mobile"
            >
              All Players
            </div>
          )}
        </animated.div>
        </div>
      )}
    </div>
  );
}
