import {
  CameraControls,
  Environment,
  Text3D,
  useTexture,
  useMatcapTexture,
  ScrollControls,
  useScroll,
} from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState, useMemo } from "react";
import * as THREE from "three";
import cameraControls from 'camera-controls';
import { Carousel as Rollups } from "../leagues/leagueSelection/Rollups";
import EthereumLogo from "./EthereumLogo";


extend({ CameraControls });

function Village({ onRollupSelect, isMobile }){
  const [active, setActive] = useState(null);
  const [allowRotation, setAllowRotation] = useState(true);
  const [hovered, setHovered] = useState(null);
  const controlsRef = useRef();
  const scene = useThree((state) => state.scene);
  const map = useTexture("textures/digital_painting_the_olympics_village_a_sports_st.jpeg");
  const [ matcapTextureVillage ] = useMatcapTexture('9F9F9F_E4E4E4_D4D4D4_CCCCCC', 256);
  
  // Memoize the material
  const materialVillage = useMemo(() => {
    const material = new THREE.MeshMatcapMaterial();
    material.matcap = matcapTextureVillage;
    material.needsUpdate = true;
    return material;
  }, [matcapTextureVillage]);

  // Update texture settings only once
  useEffect(() => {
    matcapTextureVillage.colorSpace = THREE.SRGBColorSpace;
    matcapTextureVillage.needsUpdate = true;
  }, [matcapTextureVillage]);

  useEffect(() => {
      if (active) {
      const targetPosition = new THREE.Vector3();
      scene.getObjectByName(active).getWorldPosition(targetPosition);
      controlsRef.current.setLookAt(
          0,
          2,
          5,
          targetPosition.x,
          targetPosition.y-1,
          targetPosition.z,
          true
      );
      } else {
      controlsRef.current.setLookAt(0, 5, 10, 0, 1, 0, true);
      }
  }, [active]);

  useFrame(() => {
      if (controlsRef.current && !active) {
          controlsRef.current.rotateAzimuthTo(controlsRef.current.azimuthAngle -= 0.0002);
      }
  });

  const handleRollupSelect = (rollup) => {
    onRollupSelect(rollup)
  }

  // Memoize the Rollups component
  const memoizedRollups = useMemo(() => (
    <Rollups position={[0, 0.85, 0]} onRollupSelect={handleRollupSelect} isMobile={isMobile} />
  ), []); // Empty dependency array since position is static

  return (
      <>
          <ambientLight intensity={1} />
          <Environment preset="sunset" />
          <CameraControls
              ref={controlsRef}
              maxPolarAngle={Math.PI / 1.8}
              minPolarAngle={Math.PI / 2.5}
              infinityDolly={false}
              dollyToCursor={false}
              mouseButtons={{
                  left: cameraControls.ACTION.ROTATE,
                  middle: cameraControls.ACTION.NONE,
                  right: cameraControls.ACTION.NONE,
                  shiftLeft: cameraControls.ACTION.NONE,
                  wheel: cameraControls.ACTION.NONE,
              }}
          />
          <ScrollControls pages={4} infinite>
            <Rig rotation={[0, 0, 0.1]}>
              {memoizedRollups}
            </Rig>
          </ScrollControls>
          <Text3D
              material={materialVillage}
              position={[-2.1, 0.6, -4.551]}
              font="fonts/LuckiestGuy_Regular.json"
              size={0.45}
              height={0.1}
              curveSegments={12}
              bevelEnabled
              bevelThickness={0.02}
              bevelSize={0.02}
              bevelOffset={0}
              bevelSegments={5}
              anchorY={"bottom"}
          >
              {"The Village"}
          </Text3D>
          <mesh name="village">
              <sphereGeometry args={[5, 64, 64]} />
              <meshStandardMaterial map={map} side={THREE.BackSide} />
          </mesh>
          <EthereumLogo
                scale={0.4}
                position={isMobile ? [0, 0.5, 0] : [1, 1.7, -1]} 
                hovered={hovered === "EthLogo"}
                onPointerOver={() => setHovered("EthLogo")}
                onPointerOut={() => setHovered(null)}
            />
      </>
  )
}

function Rig(props) {
  const ref = useRef()
  const scroll = useScroll()
  useFrame((state) => {
    ref.current.rotation.y = -scroll.offset * (Math.PI * 2)
    state.events.update()
  })
  return <group ref={ref} {...props} />
}


export default Village;