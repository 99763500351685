import React, { Component } from 'react';
import HomeHero from '../components/home/HomeHero';
import HomeContent from '../components/home/HomeContent';

class HomeScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      sampleTeam: null
    }
    document.body.style = 'background: #352D39;';
  }
  

  componentDidMount() {
    this.setState({
      sampleTeam: {openseaUrl: 'https://opensea.io/assets/ethereum/0xde2fC11b3222E9754A23A12094Ab70116f0e493E/'}
    })
    this.props.homepagecallback();
  }

  handlePlayClick() {
    document.body.style = 'background: #ffffff;';
    this.props.handlePlayClick();
  }


  render() {
    return (
      <div>
        <div style={{ 
          height: this.props.isMobile ? '20vh' : '100vh',
          position: 'relative',
          zIndex: 1 
        }}>
          <HomeHero 
            isMobile={this.props.isMobile} 
            handlePlayClick={() => this.handlePlayClick()} 
          />
        </div>
        <div style={{
          position: 'relative',
          zIndex: 2
        }}>
          <HomeContent 
            isMobile={this.props.isMobile}
            handlePlayClick={() => this.handlePlayClick()}
            teamData={this.state.sampleTeam}
          />
        </div>
      </div>
    );
  }
}

export default HomeScreen;