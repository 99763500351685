// Web3AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { Web3Auth } from "@web3auth/modal";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { getInjectedAdapters } from "@web3auth/default-evm-adapter";
import { getUserNotifications } from '../helpers/api';

// import { createCustomerAccount, generateLoginToken} from '../hooks/api';

const Web3AuthContext = createContext();

const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID; 
// const clientId = "BBOzLcBpVH56oNrFo-IcovFY1D3mYKrVBF2qJYAuI3jtzY_Va8JV5VsQvpuKL-Me-vWqGBMzudajSU45MtXbODE"; // Your client ID from Web3Auth

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: "0x1",
  rpcTarget: process.env.REACT_APP_ETHEREUM_RPC_URL,
  displayName: "Ethereum Mainnet",
  blockExplorerUrl: "https://etherscan.io",
  ticker: "ETH",
  tickerName: "Ethereum",
};


const Web3AuthProvider = ({ children }) => {
  const [provider, setProvider] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const initWeb3Auth = async () => {
      const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
      const web3auth = new Web3Auth({
        clientId,
        web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        privateKeyProvider,
      });

      const adapters = await getInjectedAdapters({ options: {
        clientId,
        web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        privateKeyProvider,
        chainConfig
      } });

      adapters.forEach((adapter) => {
        web3auth.configureAdapter(adapter);
      });
      await web3auth.initModal();
      setProvider(web3auth);
    };
    initWeb3Auth();
  }, []);

  const getUserInfoWithWallet = async (web3auth) => {
    const user = await web3auth.getUserInfo();
    const accounts = await web3auth.provider.request({ method: "eth_accounts" });
    user.wallet = accounts[0]; // Add the wallet address to user info
    return user;
  };

  const login = async () => {
    if (!provider) return;
    var user;
    try{
      await provider.connect();
      user = await getUserInfoWithWallet(provider);
      user.notifications = 0;
      user.notifications = await getUserNotifications(user.wallet);
      user.provider = provider;
      setUserInfo(user);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error in login:', error.response?.data || error.message);
      return;
    }

    // Create customer account in the database
    try {
    //   await createCustomerAccount(user);
    //   const loginResponse = await generateLoginToken(user.email);
    //   console.log(loginResponse.message); 
    } catch (error) {
      console.error('Error creating customer account:', error);
    }
    return user;
  };

  const logout = async () => {
    if (!provider) return;
    await provider.logout();
    setIsLoggedIn(false);
    setUserInfo(null);
  };

  return (
    <Web3AuthContext.Provider value={{ userInfo, isLoggedIn, login, logout }}>
      {children}
    </Web3AuthContext.Provider>
  );
};

export { Web3AuthContext, Web3AuthProvider };