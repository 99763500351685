   // src/components/yourTeam/YourTeam.js
   import React from 'react';
   import { Row, Col, Spinner, Container } from 'react-bootstrap';
   import { Link } from 'react-router-dom';
   import CreateTeamBox from '../forms/CreateTeamBox';
   import CouponCodeBox from '../forms/CouponCodeBox';
   import TeamList from '../nft/TeamList';
   import { transformToBNotation } from '../../helpers/utils';
   import './YourTeam.scss';

   const YourTeam = (props) => {
     return (
       <div style={{ border: '1px solid #ccc', borderRadius: '16px', padding: '16px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
         <Row className="justify-content-center" style={{ paddingTop: '1%', paddingBottom: '10px' }}>
           <Col md="auto" xs="auto">
             <div className='sub-title-mobile'>
               Your&nbsp; Team
             </div>
           </Col>
         </Row>
         {props.ownedTeamId === undefined ? (
          <>
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
              <Col className="col-centered comic-neue" md="auto">
                <div className='table-title'>
                  Team Id:
                </div>
                <span className='sub-title-mobile'>
                  -
                </span>
              </Col>
              <Col className="col-centered comic-neue" md="auto">
                <div className='table-title'>
                  Name:
                </div>
                  <div className='sub-title-mobile'>
                    -
                  </div>
              </Col>
            </Row>
           <Row className="justify-content-center">
              <Container style={{ width: '90%' }}>
              <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
                {[...Array(11)].map((_, index) => (
                  <Col key={index} md={2} xs={4} style={{ paddingLeft: '1%', paddingTop: '1%' }}>
                    <div className="loading-card-placeholder" style={{
                      height: props.isMobile ? '80px' : '150px',
                      background: '#f0f0f0',
                      borderRadius: '8px',
                      animation: 'pulse 1.5s infinite'
                    }}/>
                  </Col>
                ))}
              </Row>
            </Container>
           </Row>
           </>
         ) : (
               <>
                 <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
                   <Col className="col-centered comic-neue" md="auto">
                     <div className='table-title'>
                       Team Id:
                     </div>
                     {props.ownedTeamId === undefined ?
                       (<span className='sub-title-mobile'>
                         0
                       </span>
                       ) : (
                         <span className='sub-title-mobile'>
                           {props.ownedTeamId.toString().startsWith('8453') ? transformToBNotation(props.ownedTeamId.toString()) : props.ownedTeamId}
                         </span>
                       )
                     }
                   </Col>
                   <Col className="col-centered comic-neue" md="auto">
                     <div className='table-title'>
                       Name:
                     </div>
                     {props.ownedTeamName === undefined || props.ownedTeamName == '' ?
                       <span onClick={() => props.handleTeamNameClick()} style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '4px 8px', background: 'transparent', cursor: 'pointer' }}>
                         <div className='sub-title-mobile'>
                           No Name
                         </div>
                       </span>
                       :
                       <span onClick={() => props.handleTeamNameClick()} style={{ border: '1px solid #ccc', borderRadius: '8px', padding: '4px 8px', background: 'transparent', cursor: 'pointer' }}>
                         <div className='sub-title-mobile'>
                           {props.ownedTeamName}
                         </div>
                       </span>
                      }
                   </Col>
                 </Row>
                 <Row className="justify-content-center" style={{ paddingLeft: '2%', minHeight: !props.isMobile ? '300px' : null }}>
                   <TeamList
                     account={props.account}
                     web3={props.web3}
                     CBAddress={props.CBAddressL1}
                     ownedPlayers={props.ownedPlayers}
                     loadingCallback={props.loadingCallback}
                     handleselect={props.handlePlayerSelect}
                     key={props.ownedPlayers}
                     isMobile={props.isMobile}
                   />
                 </Row>
                 {props.ownedTeamLeague > 0 && (
                  <Row style={{ paddingTop: '2%' }}>
                    <Col style={{ display: 'flex', justifyContent: 'center' }}>
                      <Link to="/formation" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <button onClick={() => props.handleSetFormationClick()} className='gradient-button-2'>
                          <div className='sub-title-mobile'>
                            Set Formation
                          </div>
                        </button>
                      </Link>
                    </Col>
                  </Row>
                 )}
               </>
             )}
       </div>
     );
   };

   export default YourTeam;