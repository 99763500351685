import React from 'react';
import { Container, Row, Col, Figure } from 'react-bootstrap';
import PlayerCardSingle from '../nft/PlayerCardSingle';
import PlayerAttributesTraining from '../nft/PlayerAttributesTraining';
import TrainingRoomModal from '../modal/TrainingRoomModal';

const TrainingRoom = ({
  selectedItem,
  selectedAttribute,
  canTrainMapping,
  handleTraining,
  handleSelectAttribute,
  changeSelection,
  showTrainingRoomModal,
  submittedAttempt,
  trainingAttempt,
  levelUpAttempt,
  levelUpResult,
  handleCloseTrainingRoomModal,
  isMobile
}) => {
  return (
    <div style={{paddingBottom: "2%"}}>
      <Container 
        className='box-shadow-simple-2'
        style={!isMobile ? {
          backgroundColor: '#fff', 
          minWidth: '900px', 
          maxWidth: '600px', 
          minHeight: "80px", 
          borderRadius: "15px", 
          boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.2)', 
          paddingTop: "2%", 
          paddingBottom: "2%"
        } : {
          backgroundColor: '#fff',
          borderRadius: "15px", 
          boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.2)', 
          paddingTop: "2%", 
          paddingBottom: "2%"
        }}>
        <Row className="justify-content-center" style={{paddingLeft: '0%', paddingBottom: '2%'}}>
          <Col md="auto" xs="auto" className="text-center">
            <div className='sub-title-mobile'>
              Training Room
            </div>
          </Col>
        </Row>
        {selectedItem === 0 ? (
          <EmptyCardLayout />
        ) : (
          <ActiveCardLayout 
            selectedItem={selectedItem}
            selectedAttribute={selectedAttribute}
            canTrainMapping={canTrainMapping}
            handleTraining={handleTraining}
            handleSelectAttribute={handleSelectAttribute}
            changeSelection={changeSelection}
            isMobile={isMobile}
          />
        )}
      </Container>

      <TrainingRoomModal
        show={showTrainingRoomModal}
        submittedAttempt={submittedAttempt}
        trainingAttemptResult={trainingAttempt}
        levelUpAttempt={levelUpAttempt}
        levelUpResult={levelUpResult}
        onHide={handleCloseTrainingRoomModal}
        isMobile={isMobile}
      />
    </div>
  );
};

const EmptyCardLayout = () => (
  <Row className="justify-content-center">
    <Col md={5} xs="auto" style={{display: "flex", justifyContent: "center"}}>
      <Figure className="justify-content-center">
        <Row>
          <PlayerCardSingle
            cardData={0}
            cardSelection={0}
            cardFunction={() => {}}
          />
        </Row>
      </Figure>
    </Col>
    <Col md={7} xs="auto" style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
      <Row style={{ paddingLeft:"1%"}}> 
        <Col className="justify-content-center">
          <div style={{fontStyle:"italic"}}>
            Player not selected.
          </div>
        </Col>
      </Row>
      <Row style={{ paddingLeft:"1%"}}> 
        <Col className="justify-content-center"> 
          <div style={{fontStyle:"italic"}}>
            No attributes to display.
          </div>
        </Col>
      </Row>
    </Col>
  </Row>
);

const ActiveCardLayout = ({ 
  selectedItem, 
  selectedAttribute, 
  canTrainMapping, 
  handleTraining, 
  handleSelectAttribute, 
  changeSelection,
  isMobile 
}) => {
  if (!canTrainMapping || !canTrainMapping[selectedItem.tokenId]) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <div className="spinner-border text-dark" role="status">
            <span className="visually-hidden"></span>
          </div>
        </Col>
      </Row>
    );
  }

  const canTrain = canTrainMapping[selectedItem.tokenId][0];
  const trainingCooldown = canTrainMapping[selectedItem.tokenId][1];

  return canTrain ? (
    <Row >
      <Col md={6} xs={12}style={{display: "flex", justifyContent: "center"}}>
        <Figure className="justify-content-center">
          <Row style={{ paddingLeft:"5%"}}>
            <PlayerCardSingle
              cardData={selectedItem}
              cardSelection={selectedItem.tokenId}
              cardFunction={changeSelection}
            />
          </Row>
          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
            <div style={{fontWeight: "bold"}}>
              {selectedItem.title}
            </div>
          </Row>
          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
            <Col className="d-flex justify-content-center align-items-center">
              <button 
                className="gradient-button"
                onClick={() => handleTraining(selectedItem, selectedAttribute)}
              >
                <div className='sub-title-mobile'>
                  Train 
                </div>
              </button> 
            </Col>
          </Row>
        </Figure>
      </Col>
      <PlayerAttributesTraining
        selectedItem={selectedItem}
        selectedAttribute={selectedAttribute}
        handleSelectAttribute={handleSelectAttribute}
        isMobile={isMobile}
        canTrain={canTrain}
        trainingCooldown={trainingCooldown}
        md={4}
        xs={12}
      />
    </Row>
  ) : (
    <Row className="justify-content-center">
      <Col md={3} xs={12} style={{display: "flex", justifyContent: "center"}}>
        <Figure className="justify-content-center">
          <Row style={{ paddingLeft:"5%"}}>
            <PlayerCardSingle
              cardData={selectedItem}
              cardSelection={selectedItem.tokenId}
              cardFunction={changeSelection}
            />
          </Row>
          <Row style={{ paddingLeft:"5%", paddingTop: '5%'}} className="justify-content-center">
            {selectedItem.title}
          </Row>
        </Figure>
      </Col>
      <Col md={3} xs={10} style={{display: "flex", justifyContent: "center", paddingLeft: '3%'}}>
        <PlayerAttributesTraining
          selectedItem={selectedItem}
          selectedAttribute={selectedAttribute}
          handleSelectAttribute={handleSelectAttribute}
          isMobile={isMobile}
          canTrain={canTrain}
          trainingCooldown={trainingCooldown}
          md={12}
          xs={10}
        />
      </Col>
    </Row>
  );
};

export default TrainingRoom; 