import React, { Component } from 'react'
import { Col, Spinner, Row, Container} from 'react-bootstrap'

import PlayerSlimCardSpring from './PlayerSlimCardSpring';
import * as players from '../../helpers/players.js';

class TeamListAndAttributes extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      loaded: false,
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      formIndexes: [[1,4],[4,9],[9,11]],
      cardSelected: 0,
      trainingStatus: {}
    }
    this.cardFunc = this.cardFunc.bind(this)
  }

  async componentDidMount() {
    this.init()
  }

  async init(){
    // console.log(this.props.ownedPlayers)
    let ownedPlayers = this.props.ownedPlayers;
    if (!(ownedPlayers !== undefined)){
      ownedPlayers = await players.getOwnedPlayers(this.props.account,this.props.CBAddress, this.props.web3);
    }
    this.setState({
      ownedPlayers: ownedPlayers
    })

    var cardsData = await Promise.all(ownedPlayers.map(async (item) => {
      const cardData = players.loadCardDataFull(item);
      return cardData;
    }));

    // console.log(cardsData)
    this.setState({
      cardsData : cardsData,
      loaded: true
    })
    this.setState({
      loaded: true
    })
  }


  cardFunc(tokenId) {
    // console.log(tokenId)
    let cardAlreadySelected = this.state.cardSelected
    if (cardAlreadySelected === tokenId){
      this.setState({
        cardSelected: 0
      })
    } else if (cardAlreadySelected > 0){
      let ownedNFTs = this.state.ownedNFTs
      let cardsData = this.state.cardsData
      let idxSelectedAlready = ownedNFTs.indexOf(cardAlreadySelected)
      let idxSelected = ownedNFTs.indexOf(tokenId)
      let cardDataAtIdx = cardsData[idxSelected];
      let cardDataAtIdxAlready = cardsData[idxSelectedAlready];
      ownedNFTs[idxSelected] = cardAlreadySelected;
      cardsData[idxSelected] = cardDataAtIdxAlready;
      ownedNFTs[idxSelectedAlready] = tokenId;
      cardsData[idxSelectedAlready] = cardDataAtIdx;
      this.setState({
        ownedNFTs: ownedNFTs,
        cardsData: cardsData,
        cardSelected: 0
      })
    } else {
      this.setState({
        cardSelected: tokenId
      })
    }
  }
  

  async setSelectedItem(item){
    // console.log(item,this.state.cardsData)
    let index = this.getIndex(item,this.state.cardsData)
    let cardData= this.state.cardsData[index];
    // if (this.props.canTrainMapping[item] === undefined) {
    //   await this.props.setCanTrainMapping(this.props.ownedTeamId);
    // }
    this.props.handleselect(cardData)
    // this.setModalShow(true)
  }

  getIndex(item,items){
    for (let i =0; i<items.length;i++){
      if(items[i].tokenId === item){
        return i;
      }
    }
  }

  setModalShow(sel){
    this.setState({
      menuModalShow:sel,
      loading:false,
    })
  }

  handleSelect(){
    this.setModalShow(false);
  }

  async handleHover(item){
    // console.log(item)
    if (this.props.canTrainMapping === undefined) {
      let canTrainMapping = await this.props.setCanTrainMapping(this.props.ownedTeamId);
      const trainingStatus = {};
      trainingStatus[item] = canTrainMapping[item];
      this.setState({
        trainingStatus: trainingStatus
      })
    } else {
      const trainingStatus = {};
      trainingStatus[item] = this.props.canTrainMapping[item];
      this.setState({
        trainingStatus: trainingStatus
      })
    }
  }

  render() {
    return(
      <>
      {!this.state.loaded ? (
        <Container style={{width: '90%'}}>
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            <div>
              <Spinner animation="border" variant="dark" style={{width:'1rem',height:'1rem'}}/>
            </div>
          </Row>
        </Container>
      ):(
      <>
        {this.state.cardsData.length > 0 ? (
        <Container style={{width: '90%'}}>
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            {this.state.ownedPlayers
            .map((item,cardIndex) => (
              <Col key={cardIndex} md={2} xs={3} style={{ paddingLeft: '1%' }}>
                <div onClick={() => this.setSelectedItem(item.baller_id)} onMouseEnter={() => this.handleHover(item.baller_id)}>
                  <PlayerSlimCardSpring key={item.value}
                      cardData = {this.state.cardsData[cardIndex]} 
                      cardSelection = {this.state.cardSelected}
                  />
                </div>
                <Row className="justify-content-center" style={{paddingTop: "5%"}}>
                {
                  this.state.trainingStatus[item.baller_id] !== undefined ? 
                  this.state.trainingStatus[item.baller_id][0] ? 
                  (
                    <div style={{backgroundColor: 'rgb(250, 255, 249)', padding: "5px", borderRadius: "5px"}}>
                      <div style={{fontWeight:'bold',borderBottom: '5px solid green'}}>
                        I'm ready!
                      </div>
                    </div>
                  ) : (
                    <div style={{backgroundColor: 'rgb(250, 255, 249)', padding: "5px", borderRadius: "5px"}}>
                      <div style={{fontStyle:'italic'}}>
                        I'm tired coach...
                      </div>
                    </div>
                  )
                  : (
                    <div style={{backgroundColor: 'rgb(250, 255, 249)', padding: "5px", borderRadius: "5px"}}>
                      <div className="chat-bubble">
                      Hey Coach!
                      </div>
                    </div>
                  )
                }
                </Row>
              </Col>
            ))} 
          </Row>
        </Container>
        ):null}
        </>)}


    {/* <PlayerCardModal
        show={this.state.menuModalShow}
        onHide={() => this.setModalShow(false)}
        handleselect= {this.handleSelect}
        carddata={this.state.cardsData[this.state.cardSelected-1]}
        index= {this.state.cardSelected-1}
    /> */}

      </>
    );
}
}

export default TeamListAndAttributes;