import React from 'react';
import { Row, Col } from 'react-bootstrap';

const JoinDivisionBox = ({ minCardWidth, cardHeight, onJoinLeague, ownedTeamId }) => {
    return (
        <Row 
            className="box-shadow-simple justify-content-center" 
            style={{
                paddingTop: "2%",
                backgroundColor: '#fff',
                width: "80%",
                minWidth: minCardWidth,
                minHeight: cardHeight,
                margin: "auto",
                marginBottom: "2%",
                borderRadius: "10px"
            }}
        >
            <Col md="auto">
                <Row className='justify-content-center' style={{fontStyle: 'italic', paddingBottom: "5%"}}>
                    No Games Available to Play
                </Row>
                <Row className='justify-content-center'>
                    <Col md={12} xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <button 
                            className="gradient-button-2" 
                            onClick={() => onJoinLeague(ownedTeamId)}
                        >
                            <div className="sub-title-mobile">
                                Join a Division!
                            </div>
                        </button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default JoinDivisionBox; 