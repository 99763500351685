import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useSpring, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import * as players from '../../helpers/players.js';

const PlayerSlimCardSpring = ({ cardData, cardSelection, size, backgroundColor = '#ffffff', border = true}) => {
  const [modifiedCardData, setModifiedCardData] = useState(cardData);
  const [isHovered, setIsHovered] = useState(false);

  // Define cardRef before using it
  const cardRef = useRef(null);

  // React Spring setup
  const [{ x, y, scale, rotateX, rotateY }, api] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateX: 0,
    rotateY: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  // Gesture setup using @use-gesture/react
  useGesture(
    {
      onMove: ({ xy: [px, py], dragging }) => {
        if (!dragging) {
          const rect = cardRef.current.getBoundingClientRect();
          const dx = px - (rect.left + rect.width / 2);
          const dy = py - (rect.top + rect.height / 2);
          api.start({
            rotateX: (-dy / 20),
            rotateY: (dx / 20),
            scale: 1.1,
          });
        }
      },
      onHover: ({ hovering }) => {
        setIsHovered(hovering);
        if (!hovering) {
          api.start({
            rotateX: 0,
            rotateY: 0,
            scale: 1,
          });
        }
      },
    },
    {
      target: cardRef,
      eventOptions: { passive: false },
    }
  );

  // useEffect(() => {
  //   setModifiedCardData(players.replaceItemColorWithTransparency(cardData, "#ffffff"));
  // }, [cardData]);

  return (
    <animated.div
      ref={cardRef}
      style={{
        transform: `perspective(1000px)`,
        x,
        y,
        scale,
        rotateX,
        rotateY,
      }}
    >
      <Card
        className="tilt"
        style={{
          boxShadow: border ? '0px 2px 10px rgba(0, 0, 0, 0.5)' : 'none',
          border: border ? '' : 'none',
          padding: '3%',
          cursor: isHovered ? 'pointer' : 'default',
          backgroundColor: backgroundColor,
          width: size ? size.width : '100%',
          height: size ? size.height : '100%',
        }}
      >
        <Card.Img variant="top" src={modifiedCardData.imgSrc} />
      </Card>
    </animated.div>
  );
};

export default PlayerSlimCardSpring;
