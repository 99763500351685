import React from 'react';
import Navbar from '../navbar/Navbar';
import LoginBanner from '../login/LoginBanner';
import './Layout.scss';

const Layout = ({hideNavbarOnRender,hideBannerOnRender,loginButtonFallback, signer, loginMethod, startTimer, isMobile, children }) => {
  const isFullScreen = hideNavbarOnRender && hideBannerOnRender;
  return (
    <div className={`layout-grid ${isFullScreen ? 'full-screen' : ''}`}>
      {!hideNavbarOnRender && <Navbar />}
      {!hideBannerOnRender && <LoginBanner
        loginButtonFallback={loginButtonFallback}
        signer={signer}
        loginMethod={loginMethod}
        startTimer={startTimer}
        isMobile={isMobile}
      />}
      <div className="content-area">
        {children}
      </div>
    </div>
  );
};

export default Layout;
