import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown,Col, Row } from 'react-bootstrap';
import INFTContract from '../contracts/INFTContract.json'

import CardsGrid from '../components/cardsGrid/CardsGrid';
import SignInButton from '../components/SignInButton';
import TxGeneralModal from '../components/modal/TxGeneralModal';
import Accordion from '../components/accordion/Accordion';
import PlayerComparison from '../components/playerComparison/PlayerComparison';
import FormationMenu from '../components/formationMenu/FormationMenu';

import * as players from '../helpers/players.js';
import * as coreWeb3 from '../helpers/coreWeb3.js';
import { transformToBNotation } from '../helpers/utils';
import backgroundImage from "../static/bg-stadium-3.png";

class Formation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: 0,
      startAnimation: false,
      selectedPlayerData: [],
      formationTypes: ["4-4-2","4-3-3","4-5-1","5-4-1","3-5-2"],
    }
    this.formationSelectionFallback = this.formationSelectionFallback.bind(this);
    this.playerSelectionFallback = this.playerSelectionFallback.bind(this);
    this.changeComparePlayer = this.changeComparePlayer.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
  }
  
  async componentDidMount(){
    await this.checkSize();
    await this.props.formationCallback();
  }


  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  stringToArrayOfNumbers(str) {
    return str.split('-').map(Number);
  }

  setSelectedItem(item){
    let formationArray = this.stringToArrayOfNumbers(item)
    this.setState({
      selectedFormation: item,
      formationArray: formationArray
    })
  }

  formationSelectionFallback(formArray, type){
    // console.log(formArray, type)
    this.setState({
      selectedFormationArray: formArray,
      formationType: type
    })
  }

  onToggleClick(){
    let selectedPlayerData = this.state.selectedPlayerData
    if (selectedPlayerData.length > 0) {  
      let newPlayerData = this.props.ownedPlayers[0];
      let title = "Baller #"+ (newPlayerData.baller_id.toString().startsWith('8453') ? transformToBNotation(newPlayerData.baller_id) : newPlayerData.baller_id);
      let tokenId = newPlayerData.baller_id;
      let imgSrc = players.uriToImage(newPlayerData.baller_uri);
      const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
      newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
      this.playerSelectionFallback(selectedPlayerData[0],newPlayerData)
    }
  }

  playerSelectionFallback(playerData1,playerData2){
    // console.log(playerData1,playerData2)
    if (playerData2 === undefined){
      playerData1.title = "Baller #"+ (playerData1.tokenId.toString().startsWith('8453') ? transformToBNotation(playerData1.tokenId) : playerData1.tokenId);
      let newPlayerData = this.props.ownedPlayers[0];
      let title = "Baller #"+ (newPlayerData.baller_id.toString().startsWith('8453') ? transformToBNotation(newPlayerData.baller_id) : newPlayerData.baller_id);
      let tokenId = newPlayerData.baller_id;
      let imgSrc = players.uriToImage(newPlayerData.baller_uri);
      const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
      newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
      this.setState({
        startAnimation: true,
        selectedPlayerData: [playerData1,newPlayerData]
      })
    } else {
      this.setState({
        startAnimation: true,
        selectedPlayerData: [playerData1,playerData2]
      })
    }
  }

  changeComparePlayer(roleId,changeId, side){
    // console.log(roleId,changeId, side)
    let selectedPlayerData = this.state.selectedPlayerData;
    if (roleId === "secondary"){
      let index = this.props.ownedPlayers.findIndex((item) => item.baller_id === changeId);
      let newPlayerData = {}
      if (side === "Back"){ 
        newPlayerData = index > 0 ? this.props.ownedPlayers[index - 1] : this.props.ownedPlayers[0];
      } else {
        newPlayerData = index < this.props.ownedPlayers.length - 1 ? this.props.ownedPlayers[index + 1] : this.props.ownedPlayers[0];
      }
      let title = "Baller #"+ (newPlayerData.baller_id.toString().startsWith('8453') ? transformToBNotation(newPlayerData.baller_id) : newPlayerData.baller_id);
      let tokenId = newPlayerData.baller_id;
      let imgSrc = players.uriToImage(newPlayerData.baller_uri);
      const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
      newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
      this.setState({
        startAnimation: true,
        selectedPlayerData: [selectedPlayerData[0],newPlayerData]
      })
    } else {
      let index = this.props.ownedPlayers.findIndex((item) => item.baller_id === changeId);
      let newPlayerData = {}
      if (side === "Back"){ 
        newPlayerData = index > 0 ? this.props.ownedPlayers[index - 1] : this.props.ownedPlayers[0];
      } else {
        newPlayerData = index < this.props.ownedPlayers.length - 1 ? this.props.ownedPlayers[index + 1] : this.props.ownedPlayers[0];
      }
      let title = "Baller #"+ (newPlayerData.baller_id.toString().startsWith('8453') ? transformToBNotation(newPlayerData.baller_id) : newPlayerData.baller_id);
      let tokenId = newPlayerData.baller_id;
      let imgSrc = players.uriToImage(newPlayerData.baller_uri);
      const [skillsName, skillsValue] = players.getSkillsFromPlayerData(newPlayerData)
      newPlayerData = {title, tokenId, imgSrc,skillsName,skillsValue}
      this.setState({
        startAnimation: true,
        selectedPlayerData: [newPlayerData,selectedPlayerData[1]]
      })
    }
  }

  async handleSaveFormation(){
    if (!this.props.ownedTeamId || this.props.ownedTeamId <= 0) {
      return "No team selected! ";
    }
    let network = await this.props.web3.eth.net.getId();
    if (network !== 8453){
      await coreWeb3.switchToChain(this.props.web3, "0x2105");
    }
    if (network !== 8453){
      return;
    }
    this.setState({
      showGeneralTxModal: true
    })
    let web3 = this.props.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
    var firstEleven = []
    var index = 0;
    if (this.state.selectedFormationArray === undefined){
      firstEleven = this.props.ownedPlayers.slice(0,11)
      firstEleven.push(this.props.ownedPlayers[10])
    } else {
      firstEleven = this.state.selectedFormationArray
    }
    if (this.state.formationArray !== undefined) {
      var ft = this.state.formationArray.join('-')
      index = this.state.formationTypes.indexOf(ft)
    }
    firstEleven = firstEleven.map((item) => { return item.baller_id })
    if (this.props.nextGameId != 0 && this.props.nextGameId !== undefined){
      try{
        await CBContract.methods.setFormation(this.props.nextGameId,this.props.ownedTeamId,index, firstEleven).send({
          from: this.props.account,
          maxPriorityFeePerGas: null, 
          maxFeePerGas: null
        }).on('receipt', txHash =>
        {
            console.log(txHash)
            this.setState({
              generalModalStatus: 'success',
            })
        }
      )
      } catch (error) {
        this.setState({
          showGeneralTxModal: false
        })
        // console.log(error)
      }
    } else {
      this.setState({
        showGeneralTxModal: false
      })
      return this.handleSaveDefaultFormation()
    }
  }

  async handleSaveDefaultFormation(){
    if (!this.props.ownedTeamId || this.props.ownedTeamId <= 0) {
      return "No team selected! ";
    }

    this.setState({
      showGeneralTxModalGame: true
    })
    let web3 = this.props.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.props.CBAddressL2);
    var firstEleven = []
    var index = 0;
    if (this.state.selectedFormationArray === undefined){
      firstEleven = this.props.ownedPlayers.slice(0,11)
      firstEleven.push(this.props.ownedPlayers[10])
    } else {
      firstEleven = this.state.selectedFormationArray
    }
    if (this.state.formationArray !== undefined) {
      var ft = this.state.formationArray.join('-')
      index = this.state.formationTypes.indexOf(ft)
    }
    
    firstEleven = firstEleven.map((item) => { return item.baller_id })
    // console.log(index,firstEleven,this.state.selectedFormationArray)
    coreWeb3.switchToChain(this.props.web3, this.props.ownedTeamChainId);
    if (firstEleven.length > 0){
      if (firstEleven.length === 11){
        firstEleven.push(firstEleven[firstEleven.length - 1]); // add SP taker to the end if not defined
      }
      try{
        await CBContract.methods.setDefaultFormation(this.props.ownedTeamId,index, firstEleven).send({ 
          from: this.props.account,
          maxPriorityFeePerGas: null, 
          maxFeePerGas: null
        }).on('receipt', txHash =>
          {
              console.log(txHash)
              this.setState({
                generalModalStatusGame: 'success',
              })
          }
        )
      } catch (error) {
        this.setState({
          showGeneralTxModalGame: false
          })
          // console.log(error)
      }
    } else {
      this.setState({
        showGeneralTxModalGame: false
      })
      return "No changes to save";
    }
  }


  render(){
    // console.log(this.state.startAnimation, this.state.selectedPlayerData)
    // console.log(this.state.selectedFormation, this.state.formationArray)
    return (
      <>
      <div style={ !this.props.isMobile ? {paddingTop: "0%"} : {paddingTop: "0px"}}>
      <div style={!this.props.isMobile ? (this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
          backgroundBlendMode: 'soft-light',
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat',
          minHeight: '160vh',
          minWidth: '100%'
        } : {}) : (
          this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            minHeight: '150vh',
            minWidth: '100%'
          } : {})
        }>
        <Row style={{display: 'flex', justifyContent: 'center', paddingTop: "1%"}}>
            <span className='sub-title-mobile'>
              Formation 
            </span>
        </Row>

        <FormationMenu 
          selectedFormation={this.state.selectedFormation}
          formationTypes={this.state.formationTypes}
          onFormationSelect={(item) => this.setSelectedItem(item)}
          onSaveFormation={() => this.handleSaveFormation()}
          onSaveDefaultFormation={() => this.handleSaveDefaultFormation()}
          nextGameId={this.props.nextGameId}
          isMobile={this.props.isMobile}
        />

        {this.props.account !== undefined && this.props.account !== 0 ? (
          <>
          {this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? (
            <>
            <div style={this.props.isMobile ? {paddingBottom: "22%"}: {paddingBottom: "1%"}}>
              <Accordion
                onToggleClick = {this.onToggleClick}
              >
                <PlayerComparison
                  startAnimation={this.state.startAnimation}
                  selectedPlayerData={this.state.selectedPlayerData}
                  formationArray={this.state.formationArray}
                  changeComparePlayer={this.changeComparePlayer}
                  showAttrOf={this.state.showAttrOf}
                  isMobile={this.props.isMobile}
                />
              </Accordion>
            </div>
            <Row style={!this.props.isMobile ? {paddingTop: '7%',minWidth: '960px'}:null}>
              <CardsGrid
                account = {this.props.account}
                web3 = {this.props.web3}
                formationTypes = {this.state.formationTypes}
                formationArray = {this.state.formationArray}
                defaultFormationArray = {this.state.defaultFormationArray}
                teamNFTAddress=  {this.props.teamNFTAddress}
                CBAddress = {this.props.CBAddress}
                ownedTeamId = {this.props.ownedTeamId}
                ownedTeamChainId = {this.props.ownedTeamChainId}
                ownedPlayers={this.props.ownedPlayers}
                getOwnedTeam = {this.props.getOwnedTeam}
                isMobile={this.props.isMobile}
                key={this.props.ownedPlayers}
                getPlayersData = {this.props.getPlayersData}
                formationSelectionFallback={this.formationSelectionFallback}
                playerSelectionFallback={this.playerSelectionFallback}
              />
            </Row>
            </>
          ):(
            <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
              <Col md="auto" xs="auto">
              <Link to="/app" style={{ textDecoration: 'none', color: 'inherit' }}>
                <button className="button-1">
                  Create a team to play a game.
                </button>
              </Link>
              </Col>
            </Row>
          )}
          </>
        ):(
          <Row className="justify-content-center" style={{ paddingTop: '2%'}}>
            <Col md="auto" xs="auto">
              <div className="sub-title-mobile">
                Sign in to display your players
              </div>
              <SignInButton
                loginButtonFallback={this.props.loginButtonFallback}
                customText={'Sign in'}
                accountListenerFallback={this.props.accountListenerFallback}
                isMobile={this.props.isMobile}
              />
            </Col>
          </Row>
        )}
      </div>
      </div>
      <TxGeneralModal 
        show={this.state.showGeneralTxModal}
        status={this.state.generalModalStatus}
        statusMsg={"Your default formation has been saved. It will be used for all matches unless a game formation is set."}
        onHide={() => this.setState({showGeneralTxModal:false})}
        isMobile={this.props.isMobile}
      />
      <TxGeneralModal 
        show={this.state.showGeneralTxModalGame}
        status={this.state.generalModalStatusGame}
        statusMsg={"Formation saved, you are all set for your next game!"}
        onHide={() => this.setState({showGeneralTxModalGame:false})}
        isMobile={this.props.isMobile}
      />
      </>
    );
  }
}

export default Formation;
