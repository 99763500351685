import React from 'react';
import { useTransition, animated } from '@react-spring/web';
import GameCard from '../gamesGrid/GameCard';
import styles from './AnimatedCards.module.css';

// Add shuffle utility function at the top
function shuffleArray(array) {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

function AnimatedCards({ getGames, getGamesCallback, ownedTeamLeague, playGame }) {
  const isMobile = window.innerWidth < 1000;
  const [teams, setTeams] = React.useState([]);
  const [shuffledTeams, setShuffledTeams] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  // Add placeholder data
  const placeholderGames = [
    {
      gameId: 'placeholder-1',
      gameStart: 1740391300,
      height: 200,
      css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
      isPlaceholder: true
    },
    {
      gameId: 'placeholder-2',
      gameStart: 1740391300,
      height: 200,
      css: 'linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%)',
      isPlaceholder: true
    },
    {
      gameId: 'placeholder-3',
      gameStart: 1740391300,
      height: 200,
      css: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
      isPlaceholder: true
    }
  ];

  const handlePlayGame = async (gameId, index) => {
    console.log(`Playing game ${gameId}`);
    // await playGame(gameId);
  };

  React.useEffect(() => {
    // Initial load of games
    const loadGames = async () => {
      try {
        const availableGames = await getGames(ownedTeamLeague);
        if (availableGames && availableGames.length > 0) {
          const formattedGames = availableGames.map(game => ({
            ...game,
            height: 200,
            css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)'
          }));
          setTeams(formattedGames);
          setShuffledTeams(shuffleArray(formattedGames));
          getGamesCallback(formattedGames)
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading games:', error);
        setIsLoading(false);
      }
    };

    loadGames();

    // Data refresh timer (30s)
    // const refreshTimer = setInterval(() => {
    //   loadGames();
    // }, 30000);

    // Shuffle timer (3s)
    const shuffleTimer = setInterval(() => {
      setShuffledTeams(shuffledTeams => shuffleArray([...shuffledTeams]));
    }, 3000);

    return () => {
    //   clearInterval(refreshTimer);
      clearInterval(shuffleTimer);
    };
  }, [getGames, ownedTeamLeague]);

  let height = 0;
  const cardSpacing = isMobile ? 350 : 240; // Add spacing between cards
  const transitions = useTransition(
    (isLoading ? placeholderGames : shuffledTeams).map(data => ({
      ...data,
      y: (height += cardSpacing) - cardSpacing // Use cardSpacing instead of data.height
    })),
    {
      key: (item) => item.gameId,
      from: { height: 0, opacity: 0, transform: 'translate3d(0,-40px,0)' },
      leave: { height: 0, opacity: 0, transform: 'translate3d(0,-40px,0)' },
      enter: ({ y, height }) => ({ 
        y, 
        height: cardSpacing, // Use fixed height
        opacity: 1, 
        transform: 'translate3d(0,0px,0)' 
      }),
      update: ({ y, height }) => ({ 
        y, 
        height: cardSpacing, // Use fixed height
        transform: 'translate3d(0,0px,0)' 
      }),
    }
  );

//   console.log("teams: ", teams);
  return (
    <div className={styles.list} style={{ 
      height: height, // Use total calculated height
      position: 'relative', 
      width: '100%', 
      maxWidth: '800px', 
      margin: '0 auto' 
    }}>
      {transitions((style, item, _, index) => (
        <animated.div
          className={`${styles.card} ${item.isPlaceholder ? styles.pulse : ''}`}
          style={{
            zIndex: (isLoading ? placeholderGames : shuffledTeams).length - index,
            position: 'absolute',
            top: 0,
            width: '100%',
            ...style,
          }}
        >
          <GameCard
            slide={item}
            isMobile={window.innerWidth < 1000}
            onPlayGame={handlePlayGame}
            buttonStyle={{ 
              className: "gradient-button-2",
            }}
            index={index}
          />
        </animated.div>
      ))}
    </div>
  );
}

export default AnimatedCards;
