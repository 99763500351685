import React, { useRef } from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { transformToBNotation } from '../../helpers/utils'
import PitchComponent from '../pitch/PitchComponent'
import styles from './TeamPreview.module.css'


const VSComponent = ({ offset, homeTeamName, awayTeamName, homeFormation, awayFormation }) => (
  <>
    {offset === 0 && (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <span style={{ fontSize: '10rem', color: 'deeppink', position: 'absolute', top: '15%', left: '5%', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
          {homeTeamName.length > 12 ? homeTeamName.split(' ')[0] : homeTeamName}
        </span>
        {homeTeamName.length > 12 && (
          <span style={{ fontSize: '10rem', color: 'deeppink', position: 'absolute', top: '28%', left: '5%', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
            {homeTeamName.split(' ')[1]}
          </span>
        )}
        <span style={{ position: 'absolute', fontSize: '15rem', top: '50%', left: '40%', fontWeight: 'bold', fontStyle: 'italic' }}>
          VS
        </span>
        <span style={{ fontSize: '10rem', color: 'slateblue', position: 'absolute', bottom: `${awayTeamName.length > 12 ? '22%' : '12%'}`, left: `${(Math.max(5, 40 - Math.max(0, (awayTeamName.length - 7) * 5)))}%`, fontWeight: 'bold', whiteSpace: 'pre-line' }}>
          {awayTeamName.length > 12 ? awayTeamName.split(' ')[0] : awayTeamName}
        </span>
        {awayTeamName.length > 12 && (
          <span style={{ fontSize: '10rem', color: 'slateblue', position: 'absolute', bottom: '8%', left: `${(Math.max(5, 40 - Math.max(0, (awayTeamName.length - 7) * 5)))}%`, fontWeight: 'bold', whiteSpace: 'pre-line' }}>
            {awayTeamName.split(' ')[1]} {awayTeamName.split(' ')[2]}
          </span>
        )}
      </div>
    )}
    {offset === 1 && (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <span style={{ fontSize: '10rem', color: 'deeppink', position: 'absolute', top: '15%', left: '5%', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
          {homeTeamName}
        </span>
        <div style={{position: 'absolute', top: '30%', left: '5%'}}>
          <ul style={{ listStyleType: 'none', paddingBottom: 20 }}>
            {homeFormation && homeFormation[1] && homeFormation[1].map((player, index) => (
              <li key={index} style={{ fontSize: '2rem', color: 'deeppink', margin: '2rem 0', color: 'white' }}>
                {player.baller_name.startsWith('{') 
                  ? `${player.baller_name} (${player.id})` 
                  : `${player.baller_name.split(' ')[0][0]}. ${player.baller_name.split(' ').slice(1).join(' ')}`}
              </li>
            ))}
          </ul>
        </div>
        <div style={{position: 'absolute', top: '20%', left: '50%'}}>
          <PitchComponent
            homeFormation={homeFormation}
            awayFormation={awayFormation}
            displayTeam="home"
            isMobile={false}
          />
        </div>
      </div>
    )}
    {offset === 2 && (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <span style={{ fontSize: '10rem', color: 'slateblue', position: 'absolute', top: '15%', left: '5%', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
          {awayTeamName}
        </span>
        <div style={{position: 'absolute', top: '30%', left: '5%'}}>
          <ul style={{ listStyleType: 'none', paddingBottom: 20 }}>
            {awayFormation && awayFormation[1] && awayFormation[1].map((player, index) => (
              <li key={index} style={{ fontSize: '2rem', color: 'slateblue', margin: '2rem 0', color: 'white' }}>
                {player.baller_name.startsWith('{') 
                  ? `Player #${player.baller_id.toString().startsWith('8453') ? transformToBNotation(player.baller_id) : player.baller_id}` 
                  : `${player.baller_name.split(' ')[0][0]}. ${player.baller_name.split(' ').slice(1).join(' ')}`}
              </li>
            ))}
          </ul>
        </div>
        <div style={{position: 'absolute', top: '20%', left: '50%'}}>
          <PitchComponent
            homeFormation={homeFormation}
            awayFormation={awayFormation}
            displayTeam="away"
            isMobile={false}
          />
        </div>
      </div>
    )}
  </>
)

const Page = ({ offset, gradient, onClick, homeTeamName, awayTeamName, homeFormation, awayFormation }) => (
  <>
    <ParallaxLayer offset={offset} speed={0.2} onClick={onClick}>
      <div className={styles.slopeBegin} />
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.6} onClick={onClick}>
      {/* <div className={`${styles.slopeEnd} ${styles[gradient]}`} /> */}
    </ParallaxLayer>

    <ParallaxLayer className={`${styles.text} ${styles.number}`} offset={offset} speed={0.3}>
      <VSComponent offset={offset} homeTeamName={homeTeamName} awayTeamName={awayTeamName} homeFormation={homeFormation} awayFormation={awayFormation} />
    </ParallaxLayer>
  </>
)

export default function TeamPreview({ onScrollEnd, selectedGame, homeFormation, awayFormation }) {
  const parallax = useRef(null)

  const getTeamName = (teamName, teamId) => {
    return teamName || `Team ${teamId.toString().startsWith('8453') ? transformToBNotation(teamId): teamId}`
  }

  const homeTeamName = getTeamName(selectedGame.teamNameHome, selectedGame.home_team_id)
  const awayTeamName = getTeamName(selectedGame.teamNameAway, selectedGame.away_team_id)

  const scroll = (to) => {
    if (parallax.current) {
      parallax.current.scrollTo(to)
      if (to === 0) {
        onScrollEnd && onScrollEnd()
      }
    }
  }

  // console.log(homeFormation, awayFormation)
  return (
    <div style={{ background: '#dfdfdf' }}>
      <Parallax ref={parallax} className={styles.container} pages={3} horizontal>
        <Page offset={0} gradient="tomato" onClick={() => scroll(1)} homeTeamName={homeTeamName} awayTeamName={awayTeamName} homeFormation={homeFormation} awayFormation={awayFormation} />
        <Page offset={1} gradient="pink" onClick={() => scroll(2)} homeTeamName={homeTeamName} awayTeamName={awayTeamName} homeFormation={homeFormation} awayFormation={awayFormation} />
        <Page offset={2} gradient="teal" onClick={() => scroll(0)} homeTeamName={homeTeamName} awayTeamName={awayTeamName} homeFormation={homeFormation} awayFormation={awayFormation} />
      </Parallax>
    </div>
  )
}
