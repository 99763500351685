import React from 'react';
import PropTypes from 'prop-types';
import PlayerSlimCardSpring from '../nft/PlayerSlimCardSpring';
import './Pitch.scss';
import { Row, Col } from 'react-bootstrap';

function TeamRenderer({ team, isMobile }) {
    // Preprocess team data to ensure each player has an imgSrc
    const formattedTeam = Object.keys(team.squad).reduce((acc, position) => {
        if (team.squad[position].length > 0) {
            acc[position] = team.squad[position].map(player => ({
                ...player,
                imgSrc: player.image
            }));
        } else {
            acc[position] = {
                ...team.squad[position]
            };
        }
        return acc;
    }, {});

    // Define the order of positions and their respective rows
    const positionRows = {
        row0: ['gk'],
        row1: ['df'],
        row2: ['cm'],
        row3: ['fw']
    };

    // console.log(formattedTeam)
    return (
        <div >
            {Object.keys(positionRows).map((rowKey, rowIndex) => (
                <Row key={rowIndex} className="justify-content-center" style={{ paddingBottom: '5%', paddingTop: '0' }}>
                    {positionRows[rowKey].map((position) => (
                        formattedTeam[position].map((player, playerIndex) => (
                            <Col key={playerIndex} md="auto" xs="auto">
                                <PlayerSlimCardSpring 
                                    key={playerIndex} 
                                    cardData={player} 
                                    backgroundColor="#00000000" 
                                    border={false} 
                                    size={{width: isMobile ? 80 : 90, height: isMobile ? 70 : 90}}
                                />
                                <div style={{
                                    position: 'relative', 
                                    top: position === 'gk' ? 20 : 30, 
                                    left: 0, 
                                    width: '100%', 
                                    height: '100%', 
                                    color: 'black',
                                    fontSize: isMobile ? '0.8em' : '1em'
                                }}>
                                    {player.name}
                                </div>
                            </Col>
                        ))
                    ))}
                </Row>
            ))}
        </div>
    );
}

TeamRenderer.propTypes = {
    team: PropTypes.shape({
        gk: PropTypes.object.isRequired,
        df: PropTypes.arrayOf(PropTypes.object).isRequired,
        cm: PropTypes.arrayOf(PropTypes.object).isRequired,
        fw: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired
};

export default TeamRenderer;
