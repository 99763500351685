import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import bgPixels from '../../static/pixel-head-bg.png';
import Description from '../description/Description.js';
import HomeTransition from './HomeTransition';

const HomeHero = ({ isMobile, handlePlayClick }) => {
  const [showTransition, setShowTransition] = useState(false);

  const handlePlay = () => {
    setShowTransition(true);
    handlePlayClick();
  };

  if (showTransition) {
    return <HomeTransition isMobile={isMobile} handlePlayClick={handlePlayClick} />;
  }

  return (
    <>
      {!isMobile ? (
        <>
          <img src={bgPixels} alt="Chainballers" className="logo" 
            style={{ 
              position: 'fixed', 
              width: "100%", 
              zIndex: -2, 
              top: 0, 
              left: 0
            }} 
          />
          <div style={{ position: 'relative', zIndex: 2 }}>
            <Row className="justify-content-md-center" style={{paddingTop:"2%"}}> 
              <Col md="auto">
                <div className='cb-title'>CHAINBALLERS</div>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{paddingTop:"0%"}}>
              <Col md="auto" xs="auto">
                <div className='sub-title'>
                  ONCHAIN FOOTBALL MANAGER GAME
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" style={{paddingTop:"5%"}}>
              <Col md="auto" style={{paddingBottom: "1%"}}>
                {/* <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}> */}
                  <button className='gradient-button-play' onClick={handlePlay}>
                    <div className='sub-title-neon'>PLAY</div>
                  </button>
                {/* </Link> */}
              </Col>
            </Row>
            <Row className="justify-content-center" style={{paddingTop:"2%"}}>
              <Description isMobile={false} />
            </Row>
          </div>
        </>
      ) : (
        <>
          <img src={bgPixels} alt="Chainballers" className="logo" 
            style={{ 
              position: 'fixed', 
              width: "200vw",
              zIndex: -1, 
              height:"200vw",
              top:0,
              left: 0,
              boxShadow: "0px 0px 120px 20px #57ef7c"
            }} 
          />
          <div style={{ position: 'relative', zIndex: 2 }}>
            <Row className="justify-content-center" style={{paddingTop:"0%"}}> 
              <Col md="auto" xs="auto">
                <div className='cb-title-mobile'>CHAINBALLERS</div>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{paddingTop:"0%"}}>
              <Col md="auto" xs="auto">
                <div className='sub-title-mobile'>
                   FOOTBALL MANAGER 
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default HomeHero; 