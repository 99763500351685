import React, { useContext, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import { Web3AuthContext } from '../../contexts/web3AuthContext';
import UserModal from "../UserModal/UserModal";
import { IoMdNotifications } from 'react-icons/io';
// Styles
import './LoginButton.scss';

const LoginButton = ({ isMobile, onLoginClick, loginText = "Sign In", style}) => {
  const { userInfo, isLoggedIn, login, logout } = useContext(Web3AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const handleIconClick = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleHeartClick = (e) => {
    setShowModal(true)
  };

  const handleLogin = async () => {
    try {
      const userData = await login(); 
      onLoginClick(userData); 
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = async () => {
    await logout();
    onLoginClick(); 
  };


  return (
    <div className="login-button-container" >
      {isLoggedIn ? (
        <div style={!isMobile ? { minHeight: '92px', display: 'flex', alignItems: 'center', position: 'relative' } : { display: 'flex', alignItems: 'center', position: 'relative' }}>
          <div style={{ position: 'relative' }}>
            {userInfo?.profileImage && !imageError ? (
              <button className="gradient-button-no-margin" onClick={handleIconClick}>
                <div style={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '10px'
                }}>
                  <div style={{ position: 'relative' }}>
                    <div 
                      onMouseEnter={() => setShowNotifications(true)}
                      onMouseLeave={() => setShowNotifications(false)}
                    >
                      <IoMdNotifications 
                        style={{ 
                          color: 'white', 
                          cursor: 'pointer',
                          fontSize: '24px',
                          zIndex: 1
                        }} 
                        onClick={handleHeartClick}
                      />
                      {userInfo.notifications.length > 0 && (
                        <div style={{
                          position: 'absolute',
                          top: '0',
                          right: '-4px',
                          width: '8px',
                          height: '8px',
                          backgroundColor: 'red',
                          borderRadius: '50%',
                          zIndex: 2
                        }} />
                      )}
                      {showNotifications && userInfo.notifications.length > 0 && (
                        <div className="notif">
                          <span className="notifItem">
                            <span className='notif-text' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                              <IoMdNotifications 
                                style={{ 
                                  color: 'white', 
                                  cursor: 'pointer',
                                  zIndex: 1
                                }} 
                                onClick={handleHeartClick}
                              />
                              {userInfo.notifications}
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span className='table-title' style={{ color: "black" }}>
                      Manager Id: 
                    </span>
                    <span className='sub-title-mobile'> 
                      {truncate(userInfo.wallet)} 
                    </span>
                  </div>
                </div>
              </button>
            ) : (
              <div style={{ paddingTop: isMobile ? '10px' : '0px', display: 'flex', alignItems: 'center' }}>
                <button className="gradient-button-no-margin" onClick={handleIconClick}>
                  <div style={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px'
                  }}>
                    <div style={{ position: 'relative' }}>
                      <div 
                        onMouseEnter={() => setShowNotifications(true)}
                        onMouseLeave={() => setShowNotifications(false)}
                      >
                        <IoMdNotifications 
                          style={{ 
                            color: 'white', 
                            cursor: 'pointer',
                            fontSize: '24px',
                            zIndex: 1
                          }} 
                          onClick={handleHeartClick}
                        />
                        {userInfo.notifications.length > 0 && (
                          <div style={{
                            position: 'absolute',
                            top: '2px',
                            right: '2px',
                            width: '8px',
                            height: '8px',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            zIndex: 2
                          }} />
                        )}
                        {showNotifications && userInfo.notifications > 0 && (
                          <div className="notif">
                            <span className="notifItem">
                              <span className='notif-text' style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <IoMdNotifications 
                                  style={{ 
                                    color: 'white', 
                                    cursor: 'pointer',
                                    zIndex: 1
                                  }} 
                                  onClick={handleHeartClick}
                                />
                                {userInfo.notifications}
                              </span>
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span className='table-title' style={{ color: "black" }}>
                        Manager Id: 
                      </span>
                      <span className='sub-title-mobile'> 
                        {truncate(userInfo.wallet)} 
                      </span>
                    </div>
                  </div>
                </button>
              </div>
            )}
          </div>      
          <UserModal
            showModal={showModal}
            handleClose={handleClose}
            userInfo={userInfo}
            logout={handleLogout}
          />
        </div>
      ) : (
        <button onClick={handleLogin} className='gradient-button-2' style={style}>
          <div className='sub-title-mobile'>
            {loginText} 
            {loginText === "Sign In" &&<IoIcons.IoIosFlash style={{ color: 'yellow', filter: 'drop-shadow(0px 0px 1px black)' }} />}
          </div>
        </button>
      )}
    </div>
  );
};

function truncate(str) {
    return str.substring(2, 8);
}

export default LoginButton;
