import React, { useState,useEffect } from 'react';
import Pitch from './Pitch.js';
import './Pitch.scss';
import { uriToImage, uriToSvgText, svgTextToImage, addArmsSvg, replaceSvgBackgroundColorWithTransparencyLong } from '../../helpers/players.js';
import TeamRenderer from './TeamRenderer.js';

function PitchComponent({isMobile, homeFormation, awayFormation, displayTeam, pitchColor = "rgba(0, 100, 145, 0.6)"}) {
    // console.log(homeFormation, awayFormation)
    const [homeFormationArray, setHomeFormationArray] = useState([]);
    const [awayFormationArray, setAwayFormationArray] = useState([]);
    const [homeTeam, setHomeTeam] = useState(undefined);
    const [awayTeam, setAwayTeam] = useState(undefined);

    useEffect(() => {
        // console.log(homeFormation[0])
        let homeFormationArray_ = []
        if (homeFormation !== undefined){ 
            if (homeFormation[0] == 0){
              homeFormationArray_ = [4,4,2]
              setHomeFormationArray([4,4,2])
            } else if (homeFormation[0] == 1){
              homeFormationArray_ = [4,3,3]
              setHomeFormationArray([4,3,3])
            } else if (homeFormation[0] == 2){
              homeFormationArray_ = [4,5,1]
              setHomeFormationArray([4,5,1])
            } else if (homeFormation[0] == 3){
              homeFormationArray_ = [5,4,1]
              setHomeFormationArray([5,4,1])
            } else if (homeFormation[0] == 4){
              homeFormationArray_ = [3,5,2]
              setHomeFormationArray([3,5,2])
            }
            const homeTeam = createTeamStructure(homeFormationArray_, {
                color: '#f08080', // Home team color
                numberColor: '#ffffff',
                nameColor: '#ffffff'
              }, homeFormation[1]
            );
            setHomeTeam(homeTeam)
        }
  
    }
    ,[homeFormation])

    useEffect(() => {
      // console.log(awayFormation[0])
      let awayFormationArray_ = []
      if (awayFormation !== undefined){
          if (awayFormation[0] == 0){
            awayFormationArray_ = [4,4,2]
            setAwayFormationArray([4,4,2])
          } else if (awayFormation[0] == 1){
            awayFormationArray_ = [4,3,3]
            setAwayFormationArray([4,3,3])
          } else if (awayFormation[0] == 2){
            awayFormationArray_ = [4,5,1]
            setAwayFormationArray([4,5,1])
          } else if (awayFormation[0] == 3){
            awayFormationArray_ = [5,4,1]
            setAwayFormationArray([5,4,1])
          } else if (awayFormation[0] == 4){
            awayFormationArray_ = [3,5,2]
            setAwayFormationArray([3,5,2])
          }
          const awayTeam = createTeamStructure(awayFormationArray_,{
              color: '#add8e6', // Away team color
              numberColor: '#00000',
              nameColor: '#333333'
            }, awayFormation[1]
          );
          setAwayTeam(awayTeam)
      }
    }
    ,[awayFormation])

    function createTeamStructure(formation, style, players) {
        // console.log(formation,players)
        const formatPlayerName = (fullName) => {
            if (fullName.startsWith('{')) {
                return "";
            }
            const [firstName, ...lastName] = fullName.split(' ');
            return `${firstName.charAt(0)}. ${lastName.join(' ')}`;
        };

        let svgText = uriToSvgText(players[0].baller_uri)
        svgText = replaceSvgBackgroundColorWithTransparencyLong(svgText, "#ffffff")
        const team = {
            squad: {
                gk: [{ name: formatPlayerName(players[0].baller_name), image: svgTextToImage(svgText) }], // Assuming always one goalkeeper
                df: [],
                cdm: [],
                cm: [],
                cam: [],
                fw: []
            },
            style: style
        };
    
        // Define the lines in the formation
        const lines = ['df', 'cm', 'fw']; // Adjust this if more lines are needed
    
        // Populate each line in the formation
        formation.forEach((numPlayers, index) => {
            for (let i = 0; i < numPlayers; i++) {
              let posIdx = index == 0 ? 0 : (index == 1 ? formation[0] : formation[0]+formation[1])
              // console.log(players[posIdx+i+1].baller_uri)
              let svgText = uriToSvgText(players[posIdx+i+1].baller_uri)
              svgText = addArmsSvg(svgText)
              svgText = replaceSvgBackgroundColorWithTransparencyLong(svgText, "#ffffff")
              team.squad[lines[index]].push({ name: formatPlayerName(players[posIdx+i+1].baller_name), image: svgTextToImage(svgText)});
            }
        });
        return team;
    }
    
    // console.log(homeTeam,awayTeam);
    return (
      <div >
        <div >
            <div style={!isMobile ? {transform: 'perspective(1000px) rotateX(40deg) rotate(90deg)'} : {transform: 'perspective(1000px) rotateX(30deg) rotate(90deg)', paddingLeft: "20%", position: 'relative'}}>
                <Pitch
                    size={ isMobile ? "small" : "big" }
                    pattern={"lines"}
                    color={pitchColor}
                    isMobile={isMobile}
                />
            </div>
            <div style={{position: 'absolute', top: isMobile ? 40 :  40, left: 0, width: '100%', height: '100%'}}>
              {displayTeam === 'home' && homeTeam && (
                  <TeamRenderer team={homeTeam} 
                    isMobile={isMobile}
                  />
              )}
              {displayTeam === 'away' && awayTeam && (
                  <TeamRenderer team={awayTeam} 
                    isMobile={isMobile}
                  />
              )}
            </div>
        </div>
      </div>
    )
}




export default PitchComponent;