import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import './GameViewEngine.scss';

function GameControl({ startGame, gameResult, gameStatus, gameStarted, gameId, handlePlayGameClick}) {

    return (
        <Row className="justify-content-center" style={{ paddingBottom: '4%' }} >
            {!gameStarted ? (
                <Col md="auto" style={{ width: "600px" }}>
                    <Row className="justify-content-center align-items-center">
                        <button 
                            className="button-engine" 
                            onClick={() => {
                                if (gameResult === "error" || gameResult === undefined) {
                                    handlePlayGameClick(gameId, 0);
                                } else {
                                    startGame(true);
                                }
                            }}
                            style={{ margin: 0, color: 'white' }}
                        >
                            {gameResult === "error" ? (
                                <div className="button-engine-text">
                                    Retry
                                </div>
                            ) : (
                                gameResult !== undefined ? (
                                    <div className="button-engine-text">
                                        {gameStatus.gameTime > 0 ? 'Resume' : 'Start'}
                                    </div>
                                ) : (
                                    <div className="button-engine-text">
                                        Game engine is loading..
                                        <Spinner animation="border" variant="dark" style={{ borderWidth: '4px', height: '1rem', width: '1rem' }} />
                                    </div>
                                )
                            )}
                        </button>
                    </Row>
                </Col>
            ) : (
                <button className="button-1" onClick={() => startGame(false)} style={{ margin: 0 }}>
                    Stop
                </button>
            )}
        </Row>
    );
}

export default GameControl;
