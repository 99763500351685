import React, { useRef, useState, useContext } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import SignInButton from '../SignInButton';
import TeamListLoaded from '../nft/teamListHome/TeamListLoaded';
import styles from './LandingParallax.module.scss';
import PitchComponent from '../pitch/PitchComponent';
import { landingFormation } from '../../data/landingFormation';
import backgroundImage from "../../static/stadium-pitch-ng-2.png";
import { App as RollupsCanvas } from '../leagues/leagueSelection/Rollups';

const Page = ({ offset, gradient, onClick, children }) => (
  <>
    <ParallaxLayer offset={offset} speed={0.2} onClick={onClick} style={{ pointerEvents: 'auto' }}>
      <div className={styles.slopeBegin} />
    </ParallaxLayer>

    <ParallaxLayer offset={offset} speed={0.6} onClick={onClick} style={{ pointerEvents: 'auto' }}>
      <div className={`${styles.slopeEnd} ${styles[gradient]}`} />
    </ParallaxLayer>

    <ParallaxLayer className={`${styles.text} ${styles.number}`} offset={offset} speed={0.3} style={{ pointerEvents: 'auto' }}>
      {children}
    </ParallaxLayer>
  </>
);

const LandingParallax = ({ isMobile, sampleTeam, loginButtonFallback }) => {
  const parallaxRef = useRef();
  const [showRollupModal, setShowRollupModal] = useState(false);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const handlePageClick = (pageNumber) => {
    parallaxRef.current?.scrollTo(pageNumber);
  };

  const handleRollupSelect = async (rollup) => {
    setAutoScrollEnabled(false);
    setShowRollupModal(true);
  };

  React.useEffect(() => {
    if (!autoScrollEnabled) return;
    
    let currentPage = 0;
    const timer = setInterval(() => {
      if (currentPage < 2) {
        parallaxRef.current?.scrollTo(currentPage + 1);
        currentPage++;
      } else {
        clearInterval(timer);
      }
    }, currentPage === 0 && isMobile ? 4000 : 3000);

    return () => clearInterval(timer);
  }, [autoScrollEnabled, isMobile]);

  const RollupModal = (props) => (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-modal"
    >
      <Modal.Header closeButton className="custom-close-button">
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
          Join League
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center'>
          <Col className="text-center mb-4">
            <p>Ready to start your journey?</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md="auto" xs="auto">
            <div onClick={() => props.onHide()}>
            <SignInButton
              customText={'Start'}
              isMobile={isMobile}
              loginButtonFallback={loginButtonFallback}
              style={{
                width: '200px',
                height: '50px',
                borderRadius: '10px',
              }}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );

  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      overflow: 'hidden',
      position: 'relative',
      top: 0,
      left: 0
    }}>
      <Parallax ref={parallaxRef} pages={3} horizontal style={{ position: 'absolute' }}>
        <Page offset={0} gradient="pink" onClick={() => handlePageClick(1)}>
          <Container style={{maxWidth: '1000px'}}>
            <Row className="justify-content-center" style={!isMobile ? {paddingTop: "10%", paddingBottom: "1%"} : {paddingTop: "10%", paddingBottom: "3%"}}>
              <Col md="auto" xs="auto">
                <span className='sub-title' style={!isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                  Choose a league
                </span>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="auto" xs="auto">
                <div 
                  style={{ 
                    width: isMobile ? '100%' : '800px', 
                    height: isMobile ? '600px' : '400px', 
                    background: 'transparent' 
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRollupSelect();
                  }}
                >
                  <RollupsCanvas 
                    style={{ background: 'transparent' }}  
                    backgroundColor="transparent"
                    tilt={-0.15}
                    disableScroll={false}
                    isMobile={isMobile}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Page>

        <Page offset={1} gradient="teal" onClick={() => parallaxRef.current?.scrollTo(2)}>
            <Container style={{maxWidth: '1000px'}}>
                <Row className="justify-content-center" style={!isMobile ? {paddingTop: "10%", paddingBottom: "1%"} : {paddingTop: "10%", paddingBottom: "3%"}}>
                    <Col md="auto" xs="auto">
                        <span className='sub-title' style={!isMobile ? {fontSize: "28pt"} : {fontSize: "20pt"}}>
                            Grab a team
                        </span>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md="auto" xs="auto" style={{position: 'relative'}}>
                        <div style={{ position: 'relative' }}>
                            <PitchComponent
                                homeFormation={landingFormation}
                                awayFormation={landingFormation}
                                displayTeam="away"
                                isMobile={isMobile}
                                pitchColor="rgba(87, 239, 124, 0.96)"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>

        <Page offset={2} gradient="pink" onClick={() => parallaxRef.current?.scrollTo(0)}>
          <Container style={{maxWidth: '1000px'}}>
            <Row className="justify-content-center" style={!isMobile ? {paddingTop: "10%", paddingBottom: "1%"} : {paddingTop: "10%", paddingBottom: "3%"}}>
              <Col md="auto" xs="auto">
                {!isMobile ? (
                  <span className='sub-title' style={{fontSize: "28pt"}}>
                    Can you beat the champions?
                  </span>
                ) : (
                  <>
                    <span className='sub-title' style={{fontSize: "20pt", display: 'block'}}>
                      Can you beat
                    </span>
                    <span className='sub-title' style={{paddingTop: "10%", fontSize: "20pt", display: 'block'}}>
                      the champions?
                    </span>
                  </>
                )}
              </Col>
            </Row>
            <TeamListLoaded
              teamData={sampleTeam}
              isMobile={isMobile}
            />
            <Row className="justify-content-center">
              <Col md="auto" xs="auto" >
                <SignInButton
                  customText={'Join Now'}
                  isMobile={isMobile}
                  loginButtonFallback={loginButtonFallback}
                  style={{
                    width: isMobile ? '300px' : '600px',
                    height: isMobile ? '80px' : '100px',
                    borderRadius: '10px',
                  }}
                />
              </Col>
            </Row>
          </Container>
        </Page>
      </Parallax>
      
      <RollupModal
        show={showRollupModal}
        onHide={() => setShowRollupModal(false)}
      />
    </div>
  );
};

export default LandingParallax; 