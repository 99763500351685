import { useState, useEffect } from 'react';
import * as api from '../helpers/api.js';
import * as coreWeb3 from '../helpers/coreWeb3';
import INFTContract from '../contracts/INFTContract.json';
// helpers
import * as players from '../helpers/players.js';
import * as utils from '../helpers/utils.js';
import * as pay from '../helpers/stripe.js';
import gameResults from '../data/pastGames.js';

function useAppState() {
  const [state, setState] = useState({
    loading: true,
    isHome: false,
    totalPrizes: 0,
    leaguePrize: 0,
    nextGameInfo: [],
    teamNFTAddressL2: '0x3a2e5d9b86F253b9FFe23dEC88E99F057331e7e5',
    teamNFTAddressL1: '0x4f48311D92B4Da00B1f52E7dCf06A4504854275B',
    CBAddressL2: '0xF4AcaC4ADE4a1f33984EcD97f064927ccAFa03a4',
    CBAddressL1: '0xde2fC11b3222E9754A23A12094Ab70116f0e493E',
    hideNavbar: false,
    hideBanner: false,
    teamCostUsd: 500, // 5 USD
  });

  const [modals, setModals] = useState({
    player: false,
    payment: false,
    // Add other modals as needed
  });

  const actions = {
    handleResize: () => {
      setState((prevState) => ({
        ...prevState,
        isMobile: window.innerWidth < 1000,
      }));
    },

    homepageCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: true,
        isDashboard: false,
        isSchedule: false,
        isFormation: false,
        isTraining: false,
        isExplore: false,
        isReferral: false,
        isMarket: false,
        transitionNavbar: false,
        transitionBanner: false,
      }));
    },

    scheduleCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: true,
        isFormation: false,
        isTraining: false,
        isExplore: false,
        isReferral: false,
        isMarket: false,
        hideNavbar: false,
      }));
    },

    dashboardCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: true,
        isSchedule: false,
        isFormation: false,
        isTraining: false,
        isExplore: false,
        isReferral: false,
        isMarket: false,
      }));
    },

    formationCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: false,
        isFormation: true,
        isTraining: false,
        isExplore: false,
        isReferral: false,
        isMarket: false,
      }));
    },

    trainingCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: false,
        isFormation: false,
        isTraining: true,
        isExplore: false,
        isReferral: false,
        isMarket: false,
      }));
    },

    marketCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: false,
        isFormation: false,
        isTraining: false,
        isExplore: false,
        isReferral: false,
        isMarket: true,
      }));
    },

    exploreCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: false,
        isFormation: false,
        isTraining: false,
        isExplore: true,
        isReferral: false,
        isMarket: false,
      }));
    },

    referralCallback: () => {
      setState((prevState) => ({
        ...prevState,
        isHome: false,
        isDashboard: false,
        isSchedule: false,
        isFormation: false,
        isTraining: false,
        isExplore: false,
        isReferral: true,
        isMarket: false,
      }));
    },

    loginButtonFallback: async (loginData) => {
      let web3 = loginData.web3 !== undefined ? loginData.web3 : state.web3;
      const userData = {
        ...loginData,
        notifications: 0
      };
      
      setState((prevState) => ({
        ...prevState,
        account: loginData.signer,
        ownedSafes: loginData.ownedSafes,
        ownedSafesData: loginData.ownedSafesData,
        loginMethod: loginData.loginMethod,
        web3: web3,
        web3l2: loginData.web3,
        userInfo: userData
      }));

      if (loginData.signer) {
        actions.startNotificationPolling();
      }
    },

    onLeagueSelectFallback: (leagueId) => {
      setState((prevState) => ({
        ...prevState,
        showLeaguePage: true,
        selectedLeaguePage: leagueId,
      }));
    },

    onPlayerSelectFallback: (playerData) => {
      actions.setPlayerModalShow(true);
      console.log(playerData);
      if (playerData.baller_name.startsWith('{')) {
        if (playerData.baller_id.toString().startsWith('8453')) {
          playerData.baller_name = utils.transformToBNotation(playerData.baller_id.toString());
        }
      }
      console.log(playerData);
      actions.setPlayerModalData(playerData);
    },

    setPlayerModalShow: (value) => {
      setModals((prevModals) => ({
        ...prevModals,
        player: value,
      }));
    },

    setPlayerModalData: (value) => {
      setState((prevState) => ({
        ...prevState,
        playerModalData: value,
      }));
    },

    setPaymentModalShow: (value) => {
      setModals((prevModals) => ({
        ...prevModals,
        payment: value,
      }));
    },

    setClientSecret: (value) => {
      setState((prevState) => ({
        ...prevState,
        clientSecret: value,
      }));
    },

    setShareTeamModalShow: (value) => {
      setModals((prevModals) => ({
        ...prevModals,
        shareTeam: value,
      }));
    },

    loadTeamOnL1: async (txReceipt) => {
      await actions.getTeamOnL1();
      await actions.getPlayersL1(txReceipt);
    },

    onRollupSelect: (rollup) => {
      setState((prevState) => ({
        ...prevState,
        selectedRollup: rollup,
      }));
    },

    setTeamName: async (name) => {
      const network = await state.web3.eth.net.getId();
      if (network !== 8453) {
        await coreWeb3.switchToChain(state.web3, "0x2105");
      }
      let CBContract = new state.web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
      await CBContract.methods.setTeamName(state.ownedTeamId, name).send({
        from: state.account,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      }).on('receipt', () => {
        setState((prevState) => ({
          ...prevState,
          ownedTeamName: name,
        }));
      });
    },

    getCanTrain: async (tokenId) => {
      let web3 = state.web3;
      let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
      let canTrain = await CBContract.methods.canTrain(tokenId).call();
      setState((prevState) => ({
        ...prevState,
        canTrain: { ...prevState.canTrain, [tokenId]: canTrain },
      }));
    },

    getNetwork: async () => {
      const network = await state.web3.eth.net.getId();
      let isRequiredNetwork = (network === 1);
      setState((prevState) => ({
        ...prevState,
        network: network,
        isRequiredNetwork: isRequiredNetwork,
      }));
    },

    handlePlayClick: () => {
      setState((prevState) => ({
        ...prevState,
        // transitionNavbar: true,
        // transitionBanner: true,
      }));
    },

    getTeamOnL1: async () => {
      let web3 = state.web3;
      let account = state.account;
      if (web3 !== undefined && account !== undefined) {
        await actions.getOwnedTeamL1();
        setState((prevState) => ({
          ...prevState,
          ownedTeamLeague: Math.max(1, Math.ceil(prevState.ownedTeamId / 8)),
        }));
      }
    },

    getPlayersL1: async (txReceipt) => {
      let events = Object.values(txReceipt.events);
      let playerIds = [];
      for (let i = 0; i < events.length; i++) {
        if (events[i].address.toLowerCase() === state.CBAddressL1.toLowerCase()) {
          if (events[i].raw.topics[0].toLowerCase() === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef".toLowerCase()) {
            playerIds.push(parseInt(events[i].raw.topics[3], 16));
          }
        }
      }
      var ballerData = await api.getBallersByTeam(state.ownedTeamId, 1);
      if (ballerData.length === 0) {
        ballerData = await Promise.all(playerIds.map(nft => api.addBaller(nft, 1)));
      }
      setState((prevState) => ({
        ...prevState,
        ownedPlayers: ballerData,
      }));
    },

    getTeamNextGameId: async () => {
      try {
        let gameId = await api.getTeamNextGameId(state.ownedTeamChainId, state.ownedTeamId);
        console.log("gameId: ", gameId)
        if (gameId !== null) {
          setState((prevState) => ({
            ...prevState,
            nextGameId: gameId,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            nextGameId: undefined,
          }));
        }
      } catch (error) {
        console.log("error getting next game id");
      }
    },

    getFormationForGame: async (side) => {
      let web3 = state.web3l2;
      if (web3 !== undefined) {
        let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
        var nextGameFormationType;
        var nextGameFormation;
        try {
          await CBContract.methods.getGameInfo(state.nextGameId).call().then(
            _info => {
              setState((prevState) => ({
                ...prevState,
                nextGameInfo: _info,
              }));
            }
          );
        } catch (error) {
          console.log("error getting next game formation");
        }

        try {
          await CBContract.methods.getTeamFormationType(state.ownedTeamId, side).call().then(
            _formation => {
              nextGameFormationType = _formation;
            }
          );
        } catch (error) {
          console.log("error getting next game formation");
        }

        try {
          await CBContract.methods.getTeamFormation(state.ownedTeamId, side).call().then(
            _formation => {
              nextGameFormation = _formation;
            }
          );
        } catch (error) {
          console.log("error getting next game formation");
        }
      }
      return [nextGameFormationType, nextGameFormation];
    },

    async fetchBallerData(teamId, chainId) {
      let ballerData = [];
      try {
        ballerData = await api.getBallersByTeam(teamId, chainId);
        if (ballerData.length === 0) {
          console.log("No players in DB for chain: ", chainId);
          try {
            ballerData = await api.getBallersByTeam(teamId, 1);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.log(error);
      }
      return ballerData;
    },

    loadFormationById: async (teamId) => {
      let formationType = [];
      let defaultFormation = [];
      console.log("getting formation for team: ", teamId);
      try {
        const formationData = await api.getTeamFormation(state.ownedTeamChainId, teamId);
        formationType = [formationData.formationType];
        defaultFormation = formationData.defaultFormation.map(id => parseInt(id, 10));
      } catch (error) {
        console.log("Error getting formation:", error);
      }

      let ballerData;
      if (teamId === state.ownedTeamId) {
        ballerData = await actions.fetchBallerData(state.ownedTeamId, state.ownedTeamChainId);
        defaultFormation = defaultFormation.map(id => ballerData.find(baller => baller.baller_id === id)).filter(baller => baller !== undefined);
      } else {
        ballerData = await actions.fetchBallerData(teamId, state.ownedTeamChainId);
        defaultFormation = defaultFormation.map(id => ballerData.find(baller => baller.baller_id === id.toString())).filter(baller => baller !== undefined);
      }
      return [formationType, defaultFormation];
    },

    getGameSide: (info, teamId) => {
      if (info[0] === teamId) {
        return 1;
      } else if (info[1] === teamId) {
        return 2;
      } else {
        return 400;
      }
    },

    getOwnedTeam: async () => {
      let web3 = state.web3;
      var network = await web3.eth.net.getId();
      if (network === 1) {
        return await actions.getOwnedTeamL1();
      }
      let teamNFTContract = new web3.eth.Contract(INFTContract.abi, state.teamNFTAddressL2);
      var ownedTeamId = 0;
      try {
        await teamNFTContract.methods.tokenOfOwnerByIndex(state.account, 0).call().then(
          _indexes => {
            ownedTeamId = _indexes;
            setState((prevState) => ({
              ...prevState,
              ownedTeamId: _indexes,
            }));
          }
        );
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          ownedTeamId: undefined,
        }));
      }
      try {
        let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
        await CBContract.methods.getTeamName(ownedTeamId).call().then(
          _name => {
            if (_name === '') {
              setState((prevState) => ({
                ...prevState,
                ownedTeamName: undefined,
              }));
            } else {
              setState((prevState) => ({
                ...prevState,
                ownedTeamName: _name,
              }));
            }
          }
        );
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          ownedTeamName: undefined,
        }));
      }
      return ownedTeamId;
    },

    getOwnedTeamL1: async () => {
      let web3 = state.web3;
      let teamNFTContract = new web3.eth.Contract(INFTContract.abi, state.teamNFTAddressL1);
      var ownedTeamId = 0;
      try {
        await teamNFTContract.methods.tokenOfOwnerByIndex(state.account, 0).call().then(
          _indexes => {
            ownedTeamId = _indexes;
            setState((prevState) => ({
              ...prevState,
              ownedTeamId: ownedTeamId,
            }));
          }
        );
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          ownedTeamId: undefined,
        }));
      }
      return ownedTeamId;
    },

    getNumberOfLeagues: async () => {
      let numberOfLeagues = await api.getNumberOfLeagues(state.ownedTeamChainId)
      let leaguesArray = Array.from({ length: numberOfLeagues }, (_, i) => i + 1);
      setState((prevState) => ({
        ...prevState,
      numLeagues: leaguesArray,
      }));
      return leaguesArray;
    },

    getPrizes: async (networkId) => {
      if (networkId === "0x2105") {
        let web3 = state.web3;
        if (web3 !== undefined) {
          const CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
          await actions.getNumberOfLeagues();

          await CBContract.methods.getTotalAccrued().call().then(
            undistributed => {
              setState((prevState) => ({
                ...prevState,
                undistributed: undistributed,
              }));
            }
          );
          actions.getEthPrice();
          try {
            await CBContract.methods.getLeagueTotalPrize(state.ownedTeamLeague).call().then(
              prize => {
                let und = state.undistributed / state.numLeagues;
                let leaguePrize = (parseInt(prize) + parseInt(und)) / Math.pow(10, 18) * state.ethPrice;
                setState((prevState) => ({
                  ...prevState,
                  leaguePrize: leaguePrize,
                }));
              }
            );
          } catch (error) {
            console.log(error);
          }

          let prizes = 0;
          for (let i = 1; i <= state.numLeagues; i++) {
            try {
              await CBContract.methods.getLeagueTotalPrize(i).call().then(
                prize => {
                  let und = state.undistributed / state.numLeagues;
                  prizes += (parseInt(prize) + parseInt(und));
                  setState((prevState) => ({
                    ...prevState,
                    totalPrizes: prizes / Math.pow(10, 18) * state.ethPrice,
                  }));
                }
              );
            } catch (error) {
              console.log(error);
            }
          }
        }
      }
    },

    getEthPrice: () => {
      setState((prevState) => ({
        ...prevState,
        ethPrice: 2500,
      }));
    },

    getPlayersData: async () => {
      // console.log("TEAM ID: ", state.ownedTeamId);
      // console.log("fetching players..");
      try {
        var ballerData = await api.getBallersByTeam(state.ownedTeamId, state.ownedTeamChainId);
        if (ballerData.length === 0) {
          console.log("No players in DB for chain: ", state.ownedTeamChainId);
          try{
            var ballerData = await api.getBallersByTeam(state.ownedTeamId, 1);
          } catch (error) {
            console.log(error);
          }
          if (ballerData.length === 0) {
            console.log("No players in DB.");
            try{
              let _nfts = await api.getOwnedPlayers(state.account, 1);
              ballerData = await Promise.all(_nfts.map(nft => api.addBaller(nft, 1)));
            } catch (error) {
              console.log(error);
            }
            if (ballerData.length === 0) {
              console.log("No players on Ethereum either.");
              
              let _nfts = await api.getOwnedPlayers(state.account, state.ownedTeamChainId);
              ballerData = await Promise.all(_nfts.map(nft => api.addBaller(nft, state.ownedTeamChainId)));
            }
          }
        }
      } catch (error) {
        console.log(error);
      }

      if (ballerData.length === 0) {
        console.log("Looking for players on L1.");
        let _nfts = await players.getOwnedPlayers(state.account, state.CBAddressL1, state.web3);
        ballerData = await Promise.all(_nfts.map(nft => api.addBaller(nft, 1)));
      }

      setState((prevState) => ({
        ...prevState,
        ownedPlayers: ballerData,
      }));
      
      return ballerData;
    },

    getLeagueDataDB: async (leagueId) => {
      let leagueData = await api.getLeagueData(leagueId, state.ownedTeamChainId);
      const selectedFields = leagueData.map(obj => [
        obj.rank,
        obj.team_id,
        obj.team_name,
        obj.points,
        obj.goals,
      ]);

      selectedFields.sort((a, b) => a[0] - b[0]);
      // console.log("leagueData: ", leagueData);
      setState((prevState) => ({
        ...prevState,
        teamsData: selectedFields,
      }));
      return selectedFields;
    },

    getLeagueDataStateless: async (leagueId) => {
      console.log("leagueId: ", leagueId);
      let leagueData = await api.getLeagueData(leagueId, state.ownedTeamChainId);
      const selectedFields = leagueData.map(obj => [
        obj.rank,
        obj.team_id,
        obj.team_name,
        obj.points,
        obj.goals,
      ]);

      selectedFields.sort((a, b) => a[0] - b[0]);
      return selectedFields;
    },

    getValue: (id, arr) => {
      return parseInt((arr.find(pair => pair[0] === id.toString()) || [])[1], 10);
    },

    timerSetter: () => {
      setState((prevState) => ({
        ...prevState,
        startTimer: true,
      }));
    },

    getTeamNameById: async (id) => {
      let name = await api.getTeamNameById(state.ownedTeamChainId, id);
      return name;
    },

    getLeagueFullData: async () => {
      console.log("getLeagueFullData: ", state.ownedTeamLeague);
      let web3 = state.web3;
      var leagueFullData = [];
      if (web3 !== undefined) {
        const CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
        await CBContract.methods.getLeagueData(state.ownedTeamLeague).call().then(
          leagueFullData_ => {
            leagueFullData = leagueFullData_;
          }
        );
      }
      return leagueFullData;
    },

    getLeagueFullDataDB: async (leagueId) => {
      let leagueFullData = await api.getLeagueFullData(leagueId, state.ownedTeamChainId);
      return leagueFullData;
    },

    getPastResults: async (pastGames) => {
      let gr = [];
      for (let i = 0; i < pastGames.length; i++) {
        gr.push(gameResults[pastGames[i][3]]);
      }
      return gr;
    },

    handlePlayGame: async (gameId) => {
      // try {
      //   let gameResult = await api.playGame(gameId, state.account);
      //   return gameResult;
      // } catch (error) {
        let web3 = state.web3;
        await coreWeb3.switchToChain(state.web3, state.ownedTeamChainId);
        let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
        var gameResult = [];
        const receipt = await new Promise((resolve, reject) => {
          CBContract.methods.startGame(gameId).send({
            from: state.account,
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          }).on('receipt', resolve).on('error', reject);
        });

        let _info = await CBContract.methods.getGameInfo(gameId).call();
        gameResult = _info;
        return gameResult;
      // }
    },

    getPastGameResult: async (gameTime, gameId) => {
      let pastGameResult = gameResults[gameId];
      if (pastGameResult === undefined) {
        const currentBlockNumber = await state.web3.eth.getBlockNumber();
        let gameBlock = await utils.findBlockByTimestamp(6790000, currentBlockNumber, gameTime, state.web3);
        let topics = ["0x52da0466f8325d3f6fb48dd00c1b2a69f03effc8681ef5ba6d43a02f45fbd151", utils.formatToHex(gameId)];
        let events = await utils.searchEvents(gameBlock, state.CBAddressL2, topics, state.web3);
        let gameResultArray = utils.hexStringToNumbers(events[0].data);
        pastGameResult = gameResultArray.join(' - ');
      }
      return pastGameResult;
    },

    createSponsoredTeam: async (sponsoredAddress) => {
      let web3 = state.web3;
      let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
      await CBContract.methods.createSponsoredTeam(sponsoredAddress).send({
        from: state.account,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      }).on('receipt', (txHash) => {
        console.log(txHash);
      });
    },

    handlePayment: async () => {
      const secret = await pay.createStripePaymentIntent(state.teamCostUsd, "usd");
      actions.setClientSecret(secret);
      actions.setPaymentModalShow(true);
    },

    onPaymentSuccess: async () => {
      console.log("Payment successful!");
      // sign onBehalfOf transaction
      let signedMessage = await actions.createTeamCouponCode();
      console.log("signedMessage: ", signedMessage);
      await api.createTeamFromCoupon(state.account, signedMessage, state.selectedRollup);
      // load account data
      await actions.loadAccountData();
      actions.setPaymentModalShow(false);
      actions.setClientSecret(null);
    },

    getNextDivisionTeams: async (teamId) => {
      let chainId = 8453;
      let teams = await api.getNextDivisionTeams(chainId);
      console.log("Next division teams: ", teams);
      setState((prevState) => ({
        ...prevState,
        nextDivisionTeams: teams,
        nextDivisionTeamsSelected: teams,
      }));
    },

    createDivision: async () => {
      let result = await api.createDivision(8453, state.nextDivisionTeamsSelected);
      console.log(result);
    },

    generateInvite: () => {
      actions.setShareTeamModalShow(true);
    },

    handleSelectedTeamsChange: (newSelectedTeams) => {
      setState((prevState) => ({
        ...prevState,
        nextDivisionTeamsSelected: newSelectedTeams,
      }));
    },

    loadAccountData: async () => {
      const promises = [];
      await actions.getTeamData();

      promises.push(actions.getPrizes());
      promises.push(actions.getBalance(state.account));
      // promises.push(actions.getTeamNextGameId());
      // promises.push(actions.getFormationForGame());
      

      if (state.isDashboard) {
        // Uncomment and implement if needed
        // promises.push(actions.getLeagueData());
      }

      await Promise.all(promises);
    },

    getTeamData: async () => {
      let web3 = state.web3;
      let account = state.account;
      let teamData;
      console.log("Getting team data...", account);
      if (web3 !== undefined && account !== undefined) {
        try {
          teamData = await api.getTeamByOwner(account);
          setState((prevState) => ({
            ...prevState,
            ownedTeamId: teamData.team_id,
            ownedTeamLeague: teamData.league_id,
            ownedTeamChainId: teamData.chain_id,
            ownedTeamName: teamData.team_name,
          }));
        } catch (error) {
          console.error("Failed to get team data:", error);
        }
      }
      return teamData;
    },

    getBalance: async (account) => {
      let web3 = state.web3;
      if (web3 !== undefined) {
        try {
          const balance = web3.utils.fromWei(
            await web3.eth.getBalance(account) // Balance is in wei
          );
          setState((prevState) => ({
            ...prevState,
            balance: balance,
          }));
        } catch (error) {
          console.error("Failed to get balance:", error);
        }
      }
    },

    async getAvailableGames(divisionId) {
      let availableGames = await api.getAvailableGames(divisionId, 8453);
      availableGames = availableGames.filter(game => game !== null);
      setState((prevState) => ({
        ...prevState,
        availableGames: availableGames,
      }));
      return availableGames;
    },

    startTrainingCamp: async (tokenIds) => {
      let web3 = state.web3;
      const network = await web3.eth.net.getId();
      // console.log("network: ", network);
      // console.log("state.ownedTeamChainId: ", state.ownedTeamChainId);
      if (network !== state.ownedTeamChainId) {
        await coreWeb3.switchToChain(web3, state.ownedTeamChainId);
      }
      
      let CBContract = new web3.eth.Contract(INFTContract.abi, state.CBAddressL2);
      // console.log("startTrainingCamp", tokenIds)
      const receipt = await CBContract.methods.startTrainingCamp(tokenIds).send({
        from: state.account,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      });
      
      return receipt; // Return the receipt to be used by the caller
    },

    onPlayGameClick: () => {
      setState((prevState) => ({
        ...prevState,
        hideNavbar: true,
      }));
    },

    createTeamCouponCode: async () => {
      let web3 = state.web3;
      let account = state.account;

      let signedMessage = await actions.signTypedData(web3, account);
      setState((prevState) => ({
        ...prevState,
        signedMessage: signedMessage
      }));

      if (signedMessage == undefined) {
        throw new Error("No signed message");
      }

      await api.sendCouponCode(account, signedMessage);
      // check if coupon code is valid
      // let couponStatus = await api.isCouponSponsored(account, signedMessage);
      // Disabled for mainnet
      // if (couponStatus){
      //   await api.createTeamFromCoupon(account,signedMessage)
      // }
      return signedMessage;
    },

    signTypedData: async (web3, fromAddress) => {
      // Defining the message signing data content.
      const message = {
        to: fromAddress,
        players: 11,
        deadline: 1750009500, // 15th June 2025
      };

      const typedData = JSON.stringify({
        types: {
          EIP712Domain: [
            { name: "chainId", type: "uint256" },
            { name: "verifyingContract", type: "address" },
          ],
          CreateTx: [
            { name: "to", type: "address" },
            { name: "players", type: "uint256" },
            { name: "deadline", type: "uint256" }
          ]
        },
        primaryType: "CreateTx",
        domain: {
          chainId: 1, //8453,
          verifyingContract: state.CBAddressL2
        },
        message: message,
      });

      const params = [fromAddress, typedData];
      const method = "eth_signTypedData_v4";

      var signedMessage;
      // if (!utils.isWeb3Wallet(web3)) {
        signedMessage = await actions.sendDirectSigRequest(web3, method, params, fromAddress);
      // } else {
      //   signedMessage = await actions.sendAsyncSigRequest(web3, method, params, fromAddress)
      //     .then((signedMessage) => {
      //       console.log('TYPED SIGNED: ' + JSON.stringify(signedMessage));
      //       return JSON.stringify(signedMessage).slice(1, -1);
      //     })
      //     .catch((error) => {
      //       console.error('ERROR', error);
      //     });
      // }
      return signedMessage;
    },

    sendAsyncSigRequest: (web3, method, params, fromAddress) => {
      return new Promise((resolve, reject) => {
        web3.currentProvider.sendAsync(
          {
            method,
            params,
            from: web3.utils.toChecksumAddress(fromAddress),
          },
          function (err, result) {
            if (err) {
              reject(err);
            } else if (result.error) {
              reject(new Error(result.error.message));
            } else {
              resolve(result.result);
            }
          }
        );
      });
    },

    sendDirectSigRequest: async (web3, method, params, fromAddress) => {
      console.log(params, fromAddress);
      const signedMessage = await web3.provider.sendAsync(
        {
          from: fromAddress,
          method,
          params,
        }
      );
      return signedMessage;
    },

    startNotificationPolling: () => {
      const pollInterval = setInterval(() => {
        const newNotifications = Math.floor(Math.random() * 6);
        setState(prevState => ({
          ...prevState,
          userInfo: {
            ...prevState.userInfo,
            notifications: newNotifications
          }
        }));
      }, 30000);

      setState(prevState => ({
        ...prevState,
        notificationInterval: pollInterval
      }));
    },

    handleLogout: async () => {
      if (state.notificationInterval) {
        clearInterval(state.notificationInterval);
      }
      // ... existing logout logic ...
    }
  };

  //** useEffect hooks for handling side effects */

  useEffect(() => {
    actions.handleResize(); // Call handleResize on initial mount
    window.addEventListener('resize', actions.handleResize);
    return () => {
      window.removeEventListener('resize', actions.handleResize);
    };
  }, []);

  useEffect(() => {
    if (state.account) {
      actions.loadAccountData();
    }
  }, [state.account]);

  useEffect(() => {
    if (state.ownedTeamId > 0) {
      const promises = [];
      try {
        promises.push(api.addTeam(state.account, state.ownedTeamChainId));
        promises.push(api.updateTeam(state.account, state.ownedTeamChainId));
        promises.push(actions.getPlayersData());
      } catch (error) {
        console.error(error);
      }
      Promise.all(promises);
    }
  }, [state.ownedTeamId]);

  return { state, actions, modals };
}

export default useAppState;