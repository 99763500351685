import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import LeagueOption from '../leagueOptions/LeagueOption';
import './LeagueSelection.css';

function LeagueSelection({ createTeam, onLeagueSelect, isMobile, selectedRollup }) {
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [leagues, setLeagues] = useState([
    {
      name: 'base',
      component: LeagueOption,
      data: { name: 'base', averageTeamCost: 15, numberOfTeams: 11, totalValue: 1000 },
      chainId: 8453,
    },
    {
      name: 'optimism',
      component: LeagueOption,
      data: { name: 'optimistic', averageTeamCost: 15, numberOfTeams: 11, totalValue: 1000 },
      chainId: 10,
    },
    {
      name: 'arbitrum',
      component: LeagueOption,
      data: { name: 'arbitrum', averageTeamCost: 15, numberOfTeams: 11, totalValue: 1000 },
      chainId: 42161,
    },
  ]);
  const containerRef = useRef(null);

  // Filter leagues based on selectedRollup
  const filteredLeagues = leagues.filter(league => {
    if (!selectedRollup) return false;
    return league.name === selectedRollup;
  });

  const handleLeagueSelect = (league) => {
    if (selectedLeague === league) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    } else {
      setSelectedLeague(league);
      onLeagueSelect(league);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // console.log("leagues: ", leagues);
  return (
    <Container ref={containerRef}>
      <Row 
        className="justify-content-center align-items-center" 
        style={{
          minHeight: isMobile ? 'auto' : '70vh',
          paddingRight: isMobile ? "0%" : "15%",
        }}
      >
        <Col md={4}>
          <div 
            className="sub-title" 
            style={{
              fontSize: isMobile ? "20pt" : "26pt",
              textAlign: isMobile ? "center" : "left"
            }}
          >
            Select your league
          </div>
        </Col>
        
        {isMobile ? (
          <Col md={8}>
            {filteredLeagues.map(({ name, component: LeagueComponent, data, chainId }) => (
              <div 
                key={name}
                onClick={() => handleLeagueSelect(name)} 
                style={{ cursor: 'pointer', paddingTop: name !== 'base' ? '5%' : '0%' }}
              >
                <LeagueComponent 
                  createTeam={createTeam}
                  onLeagueSelect={() => handleLeagueSelect(name)}
                  isSelected={selectedLeague === name}
                  data={data}
                  isMobile={isMobile}
                  chainId={chainId}
                />
              </div>
            ))}
          </Col>
        ) : (
          <Col md={8}>
            <Row>
              {filteredLeagues.map(({ name, component: LeagueComponent, data, chainId }) => (
                <Col 
                  key={name}
                  md={6}
                  onClick={() => handleLeagueSelect(name)} 
                  style={{ cursor: 'pointer' }}
                >
                  <LeagueComponent 
                    createTeam={createTeam}
                    onLeagueSelect={() => handleLeagueSelect(name)}
                    isSelected={selectedLeague === name}
                    data={data}
                    isMobile={isMobile}
                    chainId={chainId}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default LeagueSelection;
