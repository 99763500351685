import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Spinner} from 'react-bootstrap';
import GameViewTabs from './GameViewTabs';
import * as gameActions from '../../helpers/gameplay/actions';
import { transformToBNotation } from '../../helpers/utils';

import pitchImage from '../../static/pitch1.jpg';

function GameViewEngine({ 
    selectedGame,
    gameResult,
    gameSummary,
    homeFormation,
    awayFormation,
    homeFormationArray,
    awayFormationArray,
    teamsData,
    getLeagueData,
    redIds = Array.from({ length: 11 }, (_, i) => i + 1), // Default IDs for red
    blueIds = Array.from({ length: 11 }, (_, i) => i + 12), // Default IDs for blue
    updateGameStatus,
    gameStarted 
}) {
    // Team color variables
    const HOME_TEAM_COLOR = 'deeppink';
    const AWAY_TEAM_COLOR = 'slateBlue';

    const getGroupNumber = (index, color) => {
        const groupSizes = color === 'red' ? homeFormationArray : awayFormationArray;
        let count = 1; // Start counting from the second circle
        for (let i = 0; i < groupSizes.length; i++) {
            count += groupSizes[i];
            if (index < count) return i + 1;
        }
        return null; // For indices outside the group sizes
    };
    // Adjust initial positions with groups and include IDs

    const initialPositions = Array(22).fill().map((_, index) => {
        const color = index < 11 ? HOME_TEAM_COLOR : AWAY_TEAM_COLOR;
        const groupNumber = getGroupNumber(index % 11, color); // Calculate group number for each circle
        const id = color === HOME_TEAM_COLOR ? redIds[index % 11] : blueIds[index % 11];
    
        let left, top;
        if (index === 0 || index === 11) {
            // Static circles
            left = color === HOME_TEAM_COLOR ? 0 : 96;
            top = 48;
        } else {
            // Determine left position based on group number
            if (color === HOME_TEAM_COLOR) {
                left = groupNumber === 1 ? 15 : (groupNumber === 2 ? 30 : 45);
            } else {
                left = groupNumber === 1 ? 85 : (groupNumber === 2 ? 70 : 55);
            }
    
            // Determine the top position based on group size and index within the group
            const groupSizes = color === 'red' ? homeFormationArray : awayFormationArray;
            const groupStartIndex = groupSizes.slice(0, groupNumber - 1).reduce((a, b) => a + b, 0);
            const positionInGroup = (index % 11) - groupStartIndex;
    
            switch (groupSizes[groupNumber - 1]) {
                case 1:
                    top = 49.5;
                    break;
                case 2:
                    top = positionInGroup * 20 + 20;
                    break;
                case 3:
                    top = positionInGroup * 20 + 10;
                    break;
                case 4:
                    top = positionInGroup * 20 + -2;
                    break;
                case 5:
                    top = positionInGroup * 16.5 + 0;
                    break;
                default:
                    top = 0;
                    break;
            }
        }
    
        return {
            color,
            left,
            top,
            groupNumber: groupNumber || 0,
            id
        };
    });

    
    const [circles, setCircles] = useState(initialPositions);
    const [ball, setBallPosition] = useState({ left: 15, top: 49.5 });
    const [precalculatedSteps, setPrecalculatedSteps] = useState([]);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [isStadiumPlaying, setIsStadiumPlaying] = useState(false);
    const [isGoalAudio, setIsGoalAudio] = useState(false);
    const [gameStatus, setGameStatus] = useState({score: [0,0], gameTime: 0});
    const [clickedTab, setClickedTab] = useState("info");
    const [isUpdatingSteps, setIsUpdatingSteps] = useState(false);
    const [gameActionsArray, setGameActions] = useState([]);
    const [isGoalAction, setIsGoalAction] = useState([false, 'none']);

    // Modify box dimensions to be responsive
    const isMobile = window.innerWidth <= 768;
    
    const boxStyle = {
        width: isMobile ? '420px' : '600px',  // Half size for mobile
        height: isMobile ? '175px' : '350px',  // Half size for mobile
        border: '1px solid ',
        position: 'relative',
        backgroundColor: 'lightgreen',
        boxShadow: '0 0 0 1px black',
        zIndex: 2
    };

    const circleStyle = (color, left, top, groupNumber, id) => ({
        width: isMobile ? '10px' : '20px',     // Half size for mobile
        height: isMobile ? '10px' : '20px',    // Half size for mobile
        backgroundColor: color,
        borderRadius: '50%',
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: isMobile ? '8px' : '10px',   // Smaller font for mobile
        transition: 'top 0.5s ease-out, left 0.5s ease-out'
    });

    const ballStyle = (color, left, top) => ({
        width: isMobile ? '5px' : '10px',      // Half size for mobile
        height: isMobile ? '5px' : '10px',     // Half size for mobile
        backgroundColor: color,
        borderRadius: '50%',
        position: 'absolute',
        left: `${left}%`,
        top: `${top}%`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        transition: 'top 0.5s ease-out, left 0.5s ease-out'
    });

    useEffect(() => {
        if (currentStepIndex < precalculatedSteps.length) {
            setIsUpdatingSteps(true);
            if (currentStepIndex === precalculatedSteps.length - 20) {
                setIsGoalAudio(true); // Trigger the goal audio on the last step
            }
            setCircles(precalculatedSteps[currentStepIndex].circles);
            setBallPosition(precalculatedSteps[currentStepIndex].ball);
            const timeoutId = setTimeout(() => {
                setCurrentStepIndex(currentStepIndex + 1);
                setIsUpdatingSteps(false);
            }, 100); // Delay between steps
            // console.log(circles)
            return () => clearTimeout(timeoutId); // Cleanup timeout
        }
        if (isGoalAction[0]){
            if (isGoalAction[0]){
                if (isGoalAction[1] === 'home'){
                    setGameStatus(prevStatus => ({...prevStatus, score: [prevStatus.score[0] + 1, prevStatus.score[1]]}))
                }else{
                    setGameStatus(prevStatus => ({...prevStatus, score: [prevStatus.score[0], prevStatus.score[1] + 1]}))
                }
            }
            setIsGoalAction([false, 'none']);
            setIsGoalAudio(false);
            console.log("Goal")
        }
        if (gameStatus.gameTime !== 0){
            setGameStatus(prevStatus => ({...prevStatus, gameTime: prevStatus.gameTime + 1}));
        }
    }, [currentStepIndex, precalculatedSteps]);
    
    useEffect(() => {
        let timer;
        if (gameStarted && gameStatus.gameTime < 90 && !isUpdatingSteps) {
            if (gameActionsArray.times && gameActionsArray.times.includes(gameStatus.gameTime)) {
                const index = gameActionsArray.times.indexOf(gameStatus.gameTime);
                precalculateSteps(index);
            } else {
                timer = setInterval(() => {
                    setGameStatus(prevStatus => ({...prevStatus, gameTime: prevStatus.gameTime + 1}));
                }, 1000); // Update every second
            }
        }
    
        return () => clearInterval(timer); // Clean up on unmount
    }, [gameStarted, gameStatus, isUpdatingSteps, gameActionsArray]);

    useEffect(() => {
        updateGameStatus(gameStatus); // Pass gameStatus to parent component whenever it changes
    }, [gameStatus, updateGameStatus]);

    useEffect(() => {
        if (gameStarted) {
            let gameActionsArray_ = [];
            // Generate a action game time between 0 and 90
            const actionGameTimes_ = Array.from({ length: 10 }, () => Math.floor(Math.random() * 91)).sort((a, b) => a - b);
            // console.log(actionGameTimes_)
            gameActionsArray_.times = actionGameTimes_;
            const [gameActions_, goalActions_ ]= gameActions.generateGameActions(gameResult);
            gameActionsArray_.goalActions = goalActions_;
            gameActionsArray_.actions = gameActions_;
            console.log(gameActionsArray_)

            setGameActions(gameActionsArray_)
            setIsStadiumPlaying(true);
        }
    }, [gameStarted]);

    function precalculateSteps(index){
        console.log(index, gameActionsArray);
        const selectedGameAction = gameActionsArray.actions[index];
        if (gameActionsArray.goalActions[index][0]){
            setIsGoalAction(gameActionsArray.goalActions[index]);
        }
        const steps = selectedGameAction(initialPositions); 
        setPrecalculatedSteps(steps);
        setCurrentStepIndex(0);
    };

    // Update position for different groups
    const generateNewPosition = () => {
        return circles.map((circle, index) => {
            if (index === 0 || index === 11 || circle.groupNumber === 0) return circle; // Skip static circles and group 0

            let maxLeft; let minLeft;
            if (circle.color === HOME_TEAM_COLOR) {
                maxLeft = circle.groupNumber === 1 ? 45 : (circle.groupNumber === 2 ? 65 : 95);
                minLeft = circle.groupNumber === 3 ? 50 : 0;
            } else {
                maxLeft = circle.groupNumber === 1 ? 85 : (circle.groupNumber === 2 ? 65 : 95);
                minLeft = circle.groupNumber === 3 ? 50 : 0;
            }
            
            // Calculate the movement range as 2% of the current left position
            const movementRangeLeft = circle.left * 0.02;

            // Randomly adjust the left position within the 2% range, ensuring it stays within the min and max boundaries
            let newLeft = circle.left + (Math.random() * 2 - 1) * movementRangeLeft;
            newLeft = Math.max(minLeft, Math.min(newLeft, maxLeft)); // Constrain newLeft within minLeft and maxLeft
            
            let maxTop = 100;
            // Randomly adjust the top position within the 2% range of the current top position
            const movementRangeTop = circle.top * 0.02;
            let newTop = circle.top + (Math.random() * 2 - 1) * movementRangeTop;
            newTop = Math.min(newTop, maxTop);

            return {
                ...circle,
                left: newLeft,
                top: newTop
            };
        });
    };

    // console.log(selectedGame)
    return (
        <div style={isMobile ? 
            {padding: '5px', backgroundColor: "transparent"} : 
            {backgroundColor: "transparent"}
        }>
            <Container style={{
                width: isMobile ? '420px' : '800px',
                maxWidth: isMobile ? '320px' : '900px',
                minHeight: "80px",
                borderRadius: "15px"
            }}>
                <Row className="justify-content-center" style={{height: isMobile ? "300px" : "400px"}}>
                    {/* <Col md="auto" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                        <Row className="justify-content-center align-items-center" style={{height: "50px"}}>
                            4-4-2
                        </Row>
                        {homeFormation[1].map((baller, index) => (
                            <Row key={index} className="justify-content-center align-items-center">
                                <Col>{baller.baller_id}</Col>
                                <Col>{baller.baller_name}</Col>
                            </Row>
                        ))}
                    </Col> */}
                    <Col md="auto">
                        {selectedGame !== undefined && (
                            <Row className="justify-content-center">
                                <Col md="auto" style={{
                                    width: isMobile ? "400px" : "600px",
                                    border: '2px solid #ffffff'
                                }}>
                                    <Row className="justify-content-center align-items-center" style={{paddingTop: '2%'}}>
                                        <Col xs={5} md={5}>
                                            <div className="d-flex justify-content-end sub-title-mobile" >
                                                {selectedGame.teamNameHome || (selectedGame.home_team_id.toString().startsWith('8453') ? transformToBNotation(selectedGame.home_team_id.toString()) : selectedGame.home_team_id)}
                                            </div>
                                        </Col>
                                        <Col xs={2} md={2}>
                                            <div className="d-flex justify-content-center sub-title-mobile" style={{ fontWeight:'bold', letterSpacing: '1px', color: '#C1121F'}}>
                                                {!isUpdatingSteps && gameStatus.gameTime == 90 ? 'Full Time' : `${gameStatus.gameTime}'` }
                                            </div>
                                        </Col>
                                        <Col xs={5} md={5}>
                                            <div className="d-flex justify-content-start sub-title-mobile"  >
                                                {selectedGame.teamNameAway || (selectedGame.away_team_id.toString().startsWith('8453') ? transformToBNotation(selectedGame.away_team_id.toString()) : selectedGame.away_team_id)}
                                            </div>
                                        </Col> 
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row className="justify-content-center" style={{ paddingBottom: '0%', paddingLeft: '0%', paddingTop: '0%' }}>
                            <div className="box-shadow-simple-2" style={{
                                ...boxStyle,
                                backgroundImage: `url(${pitchImage})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: '1rem',
                                filter: 'brightness(100%)'
                            }}>
                                <Row className="justify-content-center" style={{paddingTop: '0%'}}>
                                    <div className="d-flex justify-content-center sub-title-mobile" style={{fontSize: "2.5rem"}}>
                                        {`${gameStatus.score[0]} - ${gameStatus.score[1]}`}
                                    </div>
                                </Row>
                                {/* <PitchLines/>  */}
                                {circles.map((circle, index) => (
                                    <div key={index} style={circleStyle(circle.color, circle.left, circle.top, circle.groupNumber, circle.id)}>
                                        {circle.id}
                                    </div>
                                ))}
                                <div style={ballStyle('white', ball.left, ball.top)}/>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {/* <Row className="justify-content-center" style={{display: "flex", justifyContent: "center"}}>
                    <Col md="auto" style={{backgroundColor: 'rgb(250, 255, 249)', borderTopLeftRadius: '10px'}}>
                        <button className={`button-raw ${clickedTab === "info" ? 'active' : ''}`}  
                            onClick={() => setClickedTab("info")}
                        >
                            <div className="d-flex justify-content-center" style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
                            Info
                            </div>
                        </button>
                    </Col>
                    <Col md="auto" style={{backgroundColor: 'rgb(250, 255, 249)' }}>
                        <button className={`button-raw ${clickedTab === "summary" ? 'active' : ''}`}  
                            onClick={() => setClickedTab("summary")}
                        >
                            <div className="d-flex justify-content-center" style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
                            Summary
                            </div>
                        </button>
                    </Col>
                    <Col md="auto" style={{backgroundColor: 'rgb(250, 255, 249)' }}>
                        <button className={`button-raw ${clickedTab === "lineups" ? 'active' : ''}`}  
                            onClick={() => setClickedTab("lineups")}
                        >
                            <div className="d-flex justify-content-center" style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
                            Line-ups
                            </div>
                        </button>
                    </Col>
                    <Col md="auto" style={{backgroundColor: 'rgb(250, 255, 249)', borderTopRightRadius: '10px'}}>
                        <button className={`button-raw ${clickedTab === "table" ? 'active' : ''}`}  
                            onClick={() => setClickedTab("table")}
                        >
                            <div className="d-flex justify-content-center" style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
                            Table
                            </div>
                        </button>
                    </Col>
                </Row>
                <div className="box-shadow-simple-2" style={{backgroundColor: 'rgb(250, 255, 249)', borderRadius: "20px"}}>
                    <GameViewTabs
                        selectedGame={selectedGame}
                        selectedTab={clickedTab}
                        homeFormation={homeFormation}
                        awayFormation={awayFormation}
                        gameResult={gameResult}
                        gameSummary={gameSummary}
                        gameStatus={gameStatus}
                        teamsData={teamsData}
                        getLeagueData={getLeagueData}
                    />
                </div> */}
                    {/* <AudioPlayer source="audio/stadium.m4a" shouldPlay={isStadiumPlaying} />
                    <AudioPlayer source="audio/score.m4a" shouldPlay={isGoalAudio} />  */}
            </Container>
               
        </div>
    );
};

export default GameViewEngine;
