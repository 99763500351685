// https://cydstumpel.nl/

import * as THREE from 'three'
import { useRef, useState, useTransition, useEffect, useCallback, Suspense } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Image, Environment, ScrollControls, useScroll, useTexture, useMatcapTexture } from '@react-three/drei'
import { easing } from 'maath'
import './util'
import inboxes from '../../../data/inboxes.json'


export const App = ({ onRollupSelect, tilt = 0.15, isMobile }) => (
  <Suspense fallback={<div></div>}>
    <Canvas camera={{ position: [50, 0, 100], fov: 15 }} style={{ background: 'transparent' }}>
      <ScrollControls pages={4} infinite damping={0.3} horizontal={false} enabled={true}>
        <Rig rotation={[50, 0, tilt]} isMobile={isMobile}>
          <Carousel onRollupSelect={onRollupSelect} isMobile={isMobile} />
        </Rig>
      </ScrollControls>
    </Canvas>
  </Suspense>
)

function Rig({ rotation, isMobile, ...props }) {
  const ref = useRef()
  const scroll = useScroll()
  
  useFrame((state, delta) => {
    // Get scroll offset
    const scrollOffset = scroll.offset

    if (isMobile) {
      // On mobile, directly map scroll position to rotation
      ref.current.rotation.x = -scrollOffset * Math.PI * 2
    } else {
      // On desktop, maintain the smooth rotation animation
      ref.current.rotation.y += delta * 0.5
    }
    
    // Apply the tilt rotation to z-axis
    ref.current.rotation.z = rotation[2]
    
    // Camera movement
    state.events.update()
    easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta)
    state.camera.lookAt(0, 0, 0)
  })
  
  return <group ref={ref} {...props} />
}

export function Carousel({ radius = 2.5, position = [0, 0, 0], onRollupSelect, isMobile }) {
  const rollups = Object.keys(inboxes.ethereum)
  const count = rollups.length
  const [currentRollup, setCurrentRollup] = useState(null)
  const mobileRadius = radius * 0.45 // Increased radius for mobile

  const handleRollupSelect = (rollup) => {
    setCurrentRollup(rollup)
    onRollupSelect?.(rollup)
  }

  return (
    <group position={position}>
      {rollups.map((rollup, i) => (
        <Card
          key={i}
          rollup={rollup}
          url={`/icons/${rollup}.png`}
          isHighlighted={currentRollup === rollup}
          onSelect={handleRollupSelect}
          position={isMobile ? [
            0,
            Math.sin((i / count) * Math.PI * 2) * mobileRadius,
            Math.cos((i / count) * Math.PI * 2) * mobileRadius
          ] : [
            Math.sin((i / count) * Math.PI * 2) * radius,
            0,
            Math.cos((i / count) * Math.PI * 2) * radius
          ]}
          rotation={isMobile ? [
            -(i / count) * Math.PI * 2,
            0,
            0
          ] : [
            0,
            Math.PI + (i / count) * Math.PI * 2,
            0
          ]}
          isMobile={isMobile}
        />
      ))}
    </group>
  )
}

function Card({ url, isHighlighted, rollup, onSelect, isMobile, ...props }) {
  const ref = useRef()
  const [hovered, setHovered] = useState(false)
  const [isPending, startTransition] = useTransition()
  
  const pointerOver = useCallback((e) => {
    e.stopPropagation()
    startTransition(() => {
      setHovered(true)
      onSelect(rollup)
    })
    document.body.style.cursor = 'pointer'
  }, [rollup, onSelect])
  
  const pointerOut = useCallback(() => {
    startTransition(() => setHovered(false))
    document.body.style.cursor = 'auto'
  }, [])

  const handleClick = useCallback((e) => {
    e.stopPropagation()
    startTransition(() => onSelect(rollup))
  }, [rollup, onSelect])

  // Adjust scale values for mobile - making them significantly smaller
  const baseScale = isMobile ? 0.25 : 0.6
  const highlightedScale = isMobile ? 0.35 : 0.8
  const hoveredScale = isMobile ? 0.45 : 1.15

  // Use useCallback to memoize the animation frame callback
  const animate = useCallback((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? hoveredScale : isHighlighted ? highlightedScale : baseScale, 0.1, delta)
    easing.damp(ref.current.material, 'radius', hovered ? 0.25 : isHighlighted ? 0.2 : 0, 0.2, delta)
    easing.damp(ref.current.material, 'zoom', hovered ? 1 : isHighlighted ? 1.1 : 1, 0.2, delta)
  }, [hovered, isHighlighted, isMobile])

  useFrame(animate)

  return (
    <Image 
      ref={ref} 
      url={url} 
      transparent 
      side={THREE.DoubleSide}
      onPointerOver={pointerOver} 
      onPointerOut={pointerOut}
      onClick={handleClick}  // Add click handler
      {...props}
    >
      <bentPlaneGeometry args={[0.1, 1, 1, 20, 20]} />
    </Image>
  )
}

function Banner(props) {
  const ref = useRef()
  const [isPending, startTransition] = useTransition()
  const [texture, setTexture] = useState(null)
  const scroll = useScroll()
  

  useFrame((state, delta) => {
    if (texture) {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping
      ref.current.material.time.value += Math.abs(scroll.delta) * 4
      ref.current.material.map.offset.x += delta / 2
    }
  })

  return (
    <mesh ref={ref} {...props}>
      <cylinderGeometry args={[1.6, 1.6, 0.14, 128, 16, true]} />
      {texture && (
        <meshSineMaterial 
          map={texture} 
          map-anisotropy={16} 
          map-repeat={[30, 1]} 
          side={THREE.DoubleSide} 
          toneMapped={false} 
        />
      )}
    </mesh>
  )
}

