// CB
import INFTContract from '../contracts/INFTContract.json'

export function loadCardDataFull(item) {
  // console.log("Loading cards data..", item)
  // Load the data for the card here...
  let uri = item.baller_uri;
  let url;
  // // get base64 part of uri
  let base64Only = uri.slice(29, -1)
  // // convert Base64 to svg 

  try {
    // Code that may throw an error
    let decodedBase64 = atob(base64Only)
    
    let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
    svgText = addArmsSvg(svgText)
    //convert svg to image
    let blob = new Blob([svgText], {type: 'image/svg+xml'});
    // console.log(blob);
    url = URL.createObjectURL(blob);
    // console.log(url);
  } catch (error) {
    // try removing trailing 0
    try{
      let decodedBase64 = atob(base64Only.slice(0,base64Only.length-1))
      let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
      //convert svg to image
      let blob = new Blob([svgText], {type: 'image/svg+xml'});
      // console.log(blob);
      url = URL.createObjectURL(blob);
      // console.log(url);
    } catch(error){
            // Handle the error
      // console.log(base64Only)
      // console.error(error.message);
    }
  }


  let skillsName = ["Stamina","Playmaking","Scoring","Winger", "Goalkeeping", "Passing", "Defending","SetPieces", "Learning Rate"];
  let skillsValue = [item.stamina,item.playmaking,item.scoring,item.winger,item.goalkeeping,item.passing,item.defending,item.set_pieces,item.learning_rate];

  // console.log(skillsValue)

  const title = "Baller #"+ item.baller_id;
  const body = "Attributes";
  let isOnEthereum = item.isoneth;
  let imgSrc = url;
  let tokenId = item.baller_id;
  let cardFunction = cardSelectFunc;
  let cardAttrFunction = cardAttrFunc;
  return {
    title,
    body,
    skillsName,
    skillsValue,
    isOnEthereum,
    imgSrc,
    tokenId,
    cardFunction,
    cardAttrFunction
  };
}

function cardAttrFunc(item) {
  /* deprecated */
}

function cardSelectFunc(tokenId) {
  console.log("cardSelectFunc");
  console.log(tokenId);
}

export function loadCardData(item){
  // console.log("Loading cards data..", item.baller_id)
  let uri = item.baller_uri;
  let url;
  // // get base64 part of uri
  let base64Only = uri.slice(29, -1)
  // // convert Base64 to svg 
  try {
    // Code that may throw an error
    let decodedBase64 = atob(base64Only)
    
    let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
    svgText = addArmsSvg(svgText)
    //convert svg to image
    let blob = new Blob([svgText], {type: 'image/svg+xml'});
    // console.log(blob);
    url = URL.createObjectURL(blob);
    // console.log(url);
  } catch (error) {
    // try removing trailing 0
    try{
      let decodedBase64 = atob(base64Only.slice(0,base64Only.length-1))
      let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
      //convert svg to image
      let blob = new Blob([svgText], {type: 'image/svg+xml'});
      // console.log(blob);
      url = URL.createObjectURL(blob);
      // console.log(url);
    } catch(error){
            // Handle the error
      // console.log(base64Only)
      // console.error(error.message);
    }
  }

  const title = "Baller #"+ item.baller_id;
  const body = "Attributes";
  let imgSrc = url;
  let tokenId = item.baller_id;
  let cardFunction;
  return {
    title,
    body,
    imgSrc,
    tokenId,
    cardFunction
  };
}

export function uriToImage(tokenUri){
  let url;
  // // get base64 part of uri
  let base64Only = tokenUri.slice(29, -1)
  // // convert Base64 to svg 
  try {
    // Code that may throw an error
    let decodedBase64 = atob(base64Only)
    
    let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
    svgText = addArmsSvg(svgText)
    //convert svg to image
    let blob = new Blob([svgText], {type: 'image/svg+xml'});
    // console.log(blob);
    url = URL.createObjectURL(blob);
    // console.log(url);
  } catch (error) {
    // try removing trailing 0
    try{
      let decodedBase64 = atob(base64Only.slice(0,base64Only.length-1))
      let svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
      //convert svg to image
      let blob = new Blob([svgText], {type: 'image/svg+xml'});
      // console.log(blob);
      url = URL.createObjectURL(blob);
      // console.log(url);
    } catch(error){
            // Handle the error
      // console.log(base64Only)
      // console.error(error.message);
    }
  }
  return url;
}

export function uriToSvgText(tokenUri){
  let svgText;
  // get base64 part of uri
  let base64Only = tokenUri.slice(29, -1)
  // convert Base64 to svg 
  try {
    // Code that may throw an error
    let decodedBase64 = atob(base64Only)
    svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
  } catch (error) {
    // try removing trailing 0
    try{
      let decodedBase64 = atob(base64Only.slice(0,base64Only.length-1))
      svgText = decodedBase64.slice(18, decodedBase64.indexOf('</svg>')+6)
    } catch(error){
      // Handle the error
      console.error(error.message);
    }
  }
  return svgText;
}

export function svgTextToImage(svgText) {
  // Convert SVG text to image
  let blob = new Blob([svgText], {type: 'image/svg+xml'});
  let url = URL.createObjectURL(blob);

  return url;
}


export async function getOwnedPlayers(_owner, CBAddress,web3){
  console.log("Getting owned players..", _owner, CBAddress);
  const CBContract = new web3.eth.Contract(INFTContract.abi, CBAddress);
  var ownedNFTs = [];
  await CBContract.methods.tokensOfOwner(_owner).call().then(
    _nfts => {
      ownedNFTs = _nfts;
  })
  console.log(ownedNFTs);
  return ownedNFTs;
}

export function getSkillsFromPlayerData(playerData){
  let skillsName = ["Stamina","Playmaking","Scoring","Winger", "Goalkeeping", "Passing", "Defending","SetPieces", "Learning Rate"];
  let skillsValue = [playerData.stamina,playerData.playmaking,playerData.scoring,playerData.winger,playerData.goalkeeping,playerData.passing,playerData.defending,playerData.set_pieces,playerData.learning_rate];
  return [skillsName, skillsValue];
}


export function replaceColorWithTransparency(obj, color) {
  let newObj = JSON.parse(JSON.stringify(obj));
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  newObj.forEach(item => {
      if (item.svgText) {
          // Parse the SVG text
          let svgDoc = parser.parseFromString(item.svgText, "image/svg+xml");
          let elements = svgDoc.querySelectorAll(`[fill='${color}']`);

          // Change fill-opacity for elements with the specified color
          elements.forEach(el => {
            if(el.hasAttribute('fill')) {
                el.setAttribute('fill-opacity', '0');
            }
            if(el.style.fill === color) {
                el.style.fillOpacity = '0';
            }
          });

          // Serialize the SVG back to string
          item.svgText = serializer.serializeToString(svgDoc.documentElement);
      }
  });

  return newObj;
}

export function replaceBackgroundColorWithTransparency(obj, color) {
  let newObj = JSON.parse(JSON.stringify(obj));
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  newObj.forEach(item => {
      if (item.svgText) {
          // Parse the SVG text
          let svgDoc = parser.parseFromString(item.svgText, "image/svg+xml");
          let elements = svgDoc.querySelectorAll(`[fill='${color}']`);

          elements.forEach(el => {
            let x = parseFloat(el.getAttribute('x'));
            let y = parseFloat(el.getAttribute('y'));
          
            if ( (x <= 100 || x >= 210) && y <= 280 || (x > 100 && x < 210) && y < 60 || isShirtEdges(x,y) ){
              if(el.hasAttribute('fill')) {
                el.setAttribute('fill-opacity', '0');
              }
              if(el.style.fill === color) {
                el.style.fillOpacity = '0';
              }
            }
          });

          // Serialize the SVG back to string
          item.svgText = serializer.serializeToString(svgDoc.documentElement);
      }
  });

  return newObj;
}

export function replaceSvgBackgroundColorWithTransparency(svgText, color) {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  // Parse the SVG text
  let svgDoc = parser.parseFromString(svgText, "image/svg+xml");
  let elements = svgDoc.querySelectorAll(`[fill='${color}']`);

  elements.forEach(el => {
    let x = parseFloat(el.getAttribute('x'));
    let y = parseFloat(el.getAttribute('y'));

    if ((x <= 100 || x >= 210) && y <= 280 || (x > 100 && x < 210) && y < 60 || isShirtEdges(x, y)) {
      if (el.hasAttribute('fill')) {
        el.setAttribute('fill-opacity', '0');
      }
      if (el.style.fill === color) {
        el.style.fillOpacity = '0';
      }
    }
  });

  // Serialize the SVG back to string
  svgText = serializer.serializeToString(svgDoc.documentElement);

  return svgText;
}

function isShirtEdges(x, y) {
  const edges = [
    { x: [10, 300], y: 310 },
    { x: [20, 290], y: 300 },
    { x: [30, 280], y: 290 }
  ];

  return edges.some(edge => (x <= edge.x[0] || x >= edge.x[1]) && y === edge.y);
}

function isShirtEdgesLong(x, y) {
  const edges = [
    { x: [10, 300], y: 310 },
    { x: [20, 290], y: 300 },
    { x: [30, 280], y: 290 },
    { x: [20, 290], y: 390 },
    { x: [30, 280], y: 400 },
  ];

  return edges.some(edge => (x <= edge.x[0] || x >= edge.x[1]) && y === edge.y) || (y > 300 && x < 30 || x > 280);
}

export function replaceSvgBackgroundColorWithTransparencyLong(svgText, color) {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  // Parse the SVG text
  let svgDoc = parser.parseFromString(svgText, "image/svg+xml");
  let elements = svgDoc.querySelectorAll(`[fill='${color}']`);

  elements.forEach(el => {
    let x = parseFloat(el.getAttribute('x'));
    let y = parseFloat(el.getAttribute('y'));

    // Use the updated isShirtEdgesLong function
    if ((x <= 100 || x >= 210) && y <= 280 || (x > 100 && x < 210) && y < 60 || isShirtEdgesLong(x, y)) {
      if (el.hasAttribute('fill')) {
        el.setAttribute('fill-opacity', '0');
      }
      if (el.style.fill === color) {
        el.style.fillOpacity = '0';
      }
    }
  });

  // Serialize the SVG back to string
  svgText = serializer.serializeToString(svgDoc.documentElement);

  return svgText;
}


export function replaceItemColorWithTransparency(item, color) {
  let newObj = JSON.parse(JSON.stringify(item));
  const parser = new DOMParser();
  const serializer = new XMLSerializer();

  if (newObj.svgText) {
    // Parse the SVG text
    let svgDoc = parser.parseFromString(newObj.svgText, "image/svg+xml");
    let elements = svgDoc.querySelectorAll(`[fill='${color}']`);

    // Change fill-opacity for elements with the specified color
    elements.forEach(el => {
      if(el.hasAttribute('fill')) {
        el.setAttribute('fill-opacity', '0');
      }
      if(el.style.fill === color) {
        el.style.fillOpacity = '0';
      }
    });

    // Serialize the SVG back to string
    newObj.svgText = serializer.serializeToString(svgDoc.documentElement);
  }

  return newObj;
}

export function addArmsSvg(svgText) {

  // Update the viewBox to accommodate additional rows
  let updatedSVG = svgText.replace("viewBox='0 0 320 320'", 'viewBox="0 0 320 410"');

  // Parse the SVG to get the color at coordinates x='150' y='180'
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
  const element = svgDoc.querySelector("rect[x='150'][y='180']");
  const colorAtCoordinates = element ? element.getAttribute('fill') : '#d0a458';

  // The rect elements to be added, with colorAtCoordinates replacing #d0a458
  const rectElements = `
  <rect x="0" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="320" width="10" height="10" fill="#000000" />
  <rect x="30" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="40" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="50" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="60" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="70" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="80" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="90" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="100" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="110" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="120" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="130" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="140" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="150" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="160" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="170" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="180" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="190" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="200" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="210" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="220" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="230" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="240" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="250" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="260" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="270" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="280" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="290" y="320" width="10" height="10" fill="#000000" />
  <rect x="300" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="320" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="330" width="10" height="10" fill="#000000" />
  <rect x="30" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="40" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="50" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="60" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="70" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="80" y="330" width="10" height="10" fill="#000000" />
  <rect x="90" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="100" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="110" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="120" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="130" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="140" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="150" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="160" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="170" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="180" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="190" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="200" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="210" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="220" y="330" width="10" height="10" fill="#000000" />
  <rect x="230" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="240" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="250" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="260" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="270" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="280" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="290" y="330" width="10" height="10" fill="#000000" />
  <rect x="300" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="330" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="340" width="10" height="10" fill="#000000" />
  <rect x="30" y="340" width="10" height="10" fill="#000000" />
  <rect x="40" y="340" width="10" height="10" fill="#000000" />
  <rect x="50" y="340" width="10" height="10" fill="#000000" />
  <rect x="60" y="340" width="10" height="10" fill="#000000" />
  <rect x="70" y="340" width="10" height="10" fill="#000000" />
  <rect x="80" y="340" width="10" height="10" fill="#000000" />
  <rect x="90" y="340" width="10" height="10" fill="#000000" />
  <rect x="100" y="340" width="10" height="10" fill="#000000" />
  <rect x="110" y="340" width="10" height="10" fill="#000000" />
  <rect x="120" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="130" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="140" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="150" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="160" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="170" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="180" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="190" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="200" y="340" width="10" height="10" fill="#000000" />
  <rect x="210" y="340" width="10" height="10" fill="#000000" />
  <rect x="220" y="340" width="10" height="10" fill="#000000" />
  <rect x="230" y="340" width="10" height="10" fill="#000000" />
  <rect x="240" y="340" width="10" height="10" fill="#000000" />
  <rect x="250" y="340" width="10" height="10" fill="#000000" />
  <rect x="260" y="340" width="10" height="10" fill="#000000" />
  <rect x="270" y="340" width="10" height="10" fill="#000000" />
  <rect x="280" y="340" width="10" height="10" fill="#000000" />
  <rect x="290" y="340" width="10" height="10" fill="#000000" />
  <rect x="300" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="340" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="350" width="10" height="10" fill="#000000" />
  <rect x="30" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="40" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="50" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="60" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="70" y="350" width="10" height="10" fill="#000000" />
  <rect x="80" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="90" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="100" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="110" y="350" width="10" height="10" fill="#000000" />
  <rect x="120" y="350" width="10" height="10" fill="#000000" />
  <rect x="130" y="350" width="10" height="10" fill="#000000" />
  <rect x="140" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="150" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="160" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="170" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="180" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="190" y="350" width="10" height="10" fill="#000000" />
  <rect x="200" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="210" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="220" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="230" y="350" width="10" height="10" fill="#000000" />
  <rect x="240" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="250" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="260" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="270" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="280" y="350" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="290" y="350" width="10" height="10" fill="#000000" />
  <rect x="300" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="350" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="360" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="360" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="360" width="10" height="10" fill="#000000" />
  <rect x="30" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="40" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="50" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="60" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="70" y="360" width="10" height="10" fill="#000000" />
  <rect x="80" y="360" width="10" height="10" fill="#000000" />
  <rect x="90" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="100" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="110" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="120" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="130" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="140" y="360" width="10" height="10" fill="#000000" />
  <rect x="150" y="360" width="10" height="10" fill="#000000" />
  <rect x="160" y="360" width="10" height="10" fill="#000000" />
  <rect x="170" y="360" width="10" height="10" fill="#000000" />
  <rect x="180" y="360" width="10" height="10" fill="#000000" />
  <rect x="190" y="360" width="10" height="10" fill="#000000" />
  <rect x="200" y="360" width="10" height="10" fill="#000000" />
  <rect x="210" y="360" width="10" height="10" fill="#000000" />
  <rect x="220" y="360" width="10" height="10" fill="#000000" />
  <rect x="230" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="240" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="250" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="260" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="270" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="280" y="360" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="290" y="360" width="10" height="10" fill="#000000" />
  <rect x="300" y="360" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="360" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="370" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="370" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="370" width="10" height="10" fill="#000000" />
  <rect x="30" y="370" width="10" height="10" fill="#000000" />
  <rect x="40" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="50" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="60" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="70" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="80" y="370" width="10" height="10" fill="#000000" />
  <rect x="90" y="370" width="10" height="10" fill="#000000" />
  <rect x="100" y="370" width="10" height="10" fill="#000000" />
  <rect x="110" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="120" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="130" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="140" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="150" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="160" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="170" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="180" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="190" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="200" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="210" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="220" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="230" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="240" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="250" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="260" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="270" y="370" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="280" y="370" width="10" height="10" fill="#000000" />
  <rect x="290" y="370" width="10" height="10" fill="#000000" />
  <rect x="300" y="370" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="370" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="380" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="380" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="380" width="10" height="10" fill="#000000" />
  <rect x="30" y="380" width="10" height="10" fill="#000000" />
  <rect x="40" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="50" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="60" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="70" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="80" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="90" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="100" y="380" width="10" height="10" fill="#000000" />
  <rect x="110" y="380" width="10" height="10" fill="#000000" />
  <rect x="120" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="130" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="140" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="150" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="160" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="170" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="180" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="190" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="200" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="210" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="220" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="230" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="240" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="250" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="260" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="270" y="380" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="280" y="380" width="10" height="10" fill="#000000" />
  <rect x="290" y="380" width="10" height="10" fill="#000000" />
  <rect x="300" y="380" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="380" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="30" y="390" width="10" height="10" fill="#000000" />
  <rect x="40" y="390" width="10" height="10" fill="#000000" />
  <rect x="50" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="60" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="70" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="80" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="90" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="100" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="110" y="390" width="10" height="10" fill="#000000" />
  <rect x="120" y="390" width="10" height="10" fill="#000000" />
  <rect x="130" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="140" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="150" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="160" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="170" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="180" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="190" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="200" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="210" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="220" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="230" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="240" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="250" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="260" y="390" width="10" height="10" fill="${colorAtCoordinates}" />
  <rect x="270" y="390" width="10" height="10" fill="#000000" />
  <rect x="280" y="390" width="10" height="10" fill="#000000" />
  <rect x="290" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="300" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="390" width="10" height="10" fill="#ffffff" />
  <rect x="0" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="10" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="20" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="30" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="40" y="400" width="10" height="10" fill="#000000" />
  <rect x="50" y="400" width="10" height="10" fill="#000000" />
  <rect x="60" y="400" width="10" height="10" fill="#000000" />
  <rect x="70" y="400" width="10" height="10" fill="#000000" />
  <rect x="80" y="400" width="10" height="10" fill="#000000" />
  <rect x="90" y="400" width="10" height="10" fill="#000000" />
  <rect x="100" y="400" width="10" height="10" fill="#000000" />
  <rect x="110" y="400" width="10" height="10" fill="#000000" />
  <rect x="120" y="400" width="10" height="10" fill="#000000" />
  <rect x="130" y="400" width="10" height="10" fill="#000000" />
  <rect x="140" y="400" width="10" height="10" fill="#000000" />
  <rect x="150" y="400" width="10" height="10" fill="#000000" />
  <rect x="160" y="400" width="10" height="10" fill="#000000" />
  <rect x="170" y="400" width="10" height="10" fill="#000000" />
  <rect x="180" y="400" width="10" height="10" fill="#000000" />
  <rect x="190" y="400" width="10" height="10" fill="#000000" />
  <rect x="200" y="400" width="10" height="10" fill="#000000" />
  <rect x="210" y="400" width="10" height="10" fill="#000000" />
  <rect x="220" y="400" width="10" height="10" fill="#000000" />
  <rect x="230" y="400" width="10" height="10" fill="#000000" />
  <rect x="240" y="400" width="10" height="10" fill="#000000" />
  <rect x="250" y="400" width="10" height="10" fill="#000000" />
  <rect x="260" y="400" width="10" height="10" fill="#000000" />
  <rect x="270" y="400" width="10" height="10" fill="#000000" />
  <rect x="280" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="290" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="300" y="400" width="10" height="10" fill="#ffffff" />
  <rect x="310" y="400" width="10" height="10" fill="#ffffff" />
  `;

  // Insert the new rect elements before the closing </svg> tag
  updatedSVG = updatedSVG.replace('</svg>', rectElements + '</svg>');
  // console.log(updatedSVG);
  return updatedSVG;
}

export function formatHomeCardsForLanding(homeCards) {
  return homeCards.slice(0, 3).map(player => {
    let url = uriToImage(player.baller_uri); 
    
    let skillsName = ["Stamina","Playmaking","Scoring","Winger", "Goalkeeping", "Passing", "Defending","SetPieces"];
    let skillsValue = [player.stamina,player.playmaking,player.scoring,player.winger,player.goalkeeping,player.passing,player.defending,player.set_pieces];
  
    return {
      image: url,
      title: `Baller #${player.baller_id}`,
      skillsName: skillsName,
      skillsValue: skillsValue
    };
  });
}



