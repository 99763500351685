import React, { useState, useEffect, useRef } from "react";
import * as FaIcons from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.scss';
import { IconContext } from 'react-icons';

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [sidebar, setSidebar] = useState(window.innerWidth > 1000);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const navMenuRef = useRef(null);

  useEffect(() => {
    const updateMobileState = () => {
      setIsMobile(window.innerWidth < 1000);
      if (window.innerWidth >= 1000) {
        setSidebar(true);
      }
    };

    window.addEventListener("resize", updateMobileState);
    updateMobileState();

    return () => {
      window.removeEventListener("resize", updateMobileState);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        navMenuRef.current &&
        !navMenuRef.current.contains(event.target) &&
        isMobile &&
        sidebar
      ) {
        setSidebar(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobile, sidebar]);

  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };


  return (
    <>
        <IconContext.Provider value={{ color: "#fff" }}>
          {isMobile && (
            <div className="navbar">
              <Link to="#" className="menu-bars" onClick={toggleSidebar}>
                <FaIcons.FaBars className="bordered" />
              </Link>
            </div>
          )}
          <nav
            ref={navMenuRef}
            className={`nav-menu${sidebar ? " active" : ""}`}
          >
            <ul className="nav-menu-items">
              <li
                className="navbar-toggle"
                style={{ margin: "0 0 3rem 0" }}
              >
                {!isMobile && (
                  <Link to="#" className="menu-close" onClick={toggleSidebar}>
                    {/* <FaIcons.FaArrowLeft className="bordered" /> */}
                  </Link>
                )}
                <div style={{ color: "white", fontStyle: "italic" }}>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div
                      className="sub-title"
                      style={{
                        paddingLeft: "18%",
                        paddingTop: "5%",
                        fontSize: "20pt",
                      }}
                    >
                      CHAINBALLERS
                    </div>
                  </Link>
                </div>
              </li>
              {SidebarData.map((item, index) => (
                <li
                  key={index}
                  className={`${
                    item.title === selectedItem
                      ? "nav-text-selected"
                      : item.cName
                  } ${item.title === selectedItem ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedItem(item.title);
                    if (isMobile) {
                      setSidebar(false);
                    }
                  }}
                >
                  {item.path === "/docs" ? (
                    <a
                      href="https://docs.chainballers.xyz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.icon}
                      <span className="navbar-title">
                        {item.title}&nbsp;&nbsp;
                      </span>
                      {item.icon2}
                    </a>
                  ) : (
                    <Link to={item.path}>
                      {item.icon}
                      <span className="navbar-title">
                        {item.title}&nbsp;&nbsp;
                      </span>
                      {item.icon2}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </IconContext.Provider>
    </>
  );
}

export default Navbar;
