import React, { useState, useEffect } from 'react';
import { PiLightningDuotone } from "react-icons/pi";
import { Row, Col, Container } from 'react-bootstrap';
import { svgTextToImage, replaceSvgBackgroundColorWithTransparencyLong } from '../../../helpers/players.js';
import './style/LeagueSelectionCard.css';

function LeagueOption({ data, createTeam, onLeagueSelect, isSelected, isMobile, chainId }) {
    const [svgImage, setSvgImage] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [showHiddenText, setShowHiddenText] = useState(false);


    // console.log(data);

    useEffect(() => {
        async function loadSvgAsText() {
            try {
                // Use dynamic path based on league name
                const response = await fetch(`./images/faces/${data.name}-league-face.svg`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const svgText = await response.text();
                return svgText;
            } catch (error) {
                console.error('Error fetching the SVG:', error);
            }
        }

        loadSvgAsText().then(svgText => {
            const modifiedSvgText = replaceSvgBackgroundColorWithTransparencyLong(svgText, "#ffffff");
            const imageElement = svgTextToImage(modifiedSvgText);
            setSvgImage(imageElement);
        });
    }, [data.name]);

    const fixedButtonRowStyle = {
        position: 'relative',
        bottom: isMobile ? '2%' : '5%',
        width: '100%',
        textAlign: 'center',
        zIndex: 3,
    };

    const containerStyle = {
        position: 'relative',
        height: '100%',
        paddingTop: '5%',
    };

    const handleCardClick = (e) => {
        if (e.target.closest('button')) return;
        onLeagueSelect();
        
        // setIsSelected(!isSelected);
    };

    const handleButtonClick = (e) => {
        onLeagueSelect();
        setIsClicked(true);
        e.stopPropagation();
        createTeam(chainId);
    };

    return (
        <div className={`first ${isSelected ? 'card-selected' : ''}`} onClick={handleCardClick}>
            <div className="base">
                <div className="gradient-overlay"></div>
                <img src={`./images/${data.name}-league.png`} alt={`${data.name} League`} className="image-style" />
                <div
                    style={containerStyle}
                    onMouseEnter={() => setShowHiddenText(true)}
                    onMouseLeave={() => setShowHiddenText(false)}
                >
                    <Row className="justify-content-center">
                        <Col className='text-center z-index-3'>
                            <div className='sub-title' >
                                {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className='text-center z-index-3'>
                            <div className='sub-title' style={{ fontSize: "28pt", lineHeight: "1.2" }}>League</div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className='text-center z-index-3'>
                            <div className='sub-title-style sub-title'>
                                powered by
                                <img src={`./icons/${data.name}.png`}
                                    style={{ width: '30px', height: '30px', marginLeft: '10px', marginRight: '5px', marginTop: "5px" }}
                                    alt={`${data.name}_logo`} />
                            </div>
                        </Col>
                    </Row>
                    {/* {!showHiddenText ? (
                        <Row className="justify-content-center">
                            <Col className='text-center z-index-3'>
                                <img className="img-style" src={svgImage} alt="Base League Face" />
                            </Col>
                        </Row>
                    ) : (
                        <>
                            {data.numberOfTeams && (
                                <>
                                    <Row className="justify-content-center" style={{ paddingTop: "4%" }}>
                                        <Col className='text-center z-index-3'>
                                            <div className='hidden-text-style sub-title hidden-on-load'>
                                                Number of Teams
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col className='text-center z-index-3'>
                                            <div className='large-hidden-text-style sub-title hidden-on-load'>
                                                {data.numberOfTeams}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {data.totalValue && (
                                <>
                                    <Row className="justify-content-center">
                                        <Col className='text-center z-index-3'>
                                            <div className='hidden-text-style sub-title hidden-on-load'>
                                                Total Value
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col className='text-center z-index-3'>
                                            <div className='large-hidden-text-style sub-title hidden-on-load'>
                                                ${data.totalValue}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {data.averageTeamCost && (
                                <>
                                    <Row className="justify-content-center">
                                        <Col className='text-center z-index-3'>
                                            <div className='hidden-text-style sub-title hidden-on-load'>
                                                Average Team Cost
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col className='text-center z-index-3'>
                                            <div className='large-hidden-text-style sub-title hidden-on-load'>
                                                ${data.averageTeamCost}
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </>
                    )} */}

                    <div style={fixedButtonRowStyle}>
                        <Col>
                            <button className={`gradient-button-2 ${isClicked ? 'card-selected' : ''}`} onClick={handleButtonClick}>
                                <div className='sub-title-mobile'>
                                    Create Team
                                    <PiLightningDuotone style={{ color: 'black', secondaryColor: "yellow", marginRight: '5px' }} />
                                </div>
                            </button>
                        </Col>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LeagueOption;
