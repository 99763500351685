// // Safe
// import { SafeAuthKit, SafeAuthProviderType } from '@safe-global/auth-kit';
import Web3 from 'web3';
// import { Web3Auth } from "@web3auth/modal";

// export async function initWeb3Auth(){
//   const web3auth = new Web3Auth({
//     clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
//     // web3AuthNetwork: "",
//     chainConfig: {
//       chainNamespace: "eip155",
//       chainId: "0x1",
//       rpcTarget: process.env.REACT_APP_ETHEREUM_RPC_URL,
//       displayName: "Ethereum Mainnet",
//       blockExplorer: "https://etherscan.io",
//       ticker: "ETH",
//       tickerName: "Ethereum",
//     },
//   });
//   await web3auth.initModal();
  
//   const web3authProvider = await web3auth.connect();
  
//   const web3 = new Web3(web3authProvider);
//   return web3;
// }

// export async function initWeb3AuthGnosis(){
//   const web3auth = new Web3Auth({
//     clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
//     // web3AuthNetwork: "cyan",
//     chainConfig: {
//       chainNamespace: "eip155",
//       chainId: "0x64",
//       rpcTarget: process.env.REACT_APP_GNOSIS_RPC_URL, //"https://rpc.ankr.com/gnosis",
//       displayName: "Gnosis Chain",
//       blockExplorer: "https://gnosisscan.io",
//       ticker: "xDAI",
//       tickerName: "xDAI",
//     },
//   });
//   await web3auth.initModal();
  
//   const web3authProvider = await web3auth.connect();
  
//   const web3 = new Web3(web3authProvider);
//   return web3;
// }

// export async function initAuth(){
//     // console.log(SafeAuthKit, SafeAuthProviderType)
//     let RPC_URL = process.env.REACT_APP_ETHEREUM_RPC_URL;
//     let AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
//     let safeAuthKit = await SafeAuthKit.init(SafeAuthProviderType.Web3Auth, {
//       chainId: '0x1', // 0x5 for goerli
//       authProviderConfig: 
//       {
//         rpcTarget: RPC_URL,
//         clientId: AUTH0_CLIENT_ID,
//         network: 'mainnet', // The network to use for the Web3Auth modal. Use 'testnet' while developing and 'mainnet' for production use
//         theme: 'dark', // The theme to use for the Web3Auth modal
//         modalConfig: {
//           // The modal config is optional and it's used to customize the Web3Auth modal
//           // Check the Web3Auth documentation for more info: https://web3auth.io/docs/sdk/web/modal/whitelabel#initmodal
//         }
//       }
//     })
//     return safeAuthKit;
// }

// export async function initAuthL2(){
//   let RPC_URL = process.env.REACT_APP_BASE_RPC_URL;
//   let AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
//   let safeAuthKit = await SafeAuthKit.init(SafeAuthProviderType.Web3Auth, {
//     chainId: '0x2105',  // 0x14A33 goerli base
//     authProviderConfig: 
//     {
//       rpcTarget: RPC_URL,
//       clientId: AUTH0_CLIENT_ID,
//       network: 'mainnet', // The network to use for the Web3Auth modal. Use 'testnet' while developing and 'mainnet' for production use
//       theme: 'dark', // The theme to use for the Web3Auth modal
//       modalConfig: {
//         // The modal config is optional and it's used to customize the Web3Auth modal
//         // Check the Web3Auth documentation for more info: https://web3auth.io/docs/sdk/web/modal/whitelabel#initmodal
//       }
//     }
//   })
//   return safeAuthKit;
// } 

// export async function initAuthL2Gnosis(){
//   let RPC_URL = "https://rpc.ankr.com/gnosis";
//   let AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
//   let safeAuthKit = await SafeAuthKit.init(SafeAuthProviderType.Web3Auth, {
//     chainId: '0x64', 
//     authProviderConfig: 
//     {
//       rpcTarget: RPC_URL,
//       clientId: AUTH0_CLIENT_ID,
//       network: 'cyan', // The network to use for the Web3Auth modal. Use 'testnet' while developing and 'mainnet' for production use
//       theme: 'dark', // The theme to use for the Web3Auth modal
//       modalConfig: {
//         // The modal config is optional and it's used to customize the Web3Auth modal
//         // Check the Web3Auth documentation for more info: https://web3auth.io/docs/sdk/web/modal/whitelabel#initmodal
//       }
//     }
//   })
//   return safeAuthKit;
// } 

  
export async function loadWeb3Provider(){
  if (window.ethereum){
    return new Web3(window.ethereum);
  } else {
    return undefined;
  }
}

export async function handleDownloadMetaMask(){
  // Redirect user to MetaMask download page
  window.open('https://metamask.io/download.html', '_blank');
};

// export async function loadProvider() {
//     let safeAuthKit = await initAuth();
//     let provider = safeAuthKit.getProvider();
//     return provider;
// }   

// export async function loadProviderL2() {
//   let safeAuthKit = await initAuthL2();
//   let provider = safeAuthKit.getProvider();
//   return provider;
// }   

export async function connectWeb3Wallet(){
  let accountsList = [];
  if (window.ethereum){
    await window.ethereum
    .request({
        method: "eth_requestAccounts",
    })
    .then((accounts) => {
      accountsList = accounts

    })
    .catch((error) => {
      console.log(error);
    });
  }
  return accountsList;
}

export async function accountChangeListener(accountListenerFallback){
  let provider = window.ethereum;
  provider
      .on('accountsChanged', (accounts) => {
        console.log("account changed.")
         let callbackData ={}
          callbackData.signer = accounts[0];
          // Reload interface with accounts[0]
          accountListenerFallback(callbackData); // updates state account
      });
  
  provider.on('chainChanged', (networkId)=> {
    // Time to reload your interface with the new networkId
    console.log("Network changed.")
  })
}


export function prepLoginData(signer, selectedSafe, ownedSafes, ownedSafesData, loginMethod, web3) {
  // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
  let loginData = {}
  loginData.signer = signer;
  // loginData.signer ="0x7bF8ee10E37498b1338c64458ec3a546F38BC40D" ;
  loginData.selectedSafe = selectedSafe;
  loginData.ownedSafes = ownedSafes;
  loginData.ownedSafesData = ownedSafesData;
  loginData.loginMethod = loginMethod;
  if (web3 != undefined) {
      loginData.web3 = web3;
  } 
  return loginData;
}