import {React, useState, useCallback} from "react";
// Components
import {Spinner} from 'react-bootstrap';
import DownloadWalletModal from './modal/DownloadWalletModal';
import LoginButton from './login/LoginButton';
// login 
import * as login from '../helpers/login';


function SignInButton({loginButtonFallback, customText,isMobile, style}) {
    const [signer, setSigner] = useState(undefined)
    const [loginLoading, setLoginLoading] = useState(false)
    const [openDownloadMetaMask, setOpenDownloadMetaMask] = useState(false)

    async function handleLoginClick(userInfo){
        let loginMethod; let web3;
        if (signer == undefined && userInfo != undefined) {
            try {
                // console.log("userInfo:", userInfo);
                setSigner(userInfo.wallet);
                if (userInfo.verifier != undefined) {
                    loginMethod = "WEB3AUTH";
                    web3 = userInfo.provider;
                } else {
                    loginMethod = "WEB3";
                    web3 = await login.loadWeb3Provider();
                    await login.accountChangeListener(accountListenerFallback);
                }
                let loginData = prepLoginData(userInfo.wallet, undefined, [], [], loginMethod, web3);
                loginButtonFallback(loginData)
            } catch (e) {
                console.log("Error logging in:", e);
            }
        } else {
            console.log("sign out")
            let loginData = prepLoginData(undefined, undefined, [], "", [])
            loginButtonFallback(loginData)
            setSigner(undefined);
            // await signOut();
        }
    };

    const accountListenerFallback = useCallback(async (callbackData) => {
        let loginData = prepLoginData(callbackData.signer, undefined, [], [], "", undefined);
        loginButtonFallback(loginData)
    }, []);
    
    function prepLoginData(signer, selectedSafe, ownedSafes, ownedSafesData, loginMethod, web3) {
        // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
        let loginData = {}
        loginData.signer = signer;
        // loginData.signer ="0x7bF8ee10E37498b1338c64458ec3a546F38BC40D" ;
        loginData.selectedSafe = selectedSafe;
        loginData.ownedSafes = ownedSafes;
        loginData.ownedSafesData = ownedSafesData;
        loginData.loginMethod = loginMethod;
        if (web3 != undefined) {
            loginData.web3 = web3;
        } 
        return loginData;
    }

    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <LoginButton 
                onLoginClick={handleLoginClick}
                onClick={() => setLoginLoading(true)}
                loginText={
                    !loginLoading ? (
                        <div className="sub-title-mobile">{customText}</div>
                    ) : (
                        <Spinner animation="border" variant="dark" />
                    )
                }
                style={style}
            />
            <DownloadWalletModal
                show={openDownloadMetaMask}
                onHide={() => setOpenDownloadMetaMask(false)}
            />
        </div>
    );

}

    export default SignInButton;