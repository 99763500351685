import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";

import pitch from "./img/pitch.png";
import lines from "./img/lines.png";
import squares from "./img/squares.png";
import circles from "./img/circles.png";

import TeamView from "./team/Team.tsx";


class Pitch extends Component {

    static propTypes = {
        color: PropTypes.string,
        size: PropTypes.oneOf([ "small", "normal", "big", "responsive", "fill" ]),
        pattern: PropTypes.oneOf([ "lines", "squares", "circles" ]),
        homeTeam: TeamView.teamShape,
        awayTeam: TeamView.teamShape,
        gkStyle: PropTypes.object,
        isMobile: PropTypes.bool
    };

    render() {

        const { color, size, pattern, homeTeam, awayTeam } = this.props;

        return (
            <div
                className={ classNames("pitch", size || "normal") }
                style={ {
                    backgroundColor: color || "",
                    backgroundImage: this.getPitchBackground(pattern),
                } }
            >{ this.renderTeams(homeTeam, awayTeam) }</div>
        );
    }

    getPitchBackground = (pattern) => {
        const images = [
            `url(${ pitch })`
        ];

        if (pattern) {
            // images.push(`url(${ this.getPatternImage(pattern) })`);
        }

        return images.join(", ");
    };

    getPatternImage = (pattern) => {
        switch (pattern) {
            case "lines":
                return lines;
            case "squares":
                return squares;
            case "circles":
                return circles;
        }
    };

    renderTeams = (homeTeam, awayTeam) => {
        const { gkStyle } = this.props;
        return (
            <div className="teams">
                {homeTeam && (
                    <div className="team">
                        <div className="gk" style={gkStyle}>
                            <TeamView 
                                team={homeTeam} 
                                isMobile={this.props.isMobile}
                            />
                        </div>
                    </div>
                )}
                {awayTeam && (
                    <div className="team">
                        <div className="gk" style={gkStyle}>
                            <TeamView 
                                away team={awayTeam} 
                                isMobile={this.props.isMobile}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };

}

export default Pitch;
