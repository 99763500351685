import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';
import { Web3AuthProvider } from './contexts/web3AuthContext';
import useAppState from './hooks/useAppState';

import Layout from './components/layout/Layout';
import PaymentModal from './components/modal/PaymentModal';
import HomeScreen from './pages/HomeScreen';
import Dashboard from './pages/Dashboard';
import Schedule from './pages/Schedule';
import Formation from './pages/Formation';
import Training from './pages/Training';
import Market from './pages/Market/Market';
import Explore from './pages/Explore';
import Referral from './pages/Referral';
import Background from './pages/Background';
import PlayerModal from './components/modal/PlayerModal';
import ShareTeamModal from './components/modal/ShareTeamModal';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51PtGtB065mtL9wAPqSYHTDq9FGqIGrAPwFaieCxLMVOi4dPWYMPJ70YKuIRNrjtdql6KVg9V2N1xojl4WUj7qUrO00kWdVkRIG');

const App = () => {
  const { state, actions, modals } = useAppState();

  return (
    <Web3AuthProvider>
      <Router>
        <Routes>
          <Route path='/' exact element={
            <>
                <Layout
                  loginButtonFallback={actions.loginButtonFallback}
                  signer={state.account}
                  loginMethod={state.loginMethod}
                  startTimer={state.startTimer}
                  isMobile={state.isMobile}
                  hideNavbarOnRender={!state.transitionNavbar}
                  hideBannerOnRender={!state.transitionBanner}
                >
                <HomeScreen
                  homepagecallback={actions.homepageCallback}
                  isMobile={state.isMobile}
                  handlePlayClick={actions.handlePlayClick}
                />
              </Layout>
            </>
          } />
          <Route path='/app' element={
            <>
            {state.account !== undefined && state.ownedTeamId === 0 && (
                <Background 
                  onRollupSelect={actions.onRollupSelect}
                  isMobile={state.isMobile}
                />
            )}
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
              hideNavbarOnRender={(state.ownedTeamId == 0) && state.account}
            >
                <>
                <Dashboard
                  handlesigninclick={actions.handlesigninclick}
                  dashboardCallback={actions.dashboardCallback}
                  account={state.account}
                  web3={state.web3}
                  balance={state.balance}
                  CBAddressL2={state.CBAddressL2}
                  CBAddressL1={state.CBAddressL1}
                  teamNFTAddressL1={state.teamNFTAddressL1}
                  leaguePrize={state.leaguePrize}
                  totalPrizes={state.totalPrizes}
                  selectedTeam={state.selectedTeam}
                  setTeamName={actions.setTeamName}
                  ownedTeamId={state.ownedTeamId}
                  ownedTeamChainId={state.ownedTeamChainId}
                  ownedTeamLeague={state.ownedTeamLeague}
                  ownedTeamName={state.ownedTeamName}
                  selectedRollup={state.selectedRollup}
                  ownedPlayers={state.ownedPlayers}
                  teamsData={state.teamsData}
                  getLeagueData={actions.getLeagueDataDB}
                  getLeagueDataStateless={actions.getLeagueDataStateless}
                  getAvailableGames={actions.getAvailableGames}
                  getNumberOfLeagues={actions.getNumberOfLeagues}
                  getNextDivisionTeams={actions.getNextDivisionTeams}
                  loginButtonFallback={actions.loginButtonFallback}
                  onLeagueSelectFallback={actions.onLeagueSelectFallback}
                  handlePlayerSelect={actions.onPlayerSelectFallback}
                  loadTeamOnL1={actions.loadTeamOnL1}
                  timerSetter={actions.timerSetter}
                  payWithStripe={actions.handlePayment}
                  loginMethod={state.loginMethod}
                  isMobile={state.isMobile}
                />
                <PlayerModal
                  show={modals.player}
                  onHide={() => actions.setPlayerModalShow(false)}
                  playerData={state.playerModalData}
                  isMobile={state.isMobile}
                />
                <PaymentModal
                  show={modals.payment}
                  onHide={() => actions.setPaymentModalShow(false)}
                  clientSecret={state.clientSecret}
                  stripePromise={stripePromise}
                  onSuccess={actions.onPaymentSuccess}
                  teamCostUsd={state.teamCostUsd}
                  account={state.account}
                  isMobile={state.isMobile}
                />
                </>
              </Layout>
            </>
          } />
          <Route path='/schedule' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
              hideNavbarOnRender={state.hideNavbar}
              hideBannerOnRender={state.hideNavbar}
            >
              <Schedule
                handlesigninclick={actions.handlesigninclick}
                scheduleCallback={actions.scheduleCallback}
                loginButtonFallback={actions.loginButtonFallback}
                account={state.account}
                web3={state.web3}
                CBAddressL2={state.CBAddressL2}
                teamNFTAddress={state.teamNFTAddress}
                ownedTeamId={state.ownedTeamId}
                ownedTeamLeague={state.ownedTeamLeague}
                getOwnedTeam={actions.getOwnedTeam}
                getTeamNextGameId={actions.getTeamNextGameId}
                getFormationForGame={actions.getFormationForGame}
                loadFormationById={actions.loadFormationById}
                getLeagueFullData={actions.getLeagueFullDataDB}
                getLeagueData={actions.getLeagueDataDB}
                handlePlayGame={actions.handlePlayGame}
                availableGames={state.availableGames}
                getAvailableGames={actions.getAvailableGames}
                nextGameId={state.nextGameId}
                nextGameInfo={state.nextGameInfo}
                nextGameFormationType={state.nextGameFormationType}
                nextGameFormation={state.nextGameFormation}
                nextDivisionTeams={state.nextDivisionTeams}
                getTeamNameById={actions.getTeamNameById}
                teamsData={state.teamsData}
                ownedTeamChainId={state.ownedTeamChainId}
                getPastGameResult={actions.getPastGameResult}
                getNextDivisionTeams={actions.getNextDivisionTeams}
                generateInvite={actions.generateInvite}
                handleCreateDivision={actions.createDivision}
                handleSelectedTeamsChange={actions.handleSelectedTeamsChange}
                isMobile={state.isMobile}
                onPlayGameClick={actions.onPlayGameClick}
              />
              <ShareTeamModal
                show={modals.shareTeam}
                onHide={() => actions.setShareTeamModalShow(false)}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
          <Route path='/formation' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              <Formation
                handlesigninclick={actions.handlesigninclick}
                formationCallback={actions.formationCallback}
                loginButtonFallback={actions.loginButtonFallback}
                getOwnedTeam={actions.getOwnedTeam}
                getPlayersData={actions.getPlayersData}
                account={state.account}
                web3={state.web3}
                magic={state.magic}
                CBAddressL2={state.CBAddressL2}
                teamNFTAddress={state.teamNFTAddress}
                ownedTeamChainId={state.ownedTeamChainId}
                nextGameId={state.nextGameId}
                ownedTeamId={state.ownedTeamId}
                ownedPlayers={state.ownedPlayers}
                startTimer={state.startTimer}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
          <Route path='/training' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              <Training
                handlesigninclick={actions.handlesigninclick}
                trainingCallback={actions.trainingCallback}
                loginButtonFallback={actions.loginButtonFallback}
                startTrainingCamp={actions.startTrainingCamp}
                account={state.account}
                web3={state.web3}
                magic={state.magic}
                CBAddressL2={state.CBAddressL2}
                teamNFTAddress={state.teamNFTAddress}
                ownedTeamId={state.ownedTeamId}
                ownedTeamChainId={state.ownedTeamChainId}
                ownedPlayers={state.ownedPlayers}
                startTimer={state.startTimer}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
          <Route path='/market' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              <Market
                marketCallback={actions.marketCallback}
                loginButtonFallback={actions.loginButtonFallback}
                account={state.account}
                web3={state.web3}
                CBAddressL2={state.CBAddressL2}
                CBAddressL1={state.CBAddressL1}
                ownedPlayers={state.ownedPlayers}
                ownedTeamId={state.ownedTeamId}
                ownedTeamChainId={state.ownedTeamChainId}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
          <Route path='/explore' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              <Explore
                exploreCallback={actions.exploreCallback}
                loginButtonFallback={actions.loginButtonFallback}
                account={state.account}
                web3={state.web3}
                CBAddressL2={state.CBAddressL2}
                CBAddressL1={state.CBAddressL1}
                ownedPlayers={state.ownedPlayers}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
          <Route path='/referral' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              <Referral
                referralCallback={actions.referralCallback}
                loginButtonFallback={actions.loginButtonFallback}
                account={state.account}
                web3={state.web3}
                CBAddressL2={state.CBAddressL2}
                CBAddressL1={state.CBAddressL1}
                ownedPlayers={state.ownedPlayers}
                isMobile={state.isMobile}
                createSponsoredTeam={actions.createSponsoredTeam}
              />
            </Layout>
          } />
          <Route path='/payment-success' element={
            <Layout
              loginButtonFallback={actions.loginButtonFallback}
              signer={state.account}
              loginMethod={state.loginMethod}
              startTimer={state.startTimer}
              isMobile={state.isMobile}
            >
              {!state.isMobile && state.account !== undefined && state.ownedTeamId === 0 && (
                <Background />
              )}
              <Dashboard
                handlesigninclick={actions.handlesigninclick}
                dashboardCallback={actions.dashboardCallback}
                account={state.account}
                web3={state.web3}
                balance={state.balance}
                CBAddressL2={state.CBAddressL2}
                CBAddressL1={state.CBAddressL1}
                teamNFTAddressL1={state.teamNFTAddressL1}
                leaguePrize={state.leaguePrize}
                totalPrizes={state.totalPrizes}
                selectedTeam={state.selectedTeam}
                setTeamName={actions.setTeamName}
                ownedTeamId={state.ownedTeamId}
                ownedTeamLeague={state.ownedTeamLeague}
                ownedTeamName={state.ownedTeamName}
                ownedPlayers={state.ownedPlayers}
                teamsData={state.teamsData}
                getLeagueData={actions.getLeagueDataDB}
                getNumberOfLeagues={actions.getNumberOfLeagues}
                loginButtonFallback={actions.loginButtonFallback}
                selectedRollup={state.selectedRollup}
                onLeagueSelect={actions.onLeagueSelectFallback}
                handlePlayerSelect={actions.onPlayerSelectFallback}
                loadTeamOnL1={actions.loadTeamOnL1}
                getOwnedTeam={actions.getOwnedTeam}
                timerSetter={actions.timerSetter}
                payWithStripe={actions.handlePayment}
                loginMethod={state.loginMethod}
                isMobile={state.isMobile}
              />
              <PlayerModal
                show={modals.player}
                onHide={() => actions.setPlayerModalShow(false)}
                playerData={state.playerModalData}
                isMobile={state.isMobile}
              />
              <PaymentModal
                show={modals.payment}
                onHide={() => actions.setPaymentModalShow(false)}
                clientSecret={state.clientSecret}
                stripePromise={stripePromise}
                onSuccess={actions.onPaymentSuccess}
                teamCostUsd={state.teamCostUsd}
                account={state.account}
                isMobile={state.isMobile}
              />
            </Layout>
          } />
        </Routes>
      </Router>
    </Web3AuthProvider>
  );
}

export default App;
  