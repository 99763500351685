import React, { Component } from 'react'
import './Cards.scss';

class Cards extends Component {
  constructor(props) {
    super(props)

    }

    async componentDidMount() {
        
	
            document.querySelector('#card-1').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-1').classList.contains('open') ) {
                //     document.querySelector('#card-1').classList.remove('open');
                // } else {
                //     document.querySelector('#card-1').classList.remove('open');
                //     document.querySelector('#card-1').classList.add('open');
                // }
                
            });

            document.querySelector('#card-2').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-2').classList.contains('open') ) {
                //     document.querySelector('#card-2').classList.remove('open');
                // } else {
                //     document.querySelector('#card-2').classList.remove('open');
                //     document.querySelector('#card-2').classList.add('open');
                // }
                
            });

            document.querySelector('#card-3').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-3').classList.contains('open') ) {
                //     document.querySelector('#card-3').classList.remove('open');
                // } else {
                //     document.querySelector('#card-3').classList.remove('open');
                //     document.querySelector('#card-3').classList.add('open');
                // }
                
            });

            document.querySelector('#card-4').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-4').classList.contains('open') ) {
                //     document.querySelector('#card-4').classList.remove('open');
                // } else {
                //     document.querySelector('#card-4').classList.remove('open');
                //     document.querySelector('#card-4').classList.add('open');
                // }
                
            });
        
    }
        
    render() {
        // console.log(this.state.optionsList)
        return (
        <>
        <div class="container-cards">
            <div id="card-1" class="element-card">
                <div class="front-facing">
                    <h1 className="abr">Own</h1>
                    <p class="title"> You own Team and Players, fully on-chain</p>
                </div>
            </div>
        
            
            <div id="card-3" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Trade</h1>
                    <p class="title">Buy and Sell single players or whole teams in one click</p>
                </div>
            </div>
            
            <div id="card-2" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Win</h1>
                    <p class="title">  Fun and simple gameplay</p>
                </div>
            </div>
            
            <div id="card-4" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Earn</h1>
                    <p class="title">Win real prizes by competing against other players</p>
                </div>
            </div>
        </div>
        </>
        );
    }
}

    export default Cards;